/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/Toast';
import { getAreKYCsRequestsAvailable, fetchActiveConversations, requestKYC } from 'src/_api';
import { ROUTES } from 'src/constants/routes';
import { KYCTabs, KYCTypes, KYCStatus } from 'src/constants/kyc';
import { useAppNavigate } from 'src/shared/useAppNavigate';

const getPendingKYCRequestsQuery = ({ queryKey: [key, params] }) => {
	return getAreKYCsRequestsAvailable(params);
};

const getActiveKYCConversationQuery = ({ queryKey: [key, params] }) => {
	return fetchActiveConversations(params);
};

export const useRequestKYCButton = ({ responderUserId, responderUserCompanyId, close }) => {
	const { t } = useTranslation();
	const { navigate } = useAppNavigate();
	const { addToast } = useToast();

	const {
		data: pendingKYCRequest,
		isFetched: isPendingKYCRequestFetched,
		isLoading: isLoadingPendingKYCRequest,
	} = useQuery(
		[
			['kyc-pending-requests', responderUserId],
			{
				responder_user_id: responderUserId,
			},
		],
		getPendingKYCRequestsQuery,
		{
			enabled: !!responderUserId,
		}
	);

	const {
		data: pendingArchivedKYCRequest,
		isFetched: isPendingArchivedKYCRequestFetched,
		isLoading: isLoadingPendingArchivedKYCRequest,
	} = useQuery(
		[
			['kyc-pending-archived-requests', responderUserId],
			{
				status: KYCStatus.HIDDEN,
			},
		],
		getPendingKYCRequestsQuery
	);

	const hasPendingKYCRequest =
		(isPendingKYCRequestFetched && pendingKYCRequest) ||
		(isPendingArchivedKYCRequestFetched && pendingArchivedKYCRequest);

	const { data: activeConversation = {}, isLoading: isLoadingActiveConversation } = useQuery(
		[
			'kyc-active-conversations',
			{
				company_id: responderUserCompanyId,
				type: KYCTypes.OUTBOUND,
			},
		],
		getActiveKYCConversationQuery,
		{
			enabled: !!responderUserCompanyId && !hasPendingKYCRequest,
		}
	);

	const { mutate: requestKYCMutation, isLoading: isLoadingRequestKYC } = useMutation(requestKYC, {
		onSuccess: ({ _key }) => {
			navigate(`${ROUTES.kyc}/${ROUTES.kycPendingRequest}`, {
				state: {
					kycRequested: Boolean(_key),
					tabToOpen: KYCTabs.OUTBOUND,
				},
			});
		},
		onError: () => {
			addToast({
				kind: 'error',
				message: t('error_unknown_error'),
			});

			// FOR NOW EVEN IF WE GET AN ERROR WE WANT TO REDIRECT TO KYC LISTING PAGE
			navigate(`${ROUTES.kyc}/${ROUTES.kycPendingRequest}`);
		},
	});

	const hasActiveKYCConversation =
		!isLoadingActiveConversation && activeConversation.items?.length > 0;

	const onClick = () => {
		if (hasPendingKYCRequest) {
			navigate(`${ROUTES.kyc}/${ROUTES.kycPendingRequest}`, {
				state: {
					tabToOpen: pendingArchivedKYCRequest ? KYCTabs.ARCHIVED : KYCTabs.OUTBOUND,
				},
			});
			return;
		}

		if (hasActiveKYCConversation) {
			const activeConversationID = activeConversation.items[0]._key;

			navigate(`${ROUTES.kyc}/${ROUTES.kycActiveConversationsList}/${activeConversationID}`);
			return;
		}

		requestKYCMutation({
			user_id: responderUserId,
		});

		close?.();
	};

	const buttonLabel = hasPendingKYCRequest
		? t('kyc_pending')
		: hasActiveKYCConversation
		? t('KYC')
		: t('request_kyc');

	const buttonTestID = hasPendingKYCRequest
		? 'kyc-pending-button'
		: hasActiveKYCConversation
		? 'kyc-button'
		: 'request-kyc-button';

	const loading =
		isLoadingActiveConversation ||
		isLoadingPendingArchivedKYCRequest ||
		isLoadingPendingKYCRequest ||
		isLoadingRequestKYC;

	const buttonMeta = {
		label: buttonLabel,
		loading,
		'data-test': buttonTestID,
		onClick,
	};

	return buttonMeta;
};
