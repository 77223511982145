/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled, { css } from 'styled-components';
import { media } from 'src/_helpers';

export const drawerAnimationTime = 200;

export const Drawer = styled.div`
	display: grid;
	grid-template-rows: 64px 1fr 76px;
	grid-template-columns: 500px;
	${({ $withSidebar }) =>
		$withSidebar && `grid-template-columns: minmax(88px, max-content) 500px;`}
	position: absolute;
	right: 4px;
	top: 56px;
	height: calc(100vh - 60px);
	z-index: var(--z-index-drawer);
	overflow-y: auto;
	overflow-x: hidden;
	background-color: var(--neutral-950);
	border-radius: 20px;
	box-shadow: 0px 0px 30px 20px var(--neutral-1000);
	transition: all linear ${drawerAnimationTime}ms;
	overflow: hidden;
	--_minimizedHeight: 48px;

	&.create-order {
		z-index: var(--z-index-create-order-drawer);
	}

	${media.ll`
		height: calc(100vh - 66px);
		grid-template-rows: 72px 1fr 76px;
		right: 10px;
		border-radius: 32px;
		--_minimizedHeight: 60px;
	`}

	${media.xl`
		top: 60px;
		grid-template-rows: 80px 1fr 88px;
		height: calc(100vh - 70px);
		--_minimizedHeight: 72px;
	`}

	&.hidden {
		transform: translateX(110%);
	}

	${({ $isMinimized }) =>
		$isMinimized &&
		`
		height: var(--_minimizedHeight) !important;
		top: calc(100vh - var(--_minimizedHeight)) !important;
		border-bottom-right-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		overflow: hidden;

		.drawer-content,
		.drawer-footer, 
		.drawer-close-button {
			display: none;
		}
  `}

	${media.ll`
		grid-template-columns: 560px;
		${({ $withSidebar }) => $withSidebar && `grid-template-columns: minmax(88px, max-content) 560px;`}
	`}

	${media.xl`
		grid-template-columns: 650px;	
		${({ $withSidebar }) => $withSidebar && `grid-template-columns: minmax(88px, max-content) 650px;`}
`}
`;

export const DrawerContent = styled.div`
	position: relative;
	overflow-y: auto;
	padding: 4px 0 0;
	background-color: var(--neutral-900);

	${media.ll`
		padding: 6px 0 0;
	`}

	${media.xl`
		padding: 12px 0 0;
	`};
`;

const baseFooter = css`
	padding: 0 16px;
	background: var(--neutral-800);
	z-index: 20;
	position: relative;
	border-bottom-left-radius: 20px;

	${media.ll`
		border-bottom-left-radius: 32px;
		padding: 0 20px;
	`}

	${media.xl`
		width: 650px;
		padding: 0 24px;
	`}
`;

export const OrderDrawerFooter = styled.div`
	${baseFooter}
	display: grid;
	grid-template-columns: 144px 1fr;
	gap: 12px;
	align-items: center;

	${media.ll`
		gap: 16px;
		grid-template-columns: 168px 1fr;
	`}

	${media.xl`
		grid-template-columns: 190px 1fr;
	`}
`;

export const DrawerFooter = styled.div`
	${baseFooter}
	display: flex;
	align-items: center;
	justify-content: space-between;
	--_barHeight: 2px;

	${media.ll`
		--_barHeight: 4px;
	`}

	${({ $progress }) =>
		$progress &&
		`&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: var(--_barHeight);
				background: var(--neutral-1000);
				transition: width 0.2s;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: calc(${($progress / 100) * 100}%);
				height: var(--_barHeight);
				background: var(--green-200);
				transition: width 0.2s;
			}
	`}

	&.create-order-drawer-footer {
		display: grid;
		grid-template-columns: 160px 1fr;
		justify-items: flex-end;

		& > .text-input {
			width: 100%;
		}

		${media.xl`
			grid-template-columns: 190px 1fr;
		`}
	}
`;

export const OrderDrawerButtons = styled.div`
	--gap: 12px;
	--size: 36px;
	--button-size: 144px;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	${media.ll`
		--button-size: 160px;
	`}

	${media.xl`
		--gap: 16px;
		--size: 40px;
		--button-size: 190px;
		
	`}

	gap: var(--gap);

	&:has(> div:last-of-type:empty) {
		gap: 0;
	}

	/* if there is at least one action button */
	&:has(> div:last-of-type :nth-child(1)) {
		a,
		button {
			width: var(--button-size);
		}
	}

	/* if there are at least two action buttons */
	&:has(> div:last-of-type :nth-child(2)) {
		display: grid;
		grid-template-columns: var(--size) 1fr;

		a,
		button {
			width: auto;
		}
	}

	/* if there are at least three action buttons */
	&:has(> div:last-of-type :nth-child(3)) {
		display: grid;
		grid-template-columns: var(--size) 1fr;
	}

	> div:last-of-type {
		display: flex;
		gap: var(--gap);
		justify-content: flex-end;

		> a,
		button {
			height: var(--size);
			flex: 1;
			max-width: var(--button-size);
		}
	}
`;
