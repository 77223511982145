/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { getCountries, getOrigins, getCountry, listProducts, getCompanies } from './products.api';
import {
	getOrderNegotiations,
	getOrderDetails,
	getOrderDetailsFromTrade,
	searchOrdersByIDsFilter,
	getOrderDetailsFromCounter,
	getMyUnseenNegotiations,
} from './orders.api';
import { getTradeDetails, getActivityLogs, getStringData, getTradeUsers } from './trades.api';
import { getPublicNewsCategories } from './news.api';
import { getPublicTermsAndConditions, getSettings, getTermsAndConditions } from './settings.api';
import {
	listMyNotifications,
	listMyContacts,
	listMyTeams,
	listGroups,
	getGroupDetails,
	getTeamDetails,
	getMyContactsCount,
	getPendingInvitationForUser,
	getGroupsCount,
	getTeamsCount,
	getInvitationsCount,
	getUserDetails,
} from './my-account.api';
import { useTradeLocation } from 'src/_helpers/useTradeLocation';
import { getWebGuidebook, getVideoGuide } from './guidebook.api';

const countriesWithoutFlags = ['AQ', 'BQ', 'BLACKSEA', 'CW', 'GG', 'IM', 'JE', 'MF', 'SX', 'SS'];

export const useOrigins = config => {
	return useQuery('origins', getOrigins, {
		staleTime: Infinity,
		...config,
	});
};

export const useTermsAndConditions = config => {
	return useQuery('terms-and-conditions', getTermsAndConditions, { ...config });
};

export const usePublicTermsAndConditions = ({ countryCode }, config) =>
	useQuery(
		['public-terms-and-conditions', countryCode],
		() => getPublicTermsAndConditions({ countryCode }),
		config
	);

export const useCountriesOptions = config => {
	return useQuery('countries', getCountries, {
		select: items => {
			return items.map(country => ({
				text: country.name,
				value: country._key,
				phoneNumberPrefix: country.phone_number_prefix,
				flag: countriesWithoutFlags.includes(country._key)
					? ''
					: country._key.toLowerCase(),
			}));
		},
		staleTime: Infinity,
		...config,
	});
};

export const useMultiOriginsOptions = config => {
	return useQuery('origins', getOrigins, {
		select: items => {
			return items.map(origin => ({
				text: origin.name,
				id: origin._key,
				value: origin._key,
				flag: origin._key.toLowerCase().substring(0, 2),
				search: origin.name.toLowerCase(),
			}));
		},
		staleTime: Infinity,
		...config,
	});
};

export const useOriginsOptions = config => {
	return useQuery('origins', getOrigins, {
		select: items => {
			return items.map(origin => ({
				text: origin.name,
				value: origin._key,
				flag: countriesWithoutFlags.includes(origin._key)
					? ''
					: origin._key.toLowerCase().substring(0, 2),
			}));
		},
		staleTime: Infinity,
		...config,
	});
};

export const useCountry = ({ _key }, config) => {
	return useQuery(['countries', { _key }], () => getCountry(_key), {
		staleTime: Infinity,
		...config,
	});
};

export const useProducts = market => {
	return useQuery(['products', { market }], listProducts, {
		staleTime: Infinity,
		select: data => {
			return data.map(product => ({
				name: product.name,
				_key: product._key,
			}));
		},
	});
};

export const useOrderDetailsFromTradeQuery = ({ tradeId }, config) => {
	return useQuery(
		['order-from-trade', tradeId],
		() => getOrderDetailsFromTrade({ tradeId }),
		config
	);
};

export const useOrderDetailsFromCounterQuery = (
	{ environment, orderId, negotiationId },
	config
) => {
	return useQuery(
		['order-from-counter', negotiationId],
		() => getOrderDetailsFromCounter({ environment, orderId, negotiationId }),
		config
	);
};

export const useOrderDetailsQuery = ({ environment, orderId }, config) => {
	return useQuery(['order', orderId], () => getOrderDetails({ orderId, environment }), config);
};

export const useOrderNegotiations = (
	{ environment, orderId, _order, _sort, status, counter_order_user_id },
	config
) => {
	return useQuery(
		['order', orderId, 'negotiations', status, counter_order_user_id],
		() =>
			getOrderNegotiations({
				environment,
				orderId,
				_order,
				_sort,
				status,
				counter_order_user_id,
			}),
		{
			enabled: !!orderId,
			...config,
		}
	);
};

export const notificationsQuery = ({ queryKey: [key, filters] }) => {
	return listMyNotifications(filters);
};

export const useTradeDetails = ({ tradeId }, config) => {
	return useQuery(['trade', tradeId], () => getTradeDetails({ tradeId }), config);
};

export const useActivityLogs = ({ trade_id, api }, config) => {
	return useQuery(['activity_logs', trade_id], () => getActivityLogs(trade_id, { api }), config);
};

export const usePublicNewsCategories = (query, config) => {
	return useQuery(['public_news_categories', query], () => getPublicNewsCategories(), config);
};

export const useTradeString = ({ trade_id }, config) => {
	return useQuery(['trade-string', trade_id], () => getStringData({ trade_id }), config);
};

export const useSettingsQuery = config => {
	return useQuery('settings', getSettings, { staleTime: Infinity, ...config });
};

export const useMyContactsQuery = (query, config) => {
	return useQuery(['my-contacts', query], () => listMyContacts(query), config);
};

export const useMyTeamsQuery = (query, config) => {
	const userId = useSelector(getUserId);
	return useQuery(['my-teams', userId, query], () => listMyTeams(query), config);
};

export const useMyUnseenNegotiations = config => {
	const currentUserId = useSelector(getUserId);

	return useQuery(
		['my-unseen-negotiations', currentUserId],
		() => getMyUnseenNegotiations(),
		config
	);
};

export const useUserDetailsQuery = (id, config) => {
	return useQuery(
		['user-details', id],
		() => {
			if (!id) {
				return {};
			}
			return getUserDetails(id);
		},
		config
	);
};

export const useMyContactsCountQuery = () => {
	return useQuery(['my-contacts-count'], () => getMyContactsCount());
};

export const useMyGroupsCountQuery = () => {
	return useQuery(['my-groups-count'], () => getGroupsCount(), {
		cacheTime: Infinity,
	});
};

export const useMyTeamsCountQuery = () => {
	return useQuery(['my-teams-count'], () => getTeamsCount(), {
		cacheTime: Infinity,
	});
};

export const useMyInvitationsCount = () => {
	return useQuery(['my-invitations-count'], () => getInvitationsCount());
};

export const useMyGroupsQuery = (query, config) => {
	return useQuery(['my-groups', query], () => listGroups(query), config);
};

export const useGroupDetailsQuery = (query, config) => {
	return useQuery(['group-details', query], () => getGroupDetails(query), config);
};

export const useTeamDetailsQuery = ({ teamId }, config) => {
	return useQuery(['team-details', teamId], () => getTeamDetails({ teamId }), config);
};

export const usePendingInvitationsForUser = inviteeId => {
	return useQuery(['pending-invites', inviteeId], () => getPendingInvitationForUser(inviteeId), {
		select: data => {
			return data.length > 0;
		},
	});
};

export const usePaperOrdersQuery = (query, parentOrderKey, config) => {
	return useQuery(['paper-orders', parentOrderKey], () => searchOrdersByIDsFilter(query), config);
};

export const useTradeUsersQuery = (query, config) => {
	const { tradeId } = useTradeLocation();

	return useQuery(['trade-users', query], () => getTradeUsers({ tradeId, ...query }), config);
};

export const useWebGuidebook = (query, config) => {
	return useQuery(['web_guidebook', query], () => getWebGuidebook({ ...query }), config);
};

export const queryCompanies = ({ queryKey: [key, { userId }] }) => {
	return getCompanies({ user_id: userId });
};

export const useVideoGuide = (query, config) => {
	return useQuery(['video_guide', query], () => getVideoGuide({ ...query }), config);
};
