/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { DropdownMenu } from 'semantic-ui-react';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';
import { media } from 'src/_helpers';

export const ButtonWithIcon = styled(PrimaryButton)`
	display: flex !important;
	gap: 8px;

	${media.xl`
		gap: 16px;
	`}
`;

export const DropdownMenuContainer = styled(DropdownMenu)`
	&.menu.visible {
		margin-bottom: 4px;
		border-radius: 8px !important;
		width: 100%;
		padding-top: 8px;
		padding-bottom: 8px;

		& .item {
			height: 36px;
		}
	}
`;

export const GoToExecutionButton = styled(PrimaryButton)``;
