/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { OrderViews } from './OrderViews';
import { CounterViews } from './CounterViews';
import * as Styled from './styled';
import { OrderEditedMessage } from './OrderEditedMessage';

export const Analytics = ({ order, isCounter, isLastCounter, isTraded }) => {
	const userId = useSelector(getUserId);

	const shouldShowOrderAnalytics = order.is_my_order || order.is_my_team_order;
	const shouldShowCounterAnalytics =
		isCounter && isLastCounter && order.user_id === userId && !isTraded;

	const isEdited = !!order.version && order.version !== 1;

	const EditedContent = () => (isEdited ? <OrderEditedMessage order={order} /> : null);

	return (
		<Styled.ViewsWrapper
			$isVisible={shouldShowOrderAnalytics || shouldShowCounterAnalytics || isEdited}
		>
			{shouldShowOrderAnalytics ? (
				<OrderViews order={order}>
					<EditedContent />
				</OrderViews>
			) : (
				<EditedContent />
			)}
			{shouldShowCounterAnalytics && <CounterViews counter={order} />}
		</Styled.ViewsWrapper>
	);
};
