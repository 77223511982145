/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const TimeOptions = {
	MIN5: 5,
	MIN30: 30,
	MIN60: 60,
	MIN120: 120,
	MIN240: 240,
	MIN360: 360,
	MIN480: 480,
	MIN720: 720,
	MIN1440: 1440,
};

export function tTimeOptions(t, type) {
	switch (type) {
		case TimeOptions.MIN5:
			return t('5_mins');
		case TimeOptions.MIN30:
			return t('30_mins');
		case TimeOptions.MIN60:
			return t('1_hour');
		case TimeOptions.MIN120:
			return t('2_hours');
		case TimeOptions.MIN240:
			return t('4_hours');
		case TimeOptions.MIN360:
			return t('6_hours');
		case TimeOptions.MIN480:
			return t('8_hours');
		case TimeOptions.MIN720:
			return t('12_hours');
		case TimeOptions.MIN1440:
			return t('24_hours');
		default:
			return '';
	}
}
