/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, {
	useCallback,
	useState,
	forwardRef,
	useEffect,
	useRef,
	useMemo,
	cloneElement,
} from 'react';
import { InlineDatepicker } from './date/Date';
import { HourSelect, MinuteSelect } from './time';
import { TimezoneSelect, saveTimezoneInLocalStorage, getTimezone } from './timezone';
import { Dateformat, setSixPM, setNoon } from 'src/_helpers/date';
import { useTranslation } from 'react-i18next';
import { TimeRange } from 'src/components/DateSelector/popup/TimeRange';
import moment from 'moment';
import { TimeOptions } from 'src/constants/timeRangeOptions';
import { useOnClickOutside } from 'src/_helpers/useOnClickOutside';
import { TypeSelector } from 'src/components/TypeSelector/TypeSelector';
import * as Styled from './styled';

export const DateTimeTzPicker = forwardRef(
	(
		{
			children,
			value,
			onChange,
			disabled,
			filterDate,
			popupHeaderTitle,
			renderDateToggleButton = false,
			isTimeDefault = false,
			isTimeAvailable = true,
			isTimeZoneAvailable = true,
			initiallyOpen = false,
			disableClickOutside = false,
			onClickOutside,
			Wrapper = Styled.Popup,
			skipTriggerWrapper = false,
		},
		ref
	) => {
		const { t } = useTranslation();
		const [isOpen, setIsOpen] = useState(initiallyOpen);
		const [tzPickerOpen, setTzPickerOpen] = useState(false);
		const [timePickerOpen, setTimePickerOpen] = useState(false);
		const [selectedFormat, setSelectedFormat] = useState(Dateformat.Days);
		const [isFirstClick, setIsFirstClick] = useState(true);

		useEffect(() => {
			if (isTimeDefault) {
				setSelectedFormat(Dateformat.Time);
			} else {
				setSelectedFormat(Dateformat.Days);
			}
		}, [isTimeDefault]);

		const onDateChange = useCallback(
			(date, forceSetHour = true) => {
				onChange({
					localDate:
						forceSetHour && isFirstClick
							? isTimeAvailable
								? setSixPM(date)
								: setNoon(date)
							: date,
					tz: value.tz,
					time: '',
				});
				setIsFirstClick(false);
			},
			[onChange, value, isTimeAvailable, isFirstClick, setIsFirstClick]
		);

		const onMinutesChange = useCallback(
			mins => onDateChange(moment(value.localDate).set('minutes', mins).toDate(), false),
			[onDateChange, value]
		);
		const onHoursChange = useCallback(
			hrs => {
				const dateObject = moment.isMoment(value.localDate)
					? value.localDate.toDate()
					: value.localDate;
				onDateChange(moment(dateObject).set('hours', hrs).toDate(), false);
			},
			[onDateChange, value]
		);

		const onTzChange = useCallback(
			newTz => {
				saveTimezoneInLocalStorage(newTz);
				onChange({
					localDate: value.localDate,
					tz: newTz,
				});
			},
			[onChange, value]
		);

		const onTimeChange = useCallback(
			time => {
				onChange({
					time,
					localDate: moment().add(TimeOptions[time], 'minutes').toDate(),
					tz: getTimezone(),
				});
				setIsOpen(false);
			},
			[onChange]
		);

		const toggleButtonOptions = useMemo(
			() =>
				[
					{ value: Dateformat.Days, text: t('date') },
					{ value: Dateformat.Time, text: t('time') },
				].map(({ value, text }) => ({
					text,
					onClick: () => setSelectedFormat(value),
					isActive: selectedFormat === value,
				})),
			[selectedFormat, t]
		);

		const contentRef = useRef();
		useOnClickOutside(
			contentRef,
			disableClickOutside
				? undefined
				: () => {
						setIsOpen(false);
						onClickOutside?.();
				  }
		);

		useEffect(() => {
			if (!isOpen) {
				setTzPickerOpen(false);
				setTimePickerOpen(false);
			}
		}, [isOpen]);

		const handleTriggerClick = () => (disabled ? null : setIsOpen(true));

		const triggerComponent = skipTriggerWrapper ? (
			cloneElement(children, {
				onClick: handleTriggerClick,
			})
		) : (
			<Styled.ChildrenWrapper onClick={handleTriggerClick}>{children}</Styled.ChildrenWrapper>
		);

		return (
			<Wrapper
				flowing
				positionFixed
				trigger={triggerComponent}
				open={isOpen}
				on="click"
				position="bottom left"
				data-test="date-time-tz-picker"
				popperDependencies={[tzPickerOpen, timePickerOpen, selectedFormat]}
			>
				<div ref={contentRef}>
					{renderDateToggleButton && (
						<Styled.Header>
							<label>{t(popupHeaderTitle)}</label>
							<TypeSelector options={toggleButtonOptions} />
						</Styled.Header>
					)}
					{selectedFormat === Dateformat.Days && (
						<Styled.Grid>
							<div>
								<InlineDatepicker
									selected={value?.localDate?.toDate?.() || value.localDate}
									onChange={onDateChange}
									filterDate={filterDate}
								/>

								<Styled.ButtonWrapper $isTimeAvailable={isTimeAvailable}>
									{isTimeAvailable && (
										<div>
											<Styled.DateTimeLabel>{t('time')}</Styled.DateTimeLabel>
											<Styled.DateTimeButton
												disabled={!value.localDate}
												onClick={() => setTimePickerOpen(val => !val)}
											>
												{!isFirstClick || value.localDate
													? moment(value.localDate).format('h:mm A')
													: '6:00 PM'}
											</Styled.DateTimeButton>
										</div>
									)}
									{isTimeZoneAvailable && (
										<div>
											<Styled.DateTimeLabel>
												{t('timezone')}
											</Styled.DateTimeLabel>
											<Styled.DateTimeButton
												disabled={!value.localDate}
												onClick={() => setTzPickerOpen(val => !val)}
											>
												{value.tz}
											</Styled.DateTimeButton>
										</div>
									)}
								</Styled.ButtonWrapper>
							</div>
							{timePickerOpen && (
								<>
									<Styled.HourColumn>
										<HourSelect
											value={moment(value.localDate).hours()}
											onChange={onHoursChange}
										/>
									</Styled.HourColumn>
									<Styled.MinuteColumn>
										<MinuteSelect
											value={moment(value.localDate).minutes()}
											onChange={onMinutesChange}
										/>
									</Styled.MinuteColumn>
								</>
							)}

							{tzPickerOpen && (
								<Styled.TimezoneColumn>
									<TimezoneSelect value={value.tz} onChange={onTzChange} />
								</Styled.TimezoneColumn>
							)}
						</Styled.Grid>
					)}
					{selectedFormat === Dateformat.Time && (
						<TimeRange time={value?.time} onTimeChange={onTimeChange} />
					)}
				</div>
			</Wrapper>
		);
	}
);
