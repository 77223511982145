/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { H600 } from 'src/components/Typography/fonts';
import { VolumeDisplay } from 'src/components/Volume';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';
import { parseDateStringAsUTC, formatDateRange } from 'src/_helpers/date';
import { calculate2ndLegPrice, OrderType, CompanyHeader } from 'src/constants/contract';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	getLabels,
	Labels,
} from 'src/components/ViewOrderDrawer/Content/Paper/ContractAndPricingSection/useCalendarSpreadLegUsers';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useSellerBrokerBuyer } from 'src/_helpers/order';
import * as Styled from './styled';

export const AcceptSpreadSummary = ({ order, counters = [] }) => {
	const { t } = useTranslation();

	const lastCounter = counters.at(-1);

	const { brokerCompany, buyerCompany, sellerCompany } = useSellerBrokerBuyer(order, counters, {
		order: { order_owner_principal_id: lastCounter?.order_owner_principal_id },
	});

	const { isMyOrder, isBrokerOrder } = useOrderDetails(order);

	const userLabels = getLabels(
		order.order_type === OrderType.Sell
			? isMyOrder
				? Labels.Buyer
				: Labels.Seller
			: isMyOrder
			? Labels.Seller
			: Labels.Buyer
	);

	const contractName = `${order.product.name} ${order.inco.name} ${order.primary_ports[0].name}`;
	const price = formatPriceWithoutCurrency(order.price);
	const spreadPrice = price > 0 ? `+${price}` : price;
	const firstLegPrice = order.spread_details.first_leg_price;
	const secondLegPrice = calculate2ndLegPrice(firstLegPrice, order.price);
	const currencyUnit = [
		order.currency,
		t('price_unit_short_' + order.price_unit, { lng: 'en' }),
	].join('/');

	const getSellerBuyerBrokerLabel = userLabel => {
		if (userLabel === CompanyHeader[OrderType.Buy]) {
			return `${t(userLabels[0])} (${t('broker')})`;
		}

		return `${t(userLabels[1])} (${t('broker')})`;
	};

	return (
		<>
			<Styled.OrderValueRow>
				<Styled.Label>{t('contract')}</Styled.Label>
				<H600>{contractName}</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('quantity')}</Styled.Label>
				<VolumeDisplay as={H600} value={order.volume} />
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('spread')}</Styled.Label>
				<H600>
					{spreadPrice} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
			<Styled.SectionName>{t('first_leg')}</Styled.SectionName>
			<Styled.OrderValueRow>
				<Styled.Label>{t('seller')}</Styled.Label>
				<Styled.CompanyName>
					<OverflowText>{sellerCompany}</OverflowText>
				</Styled.CompanyName>
			</Styled.OrderValueRow>
			{isBrokerOrder && (
				<Styled.OrderValueRow>
					<Styled.Label>{getSellerBuyerBrokerLabel(userLabels[0])}</Styled.Label>
					<Styled.CompanyName>
						<OverflowText>{brokerCompany}</OverflowText>
					</Styled.CompanyName>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('buyer')}</Styled.Label>
				<Styled.CompanyName>
					<OverflowText>{buyerCompany}</OverflowText>
				</Styled.CompanyName>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('shipment')}</Styled.Label>
				<H600>
					{formatDateRange({
						format: order.delivery_mode,
						startDate: parseDateStringAsUTC(order.delivery_date_from),
						endDate: parseDateStringAsUTC(order.delivery_date_to),
					})}
				</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('futures_contract')}</Styled.Label>
				<H600>{`${order.futures_contract} ${moment(order.futures_contract_date).format(
					'MMM YYYY'
				)}`}</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('price')}</Styled.Label>
				<H600>
					{formatPriceWithoutCurrency(firstLegPrice)} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
			<Styled.SectionName>{t('second_leg')}</Styled.SectionName>
			<Styled.OrderValueRow>
				<Styled.Label>{t('seller')}</Styled.Label>
				<Styled.CompanyName>
					<OverflowText>{buyerCompany}</OverflowText>
				</Styled.CompanyName>
			</Styled.OrderValueRow>
			{isBrokerOrder && (
				<Styled.OrderValueRow>
					<Styled.Label>{getSellerBuyerBrokerLabel(userLabels[1])}</Styled.Label>
					<Styled.CompanyName>
						<OverflowText>{brokerCompany}</OverflowText>
					</Styled.CompanyName>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('buyer')}</Styled.Label>
				<Styled.CompanyName>
					<OverflowText>{sellerCompany}</OverflowText>
				</Styled.CompanyName>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('shipment')}</Styled.Label>
				<H600>
					{formatDateRange({
						format: order.spread_details.delivery_mode,
						startDate: parseDateStringAsUTC(order.spread_details.delivery_date_from),
						endDate: parseDateStringAsUTC(order.spread_details.delivery_date_to),
					})}
				</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('futures_contract')}</Styled.Label>
				<H600>{`${order.futures_contract} ${moment(
					order.spread_details.futures_contract_date
				).format('MMM YYYY')}`}</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('price')}</Styled.Label>
				<H600>
					{formatPriceWithoutCurrency(secondLegPrice)} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
		</>
	);
};
