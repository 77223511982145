/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const Container = styled.div`
	overflow: hidden;
	white-space: nowrap;
	${({ $isOverflowed }) => ($isOverflowed ? `text-overflow: ellipsis;` : '')}
`;

export const Content = styled.div`
	color: #fff;
	word-break: break-word;
`;

export const MultilineContent = styled.div`
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4px;

	> div {
		background: var(--neutral-500);
		padding: 5px 8px;
		border-radius: 2px !important;
	}
`;

export const OverflowArrayWrapper = styled.div`
	display: flex;
`;

export const HighlightLabel = styled.div`
	color: var(--white);
	margin-left: ${({ $isContentOverflowed }) => ($isContentOverflowed ? '' : '4px')};
`;

export const OverflowTexWithTrailingElementWrapper = styled.div`
	display: flex;
	align-items: center;
`;
