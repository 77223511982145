/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import styled from 'styled-components';
import { media } from 'src/_helpers';
import { SecondaryButton } from 'src/components/Buttons/SecondaryButton';
import { PrimaryButton } from 'src/components/Buttons/PrimaryButton';

export const ActionButtons = styled.div.attrs({
	'data-test': 'action-buttons',
})`
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	width: 304px;

	${media.ll`
		width: 341px;
	`}

	${media.xl`
		width: 396px;
	`}

	& a, button {
		width: 144px;

		${media.ll`
			width: 160px;
		`}

		${media.xl`
			width: 190px;
		`}
	}
`;

export const CounterButton = styled(SecondaryButton)`
	&.darker.disabled {
		background-color: var(--neutral-700);
	}
`;

export const CopyButton = styled(SecondaryButton).attrs({ darker: true })``;

export const AcceptButton = styled(PrimaryButton)`
	&.disabled {
		background-color: var(--neutral-700);
	}
`;
