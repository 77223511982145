/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { QuoteType } from 'src/constants/contract';
import { defaultFirmTimeValue, defaultIndicativeTimeValue } from '../model';
import { getCurrentTzOrDefault } from 'src/components/DateTimeTzPicker';
import { TimeOptions } from 'src/constants/timeRangeOptions';

export const getDefaultValidity = orderTypeValue => {
	const timeValue =
		orderTypeValue === QuoteType.Firm ? defaultFirmTimeValue : defaultIndicativeTimeValue;

	return {
		tz: getCurrentTzOrDefault(),
		localDate: moment().add(TimeOptions[timeValue], 'minutes').toDate(),
		time: timeValue,
	};
};
