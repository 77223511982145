/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FoldableSection } from 'src/components/CreateOrderDrawer/components/FoldableSection/FoldableSection';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { usePricingSectionOptions } from './usePricingSectionOptions';
import {
	DrawerPriceType,
	PriceIncrementType,
	isBasisPrice,
	QuoteType,
} from 'src/constants/contract';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { MaterialNumberInputWithOptions } from 'src/designSystem/Form/MaterialInput/MaterialNumberInputWithOptions';
import { Tooltip, TooltipTrigger, TooltipContent } from 'src/components/Tooltip/Tooltip';
import { ContextualGuidePhysicalOrderPricing } from 'src/components/ContextualGuide';

export const PricingSection = () => {
	const { t } = useTranslation();

	const {
		formState: { errors },
		trigger,
	} = useFormContext();

	const {
		orderType,
		priceType,
		futuresMonth,
		payment,
		priceIncrementType,
		unit,
		priceIncrementBasisShipment,
		priceCurrencyUnit,
		canHideOptional,
	} = usePricingSectionOptions();

	const {
		priceValue,
		priceTypeValue,
		priceIncrementTypeValue,
		currencyUnitValue,
	} = useWatchPhysicalFields();

	const isNoPrice = priceTypeValue === DrawerPriceType.NoPrice;
	const isPriceIncrementSet = [
		PriceIncrementType.HalfMonthly,
		PriceIncrementType.Monthly,
	].includes(priceIncrementTypeValue);

	let isPriceError = !!errors.price;

	if (priceValue === 0) {
		if (!isBasisPrice(priceTypeValue) && !isNoPrice) {
			isPriceError = true;
		}
	}

	return (
		<FoldableSection
			title={t('pricing')}
			GuideComponent={ContextualGuidePhysicalOrderPricing}
			canToggle={canHideOptional}
		>
			{orderType.ready ? (
				<Controller
					name="orderType"
					mandatory
					render={({ field }) => (
						<MaterialSelectInput
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('order_type_label')}
							options={orderType.options}
							disabled={!orderType.ready}
							required
							{...field}
						/>
					)}
				/>
			) : (
				<Tooltip mandatory>
					<TooltipTrigger>
						<MaterialSelectInput
							data-test="orderType"
							label={t('order_type_label')}
							options={orderType.options}
							value={QuoteType.Indicative}
							disabled
							required
						/>
					</TooltipTrigger>
					<TooltipContent>{t('firm_orders_disabled_tooltip')}</TooltipContent>
				</Tooltip>
			)}
			<Controller
				name="priceType"
				mandatory
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('price_type')}
						options={priceType.options}
						disabled={!priceType.ready}
						required
						{...field}
						onChange={value => {
							field.onChange(value);
							if (
								priceValue !== undefined &&
								isBasisPrice(value) !== isBasisPrice(priceTypeValue)
							) {
								trigger('price');
							}
						}}
					/>
				)}
			/>
			{isBasisPrice(priceTypeValue) && (
				<Controller
					name="futuresMonth"
					mandatory
					render={({ field }) => (
						<MaterialSelectInput
							required
							error={!!errors[field.name]}
							data-test={field.name}
							label={t('futures_month')}
							options={futuresMonth.options}
							disabled={!futuresMonth.ready}
							{...field}
						/>
					)}
				/>
			)}
			<Controller
				name="price"
				mandatory
				render={({ field }) => (
					<MaterialNumberInputWithOptions
						error={isPriceError}
						data-test="priceCurrencyUnit"
						label={t('price')}
						options={priceCurrencyUnit.options}
						onOptionChange={priceCurrencyUnit.onOptionChange}
						optionValue={currencyUnitValue}
						disableInput={isNoPrice}
						disableDropdown={!priceCurrencyUnit.ready}
						required={!isNoPrice}
						allowNegative={isBasisPrice(priceTypeValue)}
						forceNumber={false}
						{...field}
						onChange={value => {
							field.onChange(value);
							if (value !== undefined) {
								trigger('price');
							}
						}}
					/>
				)}
			/>
			<Controller
				name="payment"
				mandatory={payment.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('payment')}
						options={payment.options}
						disabled={!payment.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				name="priceIncrementType"
				mandatory={priceIncrementType.hasValue}
				render={({ field }) => (
					<MaterialSelectInput
						error={!!errors[field.name]}
						data-test={field.name}
						label={t('price_increment')}
						options={priceIncrementType.options}
						disabled={!priceIncrementType.ready}
						{...field}
					/>
				)}
			/>
			{isPriceIncrementSet && (
				<Controller
					name="priceIncrement"
					mandatory={priceIncrementType.hasValue}
					render={({ field }) => (
						<MaterialNumberInput
							error={!!errors[field.name]}
							data-test={field.name}
							label={t(`price_increment_per_${unit}`)}
							fixedDecimalScale={false}
							parameters={t(`price_unit_${unit}`)}
							required
							{...field}
						/>
					)}
				/>
			)}
			{isPriceIncrementSet && (
				<Controller
					name="priceIncrementBasisShipment"
					mandatory={priceIncrementType.hasValue}
					render={({ field }) => (
						<MaterialDateInput
							{...field}
							required
							allowPastDates
							data-test={field.name}
							label={t('basis_shipment')}
							error={!!errors[field.name]}
							initialVisibleMonth={priceIncrementBasisShipment.initialValue}
						/>
					)}
				/>
			)}
		</FoldableSection>
	);
};
