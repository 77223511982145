/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import {
	getDefaultDateTimeTzValue,
	timezoneDateToIsoString,
} from 'src/components/DateTimeTzPicker';
import {
	PriceType,
	QuoteType,
	PriceIncrementType,
	NameVisibility,
	portsIncos,
	isBasisPrice,
	Market,
	PriceUnit,
} from 'src/constants/contract';
import { TimeOptions } from 'src/constants/timeRangeOptions';
import {
	mapPortsToApi,
	filterEmptyFields,
	mapCustomSpecsObjectToArray,
} from 'src/components/CreateOrderDrawer/physical/model';
import { isNil } from 'src/_helpers';
import { FILES_PREFIX } from 'src/constants/files';
import { getChangesBetweenCounters } from '../../helpers/getCounterChanges';

export const mapLastCounterToFormData = lastCounter => {
	const customSpecs = lastCounter.grade.is_custom
		? lastCounter.grade.gradespecs.reduce((acc, item) => {
				acc[`spec-${item.spec_id}`] = item.value;
				return acc;
		  }, {})
		: undefined;

	return {
		order_user_id: lastCounter.order_user_id,
		environment: lastCounter.environment,
		role: lastCounter.role,
		order_owner_principal_id: lastCounter.order_owner_principal_id,
		nameVisibility: lastCounter.hidden ? NameVisibility.Hidden : NameVisibility.Visible,
		type: lastCounter.order_type,
		volume: lastCounter.volume,
		productAndType: [lastCounter.product_id, lastCounter.type_id].join('|'),
		inco: lastCounter.inco_id,
		port: lastCounter?.primary_ports?.[0]?._key || null,
		date: {
			startDate: lastCounter.delivery_date_from,
			endDate: lastCounter.delivery_date_to,
			format: lastCounter.delivery_mode,
		},
		origin: lastCounter.origin_country_ids?.length ? lastCounter.origin_country_ids : [],
		grade: lastCounter.grade_id || null,
		customSpecs,
		harvest: lastCounter.harvest_year || null,
		speciality: lastCounter.speciality || null,
		orderType: lastCounter.is_indicative ? QuoteType.Indicative : QuoteType.Firm,
		priceType:
			lastCounter.price_type === PriceType.Flat
				? PriceType.Flat
				: lastCounter.futures_contract,
		futuresMonth: new Date(lastCounter.futures_contract_date).valueOf(),
		price: lastCounter.price,
		payment: lastCounter.payment,
		priceIncrementType: lastCounter.price_increment_type || PriceIncrementType.None,
		priceIncrement: lastCounter.price_increment,
		priceIncrementBasisShipment: {
			startDate: lastCounter.price_increment_basis_shipment_from,
			endDate: lastCounter.price_increment_basis_shipment_to,
			format: lastCounter.price_increment_basis_shipment_mode,
		},
		validity: getDefaultDateTimeTzValue(),
		currencyUnit: `${lastCounter.currency}/${lastCounter.price_unit}`,
		tolerance: lastCounter.tolerance,
		cargoType: lastCounter.shipment_type,
		inspection: lastCounter.inspection,
		ports: lastCounter.primary_ports.map(port => ({
			name: port._key,
			volume: port.volume,
			terminal: port.terminal,
			rate: port.rate,
		})),
		performanceBond: lastCounter.performance_bond_percent,
		contractTerms: lastCounter.terms_id,
		contractNumber: lastCounter.contract_number || '',
		file_contract_id: lastCounter.file_contract_id,
		file_contract: lastCounter.file_contract,
	};
};

export const mapPreviewDataToCounterView = (preview, formData, user, lastCounter) => {
	const gradespecs = formData.specs
		.map(({ _key, value, ...spec }) => ({
			_key,
			value: formData.isCustomGrade ? formData.customSpecs[`spec-${_key}`] : value,
			spec: { ...spec },
		}))
		.filter(({ value }) => value);

	const contractTermsName = formData.contractTermsOptions.find(
		option => option.value === formData.contractTerms
	)?.text;

	const currentComment = formData.comment
		? {
				key: 'counter-comment',
				comment: formData.comment,
				index: lastCounter.counterIndex + 1,
				changed: true,
				author: user.session.name,
				isOwn: true,
		  }
		: {};

	const comments = [
		currentComment,
		...(formData.allComments || []).map(comment =>
			comment?.comment ? { ...comment, changed: false } : false
		),
	].filter(Boolean);

	const currentFiles = formData.documents
		.map(documentId => ({
			name: formData.documentNames.find(document => document.id === documentId)?.name,
			_key: documentId,
			_id: documentId,
			changed: true,
			owner: {
				name: user.session.name,
			},
		}))
		.filter(Boolean);

	const files = [
		...currentFiles,
		...(lastCounter.files || []).map(file => (file ? { ...file, changed: false } : false)),
	].filter(Boolean);

	const mappedData = {
		...preview,
		is_counter_preview: true,
		inspection: formData.inspection || '',
		product: formData.productObject,
		order_user_id: formData.order_user_id,
		grade: { name: formData.gradeObject.text, gradespecs },
		price_unit:
			preview.price_type === PriceType.Flat
				? PriceUnit.MetricTon
				: formData.currencyUnit.split('/').at(-1),
		ports_loading: preview.ports_loading.map(({ port_id, ...portData }, index) => {
			const [name, country_id] = formData.portNames[port_id].split(', ');
			return {
				_key: port_id,
				name,
				country_id,
				...portData,
			};
		}),
		ports_discharging: preview.ports_discharging.map(({ port_id, ...portData }, index) => {
			const [name, country_id] = formData.portNames[port_id].split(', ');
			return {
				_key: port_id,
				name,
				country_id,
				...portData,
			};
		}),
		inco: { name: preview.inco_id, _key: preview.inco_id },
		origin_countries: (formData.originObjects || []).map(origin => ({
			_key: origin.id,
			name: origin.text,
		})),
		user: {
			_key: user.session._key,
			avatar_color: user.avatar_color,
			name: user.session.name,
			first_name: user.session.first_name,
			last_name: user.session.last_name,
			company: {
				name: user.company_name,
				country_code: user.session.company_country_code,
			},
		},
		order_owner_principal_user: formData.principalObject,
		order_owner_principal_id:
			formData.principalObject?.contact_user_id || formData.principalObject?._key,
		order_owner_principal_company: formData.principalObject
			? {
					name: formData.principalObject.company_name,
					avatar_color: formData.principalObject.company_avatar_color,
			  }
			: null,
		terms: { name: contractTermsName },
		file_contract: formData.file_contract,
		comments,
		files,
	};

	mappedData.primary_ports =
		mappedData.ports_discharging.length > 0
			? mappedData.ports_discharging
			: mappedData.ports_loading;

	// NOTE: when the order is firm, the is_indicative flag doesn't come from BE and the comparison fails
	const lastCounterWithForcedIndicativeFlag = {
		...lastCounter,
		is_indicative: lastCounter.is_indicative || false,
	};

	const changes = getChangesBetweenCounters(mappedData, lastCounterWithForcedIndicativeFlag);

	return {
		...mappedData,
		changes,
	};
};

export const mapFormDataToApiCounter = data => {
	const isDischargeInco = portsIncos.dischargePorts.includes(data.inco);
	const isLoadingInco = portsIncos.loadingPorts.includes(data.inco);

	let ports_loading;
	let ports_discharging;

	if (isDischargeInco) {
		ports_loading = [];
		ports_discharging = mapPortsToApi(data);
	}

	if (isLoadingInco) {
		ports_loading = mapPortsToApi(data);
		ports_discharging = [];
	}

	const isPriceIncrementDefined = [
		PriceIncrementType.HalfMonthly,
		PriceIncrementType.Monthly,
	].includes(data.priceIncrementType);

	const isBasisPriceType = isBasisPrice(data.priceType);

	const [product_id, type_id] = data.productAndType.split('|');

	const recipients = data.fullContactsList
		?.map(user => user._key)
		.filter((id, index, list) => list.indexOf(id) === index);

	return filterEmptyFields({
		order_type: data.type,
		market: Market.Physical,
		product_id,
		type_id,
		harvest_year: data.harvest,
		origin_country_ids: data.origin?.map(origin =>
			typeof origin === 'string' ? origin : origin.id
		),
		speciality: data.speciality,
		inco_id: data.inco,
		volume: data.volume,
		delivery_date_from: data.date.startDate,
		delivery_date_to: data.date.endDate,
		delivery_mode: data.date.format,
		ports_loading,
		ports_discharging,
		grade_id: data.grade,
		price: +data.price,
		is_indicative: data.orderType === QuoteType.Indicative,
		price_type: isBasisPriceType ? PriceType.Basis : PriceType.Flat,
		...(isBasisPriceType && {
			futures_contract: data.priceType,
			futures_contract_date: new Date(data.futuresMonth),
		}),
		payment: data.payment,
		price_increment_type: isPriceIncrementDefined ? data.priceIncrementType : undefined,
		price_increment_basis_shipment_from: isPriceIncrementDefined
			? data.priceIncrementBasisShipment?.startDate
			: undefined,
		price_increment_basis_shipment_to: isPriceIncrementDefined
			? data.priceIncrementBasisShipment?.endDate
			: undefined,
		price_increment_basis_shipment_mode: isPriceIncrementDefined
			? data.priceIncrementBasisShipment?.format
			: undefined,
		price_increment: isPriceIncrementDefined ? +data.priceIncrement : undefined,
		currency: data.currencyUnit.split('/')[0],
		...(data.isCustomGrade && {
			orderspecs: mapCustomSpecsObjectToArray(data.customSpecs).filter(
				spec => !!spec.spec_id && !isNil(spec.value)
			),
		}),
		tolerance: parseInt(data.tolerance) || undefined,
		shipment_type: data.cargoType,
		inspection: data.inspection,
		role: data.role,
		environment: data.environment,
		performance_bond_percent: data.performanceBond,
		terms_id: data.contractTerms,
		contract_number: data.contractNumber || undefined,
		file_contract_id: data.file_contract_id,
		recipients,
		order_owner_principal_id: data.principalId || data.order_owner_principal_id,
		comment_order: data.comment,
		file_ids: data.documents.map(file => file.replace(FILES_PREFIX, '')),
		validity_time: data.validity.time || '',
		validity: data.validity.time
			? timezoneDateToIsoString({
					localDate: moment().add(TimeOptions[data.validity.time], 'minutes').toDate(),
					tz: data.validity.tz,
			  })
			: timezoneDateToIsoString(data.validity),
		validity_timezone: data.validity.tz,
		version: data.version,
	});
};
