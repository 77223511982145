/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { CounterFormFooter } from './components/CounterFormFooter/CounterFormFooter';
import { ProductSection } from './sections/paper/ProductSection/ProductSection';
import { ContractAndPricingSection } from './sections/paper/ContractAndPricingSection/ContractAndPricingSection';
import { CounterPreviewContent } from './components/CounterPreview/CounterPreviewContent';
import { CounterPreviewFooter } from './components/CounterPreview/CounterPreviewFooter';
import * as Styled from './styled';

export const CounterPaperForm = ({
	setCounterForm,
	formMethods,
	onSubmit,
	isPreviewMode,
	isSubmitting,
	lastCounter,
}) => {
	return (
		<FormProvider {...formMethods}>
			<Styled.Form>
				{isPreviewMode ? (
					<>
						<CounterPreviewContent />
						<CounterPreviewFooter onSubmit={onSubmit} isSubmitting={isSubmitting} />
					</>
				) : (
					<>
						<Styled.FormContent>
							<ProductSection />
							<ContractAndPricingSection lastCounter={lastCounter} />
						</Styled.FormContent>
						<CounterFormFooter
							onSubmit={onSubmit}
							onCancel={() => setCounterForm(false, null)}
						/>
					</>
				)}
			</Styled.Form>
		</FormProvider>
	);
};
