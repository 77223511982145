/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { createContext, useContext } from 'react';

export const AppNavigateContext = createContext({
	navigate: () => null,
});

export const AppNavigateContextProvider = ({ children, navigate }) => {
	return (
		<AppNavigateContext.Provider value={{ navigate }}>{children}</AppNavigateContext.Provider>
	);
};

export const useAppNavigate = () => {
	return useContext(AppNavigateContext);
};
