/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { isNil } from './utils';

/** GOT THE SOLUTION FOR NEGATIVE PRICE FORMAT AT https://codepen.io/Cryptoch/pen/gOaKBZP */
export const formatPrice = (price, options = {}) => {
	const stripSymbols = options.currencyDisplay === 'none';
	const internalOptions = stripSymbols ? { ...options, currencyDisplay: 'code' } : options;

	let currencyReplaced = false;

	let result =
		!isNil(price) &&
		new Intl.NumberFormat('en', {
			style: 'currency',
			currency: 'USD',
			currencyDisplay: 'code',
			...internalOptions,
		})
			.formatToParts(price)
			.map((item, idx, arr) => {
				if ((item.type === 'currency' || item.type === 'literal') && currencyReplaced) {
					return '';
				}
				const nextCurrency =
					arr[idx + 1] && arr[idx + 1].type === 'currency' && arr[idx + 1].value;

				if (item.type === 'minusSign' && nextCurrency && !currencyReplaced) {
					currencyReplaced = true;
					return `${nextCurrency}\xa0${item.value}`;
				}
				return item.value;
			})
			.join('');

	if (stripSymbols && !isNil(price)) {
		result = result
			.replace(/[a-z]{3}/i, '')
			.replace(/\s/i, '')
			.trim();
	}

	return result;
};

export const formatPriceWithoutCurrency = (price, options = {}) =>
	formatPrice.call(null, price, { ...options, currencyDisplay: 'none' });

export const isPriceDefined = val => !!val || val === 0;
