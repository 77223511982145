/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Market } from 'src/constants/contract';
import { h300 } from '../Typography';
import { useTranslation } from 'react-i18next';
import { useDrawerContext, DrawerContextKeys } from '../Drawer/DrawerContext';
import { useMarket } from 'src/_routes/useActiveMarket';
import { searchSetMarket } from 'src/_store/actions';
import { ContextualGuideMarkets } from 'src/components/ContextualGuide';

export const MarketSwitch = () => {
	const { t } = useTranslation();
	const [market, setMarket] = useMarket();
	const dispatch = useDispatch();

	const contextData = useDrawerContext();
	const {
		orderPreviewData,
		setConfirmationVisible,
		setIsMarketChanging,
		isFormDirty,
	} = contextData[DrawerContextKeys.createDrawer];
	const handleMarketChange = useCallback(
		val => {
			if (!!orderPreviewData || isFormDirty) {
				setConfirmationVisible(true);
				setIsMarketChanging(true);
				return;
			}

			setMarket(val);
			dispatch(searchSetMarket(val));
		},
		[
			isFormDirty,
			orderPreviewData,
			setConfirmationVisible,
			setIsMarketChanging,
			setMarket,
			dispatch,
		]
	);

	return (
		<Wrapper>
			<MarketSwitchToggleButtons data-test="market-switch" id="market-switch-toggle">
				{Object.values(Market).map(val => {
					return (
						<ToggleButton
							key={val}
							data-test={`option-${val}`}
							onClick={() => handleMarketChange(val)}
							className={market === val && 'selected'}
						>
							{t(val)}
						</ToggleButton>
					);
				})}
			</MarketSwitchToggleButtons>
			<ContextualGuideMarkets />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const MarketSwitchToggleButtons = styled.div`
	display: flex;
	background: var(--neutral-900);
	border-radius: 32px;
	padding: 4px;
	margin-left: 25px;
`;

const ToggleButton = styled.button.attrs({ type: 'button' })`
	${h300};
	line-height: 15px;
	height: 28px;
	padding: 0 14px;

	color: var(--neutral-200);
	background: transparent;
	flex: 1 0 auto;
	border-radius: 32px;

	&.selected {
		color: var(--text-color-default);
		background: var(--neutral-600);
	}
`;
