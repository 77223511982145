/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropdownItem, Dropdown } from 'semantic-ui-react';
import { useNavigate } from 'react-router';
import { ReactComponent as ExpandMoreitemsIcon } from 'src/assets/icons/ellipsis.svg';
import { ROUTES } from 'src/constants/routes';
import { useToast } from 'src/components/Toast';
import { useCounterFooter } from '../../Counter/useCounterFooter';
import * as Styled from './styled';

const Legs = ['first_leg_abbrev', 'second_leg_abbrev'];

export const MultiExecutionButton = ({ order, counter }) => {
	const { t } = useTranslation();
	const { addToast } = useToast();
	const navigate = useNavigate();

	const { isRenduCounter, isPKPGOrder, lastCounter } = useCounterFooter({
		order,
		counter,
		isLastCounter: true,
	});

	const handleClickExecution = tradeId => {
		if (isRenduCounter) {
			addToast({
				message: t('rendu_no_post_trade_available'),
				kind: 'warning',
			});

			return;
		}

		if (isPKPGOrder) {
			addToast({
				message: t('pkpb_no_post_trade_available'),
				kind: 'warning',
			});

			return;
		}

		navigate(ROUTES.tradeDetails.replace(':tradeId', tradeId));
	};

	const hasMultipleExecutions = lastCounter.trade_ids?.length > 1;

	if (!hasMultipleExecutions) {
		return (
			<Styled.GoToExecutionButton
				data-test="execution-button"
				onClick={() => handleClickExecution(lastCounter.trade_id)}
			>
				{t('execution')}
			</Styled.GoToExecutionButton>
		);
	}

	return (
		<Dropdown
			text={t('execution')}
			direction="left"
			as={Styled.ButtonWithIcon}
			icon={<ExpandMoreitemsIcon />}
			data-test="multi-execution-button"
		>
			<Styled.DropdownMenuContainer>
				{Legs.map((leg, index) => {
					return (
						<DropdownItem
							key={leg}
							as={() => (
								<Link
									className="item"
									to={`/execution/trade/${lastCounter.trade_ids[index]}`}
								>
									{t(leg)}
								</Link>
							)}
						/>
					);
				})}
			</Styled.DropdownMenuContainer>
		</Dropdown>
	);
};
