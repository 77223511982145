/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const ContainerWidth = {
	Normal: 400,
	Large: 462,
};

export const MaxNumberOfRetries = 10;

export const ContainerSpacing = {
	Top: 20,
	Right: 20,
	Bottom: 20,
};

// only variants used on the designs are handled
export const SlideAttachment = {
	BottomLeft: 'bottom_left',
	TopLeft: 'top_left',
	BottomRight: 'bottom_right',
	Left: 'left',
};
