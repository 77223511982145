/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { terminateOrder } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useTogglable } from 'src/_helpers/useTogglable';
import { NegativeButton } from 'src/components/Buttons/NegativeButton';
import {
	Modal,
	ModalHeaderWrapper,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalTitle,
	ModalSubTitle,
	CancelButton,
} from 'src/components/Modal';
import { Checkbox } from 'src/designSystem/Form/Checkbox/Checkbox';
import { ModalSize, ModalType } from 'src/constants/modal';
import { OrderType, tOrderType, Market, PaperInstruments } from 'src/constants/contract';
import { isFooterTabActive, FooterTabs } from 'src/_helpers/session';

export const useTerminateOrderMutation = (props = {}, order) => {
	const { t } = useTranslation();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	return useMutation(terminateOrder, {
		...props,
		onSuccess: async (result, variables) => {
			await queryClient.invalidateQueries(['order', variables.id]);
			props.onSuccess?.(result, variables);
		},
		onError: err => {
			props.onError?.(err);
			addToast({
				message: t('terminate_{{order_type}}_failed', {
					order_type: tOrderType(t, order.order_type).toLowerCase(),
				}),
				kind: 'error',
			});
		},
	});
};

export const WithdrawOrderModal = ({
	orderId,
	orderType,
	orderVersion,
	hasActiveNegotiations,
	environment,
	instrument,
	market,
	trigger,
}) => {
	const { t } = useTranslation();
	const [isOpen, { open, close }] = useTogglable();
	const [shouldCloseNegotiations, setShouldCloseNegotiations] = useState(false);
	const queryClient = useQueryClient();

	const { mutate, isLoading } = useTerminateOrderMutation({
		onSuccess: async () => {
			if (market === Market.Physical) {
				await queryClient.invalidateQueries(['physical-orders']);
			}

			if (isFooterTabActive(FooterTabs.MyOrders)) {
				await queryClient.invalidateQueries(['my-orders']);
			}

			if (isFooterTabActive(FooterTabs.MyOrderHistory)) {
				await queryClient.invalidateQueries(['my-orders-history']);
			}

			close();
		},
	});

	const withdrawOrder = () => {
		mutate({
			id: orderId,
			environment,
			shouldCloseNegotiations: JSON.parse(shouldCloseNegotiations),
			version: orderVersion,
		});
	};

	const isSpread = market === Market.Paper && instrument === PaperInstruments.Spread;
	const isBid = orderType === OrderType.Buy;
	const subtitleKey = isSpread
		? 'are_you_sure_to_withdraw_spread'
		: isBid
		? 'are_you_sure_to_withdraw_bid'
		: 'are_you_sure_to_withdraw_offer';
	const checkboxKey = isSpread
		? 'close_negotiations_spread'
		: isBid
		? 'close_negotiations_bid'
		: 'close_negotiations_offer';

	const titleKey = isSpread
		? 'confirm_spread_withdraw'
		: isBid
		? 'confirm_bid_withdraw'
		: 'confirm_offer_withdraw';

	return (
		<Modal
			data-test="terminate-order"
			size={ModalSize.Tiny}
			type={ModalType.Warning}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<ModalTitle>{t(titleKey)}</ModalTitle>
				<StyledModalSubTitle>
					<SubtitleWrapper>{t(subtitleKey)}</SubtitleWrapper>
					{hasActiveNegotiations && (
						<CloseNegotiationsCheckbox
							label={t(checkboxKey)}
							name="closeNegotiations"
							data-test="close-negotiations-checkbox"
							value={JSON.stringify(shouldCloseNegotiations)}
							onChange={() => setShouldCloseNegotiations(!shouldCloseNegotiations)}
						/>
					)}
				</StyledModalSubTitle>
			</ModalHeaderWrapper>
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				<NegativeButton loading={isLoading} onClick={withdrawOrder}>
					{t('withdraw')}
				</NegativeButton>
			</ButtonsWrapper>
		</Modal>
	);
};

const SubtitleWrapper = styled.div`
	text-align: center;
	line-height: 21px;
`;

const CloseNegotiationsCheckbox = styled(Checkbox)`
	margin-top: 16px;

	&.ui.checkbox input:focus ~ label {
		color: var(--text-color-secondary);
	}
`;

const StyledModalSubTitle = styled(ModalSubTitle)`
	margin-bottom: 40px;
	width: 100%;
`;
