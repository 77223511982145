/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { Label } from '../Label/Label';
import { Select } from '../Select/Select';
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg';
import * as Styled from './styled';

export const MaterialSelectInput = React.forwardRef(
	(
		{
			label,
			id,
			required,
			searchIcon,
			span,
			controlledState = false,
			hasChanged = false,
			...props
		},
		ref
	) => {
		const hasValue = !!props.value;
		const filledControlledState = controlledState === true && hasChanged === false && hasValue;
		const filledState = controlledState ? hasChanged === true : hasValue;
		const isValid = hasValue && !props.disabled;

		props.error = props.error && !isValid;

		const searchProps = searchIcon
			? {
					icon: <SearchIcon className="dropdown icon" />,
					iconRotation: false,
			  }
			: {};

		return (
			<Styled.InputContainer
				className={clsx(
					'select-input',
					props.search && 'search',
					filledState && 'input-filled',
					filledControlledState && 'input-filled-controlled'
				)}
				$span={span}
				ref={ref}
			>
				<Select id={id} required={required} {...props} {...searchProps} />
				<Label htmlFor={id}>
					{label}
					{required && <span className="asterisk">*</span>}
				</Label>
				{isValid && <Styled.ValidCheckIcon />}
			</Styled.InputContainer>
		);
	}
);
