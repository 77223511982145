/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { Dateformat } from 'src/_helpers/date';
import { dateFromUnix } from 'src/_helpers/yup';
import { timezoneDateToIsoString, getCurrentTzOrDefault } from 'src/components/DateTimeTzPicker';
import {
	DrawerPriceType,
	Environment,
	Market,
	NameVisibility,
	OrderType,
	PriceType,
	QuoteType,
	defaultCurrencyUnit,
	Role,
	PriceTooBigKey,
	MaxBasisPrice,
	PriceTooSmallKey,
	PaperInstruments,
} from 'src/constants/contract';
import { TimeOptions } from 'src/constants/timeRangeOptions';
import { getNumberOfMonths } from 'src/_helpers/date';
import * as yup from 'yup';
import { isPriceDefined } from 'src/_helpers/price';

export const contractPricingPath = 'contractPricing';

export const paranaguaDefaultVolume = 10000;

export const defaultContractPricingRowValue = {
	orderType: QuoteType.Indicative,
	nameVisibility: NameVisibility.Hidden,
	delivery: {
		format: Dateformat.Months,
	},
	firstLegMonth: {
		format: Dateformat.Months,
	},
	secondLegMonth: {
		format: Dateformat.Months,
	},
	currencyUnit: defaultCurrencyUnit,
	environment: Environment.Exchange,
	role: Role.Principal,
};

export const defaultIndicativeTimeValue = 'MIN1440';
export const defaultFirmTimeValue = 'MIN5';

export const defaultValidity = {
	tz: getCurrentTzOrDefault(),
	localDate: moment().add(TimeOptions[defaultIndicativeTimeValue], 'minutes').toDate(),
	time: defaultIndicativeTimeValue,
};

export const defaultValues = {
	contractPricing: [defaultContractPricingRowValue],
	validity: defaultValidity,
	validityChanged: false,
	futuresMonthChanged: false,
	firstLegFuturesMonthChanged: false,
	secondLegFuturesMonthChanged: false,
	instrument: PaperInstruments.Outright,
};

const shipmentSchema = yup
	.object({
		startDate: yup.string().required(),
		endDate: yup.string().required(),
		format: yup.mixed().oneOf(Object.values(Dateformat)),
	})
	.required();

const priceTooBigTest = unit => ({
	name: PriceTooBigKey,
	params: { unit },
	message: unit,
	test: price => {
		if (price >= MaxBasisPrice) {
			return false;
		}
		return true;
	},
});

const priceTooSmallTest = unit => ({
	name: PriceTooSmallKey,
	params: { unit },
	message: unit,
	test: price => {
		if (price <= -1 * MaxBasisPrice) {
			return false;
		}
		return true;
	},
});

export const createPaperOrderSchema = yup.object({
	presetID: yup.string().required(),
	instrument: yup.mixed().oneOf(Object.values(PaperInstruments)).required(),
	contractPricing: yup.array(
		yup.object().shape({
			orderType: yup.mixed().oneOf(Object.values(QuoteType)).required(),
			type: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Outright) {
					return yup.mixed().oneOf(Object.values(OrderType)).required();
				}
			}),
			nameVisibility: yup.mixed().oneOf(Object.values(NameVisibility)).required(),
			firstLegMonth: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Spread) {
					return shipmentSchema;
				}
			}),
			secondLegMonth: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Spread) {
					return shipmentSchema;
				}
			}),
			delivery: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Outright) {
					return shipmentSchema;
				}
			}),
			priceType: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Outright) {
					return yup
						.mixed()
						.oneOf([DrawerPriceType.Flat, DrawerPriceType.CBOT])
						.required();
				}
			}),
			firstLegPrice: yup
				.mixed()
				.when(
					['$instrument', 'currencyUnit', 'orderType'],
					(instrument, unit, orderType) => {
						if (instrument === PaperInstruments.Spread) {
							if (orderType === QuoteType.Firm) {
								return yup
									.number()
									.test(priceTooBigTest(unit))
									.test(priceTooSmallTest(unit))
									.required();
							}

							return yup
								.mixed()
								.test(priceTooBigTest(unit))
								.test(priceTooSmallTest(unit))
								.nullable();
						}
					}
				),
			price: yup
				.mixed()
				.when(
					['$instrument', 'priceType', 'currencyUnit'],
					(instrument, priceType, unit) => {
						if (instrument === PaperInstruments.Outright) {
							if (priceType !== DrawerPriceType.Flat) {
								return yup
									.number()
									.test(priceTooBigTest(unit))
									.test(priceTooSmallTest(unit))
									.required();
							} else {
								return yup
									.number()
									.test('min', val => val >= 0)
									.required();
							}
						} else {
							return yup
								.number()
								.test(priceTooBigTest(unit))
								.test(priceTooSmallTest(unit))
								.required();
						}
					}
				),
			runs: yup.mixed().when('$runsRequired', $validateRuns => {
				return $validateRuns
					? yup
							.number()
							.test('min', val => val > 0)
							.required()
					: yup.mixed();
			}),
			volume: yup.number().required().min(1),
			futuresMonth: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Outright) {
					return yup.mixed().when('priceType', {
						is: val => val !== DrawerPriceType.Flat,
						then: yup.date().transform(dateFromUnix).required(),
					});
				}
			}),
			firstLegFuturesMonth: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Spread) {
					return yup.date().transform(dateFromUnix).required();
				}
			}),
			secondLegFuturesMonth: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Spread) {
					return yup.date().transform(dateFromUnix).required();
				}
			}),
			environment: yup.mixed().oneOf(Object.values(Environment)).required(),
			role: yup.mixed().oneOf(Object.values(Role)).required(),
			counterparties: yup
				.mixed()
				.when(['environment', '$paperCounter'], (environment, $paperCounter) => {
					return environment === Environment.OTC && !$paperCounter
						? yup.array().required()
						: yup.array();
				}),
		})
	),
	validity: yup
		.object({
			time: yup.string(),
			localDate: yup.date().when('time', time => {
				if (!!time) {
					return yup.date();
				}
				return yup.date().test('min', val => yup.date().min(new Date()).isValidSync(val));
			}),
			tz: yup.string(),
		})
		.required(),
});

export const mapDataToApi = data => {
	const { presetID, contractPricing, validity, instrument } = data;

	return contractPricing.map(orderRow => {
		const recipients = (orderRow.fullContactsList || [])
			.map(user => user._key)
			.filter((id, index, list) => list.indexOf(id) === index);

		const calendarSpreadResult =
			instrument === PaperInstruments.Spread
				? {
						delivery_date_from: orderRow.firstLegMonth.startDate,
						delivery_date_to: orderRow.firstLegMonth.endDate,
						delivery_mode: orderRow.firstLegMonth.format,
						futures_contract_date: moment(orderRow.firstLegFuturesMonth).toISOString(),
						spread_details: {
							delivery_date_from: orderRow.secondLegMonth.startDate,
							delivery_date_to: orderRow.secondLegMonth.endDate,
							delivery_mode: orderRow.secondLegMonth.format,
							futures_contract_date: moment(
								orderRow.secondLegFuturesMonth
							).toISOString(),
							first_leg_price: orderRow.firstLegPrice
								? +orderRow.firstLegPrice
								: undefined,
						},
						volume: +orderRow.volume,
				  }
				: {};

		return {
			market: Market.Paper,
			preset_id: presetID,
			instrument: instrument,
			environment: orderRow.environment,
			role: orderRow.role,
			hidden: orderRow.nameVisibility === NameVisibility.Hidden,
			runs: orderRow.runs ? +orderRow.runs : null,
			is_indicative: orderRow.orderType === QuoteType.Indicative,
			order_type: orderRow.type,
			has_price: true, // required by BE for model consistency
			delivery_date_from: orderRow.delivery.startDate,
			delivery_date_to: orderRow.delivery.endDate,
			delivery_mode: orderRow.delivery.format,
			volume: +orderRow.totalVolume,
			price: +orderRow.price,
			price_type: orderRow.priceType !== PriceType.Flat ? PriceType.Basis : PriceType.Flat,
			...(orderRow.priceType !== PriceType.Flat && {
				futures_contract: orderRow.priceType,
				futures_contract_date: moment(orderRow.futuresMonth).toISOString(),
			}),
			...(orderRow.environment === Environment.OTC && {
				recipients,
			}),
			...calendarSpreadResult,
			validity_time: validity.time || '',
			validity: validity.time
				? timezoneDateToIsoString({
						localDate: moment().add(TimeOptions[validity.time], 'minutes').toDate(),
						tz: validity.tz,
				  })
				: timezoneDateToIsoString(validity),
			validity_timezone: validity.tz,
			...(data.version && { version: data.version }),
		};
	});
};

export const mapPreviewDataToOrderView = (preview, formData, user) => {
	const { selectedProductPreset, contractPricing } = formData;
	const { inco_id, product } = selectedProductPreset;

	return contractPricing.map((orderRow, index) => {
		const [currency, price_unit] = orderRow.currencyUnit.split('/');

		return {
			...preview[index],
			role: orderRow.role,
			is_order_preview: true,
			product,
			inco: {
				name: inco_id,
			},
			volume: orderRow.totalVolume,
			...(preview[index].instrument === PaperInstruments.Spread && {
				secondLegQuote: orderRow.secondLegQuote,
				firstLegMonth: orderRow.firstLegMonth,
				secondLegMonth: orderRow.secondLegMonth,
				firstLegFuturesMonth: orderRow.firstLegFuturesMonth,
				secondLegFuturesMonth: orderRow.secondLegFuturesMonth,
				firstLegPrice: orderRow.firstLegPrice,
				volume: orderRow.volume,
			}),
			primary_ports: [
				{
					name:
						selectedProductPreset.loading_port?.name ||
						selectedProductPreset.discharging_port?.name ||
						'',
					country_id: selectedProductPreset.origin_country_id,
				},
			],
			environment: orderRow.environment,
			price: orderRow.price,
			currency,
			price_unit,
			order_type: orderRow.type,
			futures_contract_date: orderRow.futuresMonth,
			origin_countries: [...selectedProductPreset?.origin_countries],
			shipment_type: selectedProductPreset.shipment_type,
			user: {
				_key: user.session._key,
				avatar_color: user.avatar_color,
				name: user.session.name,
				first_name: user.session.first_name,
				last_name: user.session.last_name,
				company: {
					name: user.company_name,
					avatar_color: user.company_avatar_color,
				},
			},
			hidden: orderRow.nameVisibility === NameVisibility.Hidden,
			terms: selectedProductPreset.terms,
			grade: selectedProductPreset.grade,
			runs: orderRow.runs,
			...(orderRow.environment === Environment.OTC && {
				recipients_list: !!orderRow.fullContactsList.length
					? orderRow.fullContactsList
					: orderRow.recipients_list,
			}),
		};
	});
};

export const getPaperDefaultValuesFromApi = (response, recipients, isEditing, isCopying) => {
	const editingParams = isEditing
		? {
				created_at: response.created_at,
				_key: response._key,
				version: response.version,
				isEditing,
		  }
		: {
				isCopying,
		  };

	return {
		...editingParams,
		presetID: response.preset_id,
		instrument: response.instrument || PaperInstruments.Outright,
		contractPricing: [
			{
				orderType: response.is_indicative ? QuoteType.Indicative : QuoteType.Firm,
				type: response.order_type,
				role: response.role,
				environment: response.environment,
				nameVisibility: response.hidden ? NameVisibility.Hidden : NameVisibility.Visible,
				delivery: {
					startDate: response.delivery_date_from,
					endDate: response.delivery_date_to,
					format: response.delivery_mode,
				},
				priceType: response.has_price
					? response.price_type === PriceType.Flat
						? DrawerPriceType.Flat
						: response.futures_contract
					: DrawerPriceType.NoPrice,
				price: isPriceDefined(response.price) ? response.price.toString() : response.price,
				...(response.runs && { runs: response.runs }),
				volume: response.multi_month
					? response.volume /
					  getNumberOfMonths(response.delivery_date_from, response.delivery_date_to)
					: response.volume,
				totalVolume: response.volume,
				futuresMonth: response.futures_contract_date
					? new Date(response.futures_contract_date).valueOf()
					: undefined,
				counterparties: recipients || response.recipients || [],
				recipients_list: response.recipients_list || [],
				...(response.instrument === PaperInstruments.Spread && {
					secondLegQuote: response.secondLegQuote,
					firstLegMonth: response.firstLegMonth,
					secondLegMonth: response.secondLegMonth,
					firstLegFuturesMonth: +new Date(response.futures_contract_date),
					secondLegFuturesMonth: +new Date(response.spread_details.futures_contract_date),
					firstLegPrice: response.firstLegPrice,
				}),
			},
		],
		// validity
		validity: response.force_validity
			? {
					tz: getCurrentTzOrDefault(),
					localDate: moment()
						.add(TimeOptions[response.force_validity], 'minutes')
						.toDate(),
					time: response.force_validity,
			  }
			: defaultValidity,
	};
};

export const mapCounterFormToCounterAPI = (values, order, userId, negotiationId, lastCounter) => {
	const counterValues = values.contractPricing[0];

	return {
		instrument: values.instrument,
		type_id: order.type_id,
		inco_id: order.inco_id,
		product_id: order.product_id,
		order_type: order.order_type,
		role: order.role,
		price_type: order.price_type,
		futures_contract: order.futures_contract,
		futures_contract_date: order.futures_contract_date,
		delivery_date_from: order.delivery_date_from,
		delivery_date_to: order.delivery_date_to,
		order_owner_principal_id: counterValues.principalId || lastCounter.order_owner_principal_id,
		counter_order_user_id: userId,
		first_counter_id: negotiationId,
		environment: order.environment,
		market: order.market,
		order_id: order._key,
		is_indicative: counterValues.orderType === QuoteType.Indicative,
		price: +counterValues.price,
		runs: counterValues.runs ? +counterValues.runs : null,
		volume: +counterValues.totalVolume,
		...(values.instrument === PaperInstruments.Spread && {
			volume: +counterValues.volume,
			spread_details: {
				first_leg_price: counterValues.firstLegPrice
					? +counterValues.firstLegPrice
					: undefined,
			},
		}),
		validity: values.validity.time
			? timezoneDateToIsoString({
					localDate: moment().add(TimeOptions[values.validity.time], 'minutes').toDate(),
					tz: values.validity.tz,
			  })
			: timezoneDateToIsoString(values.validity),
	};
};
