/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { createRoot } from 'react-dom/client';
import { useDatafeed } from 'src/containers/Dashboard/Chart/useDatafeed';
import { useChartFilters } from '../helpers/useChartFilters';
import { useCreateWidgetOptions } from '../helpers/useCreateWidgetOptions';
import { useChartState } from 'src/containers/Dashboard/Chart/useChartState';
import { initialChartFilters } from 'src/_store/reducers/chart.reducers';
import { Orientation, ChartEvent } from 'src/constants/chart';
import { useQueryParams } from 'src/_helpers/router';
import { getBuyColor, getSellColor, useAdjustedChartSettings } from 'src/_helpers/chart';
import { Market } from 'src/constants/contract';
import { ReactComponent as InfoSVG } from 'src/assets/icons/info.svg';
import { useChartRealTimeUpdates } from 'src/containers/Dashboard/Chart/useChartRealTimeUpdates';
// import { MobileFullscreenButton } from './MobileFullscreenButton';

const ChartType = {
	Area: 3,
};

export const useSaveMobileChartSettings = ({
	orientation,
	chartStateStorageKey,
	mobileChartResolutionKey,
}) => {
	const { t } = useTranslation();
	const [entriesLength, setEntriesLength] = useState(null);
	const datafeed = useDatafeed({ t, setEntriesLength });
	const chartActiveFilters = useChartFilters();
	const widgetOptions = useCreateWidgetOptions(orientation);
	const { chartState, saveChartState } = useChartState(chartStateStorageKey);
	const widget = useRef();

	const chartResolution = window.localStorage.getItem(mobileChartResolutionKey);

	const { colorSchema } = useQueryParams();

	const forcedMarket = chartActiveFilters?.paper ? Market.Paper : Market.Physical;

	const { adjustChartColors } = useAdjustedChartSettings(
		chartState,
		saveChartState,
		orientation === Orientation.Portrait
	);

	useChartRealTimeUpdates({
		widget,
		resetChartDataFn: datafeed.resetChartDataFn,
		updateDataPointFn: datafeed.updateDataPointFn,
		entriesLength,
		setEntriesLength,
	});

	useEffect(() => {
		if (chartState) {
			const buyColor = getBuyColor(colorSchema);
			const sellColor = getSellColor(colorSchema);

			adjustChartColors(buyColor, sellColor);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colorSchema]);

	// TODO: Move to EventBus
	const infoIconClickHandler = () => {
		const event = new CustomEvent(ChartEvent.MobileChartGuide);

		window.dispatchEvent(event);
	};

	// const isFullscreen = orientation === Orientation.Landscape;

	// TODO: Move to EventBus
	// const fullscreenIconClickHandler = () => {
	// 	const event = new CustomEvent(ChartEvent.ToggleFullscreen, {
	// 		detail: {
	// 			fullscreen: !isFullscreen,
	// 		},
	// 	});

	// 	window.dispatchEvent(event);
	// };

	useEffect(() => {
		if (!widget.current && window.TradingView) {
			const tvWidget = new window.TradingView.widget({
				...widgetOptions,
				symbol: datafeed.createAsset(
					chartActiveFilters?.[forcedMarket] || initialChartFilters[Market.Physical] // falback to Physical filters in case no asset is passed
				).ticker,
				saved_data: chartState,
				datafeed,
			});

			window.tvWidget = tvWidget;

			tvWidget.onChartReady(() => {
				widget.current = tvWidget;

				tvWidget.headerReady().then(() => {
					// let fullscreenButtonAlignment = 'right';

					if (orientation === Orientation.Portrait) {
						tvWidget.activeChart().setChartType(ChartType.Area);

						// fullscreenButtonAlignment = 'left';

						const infoGuideButton = tvWidget.createButton({ align: 'left' });
						const infoGuideButtonRoot = createRoot(infoGuideButton);

						infoGuideButtonRoot.render(
							<span onClick={infoIconClickHandler}>
								<InfoSVG
									style={{
										width: '16px',
										height: '16px',
										color: '#757ba9', // --neutral-300
									}}
								/>
							</span>
						);

						const rightSymbolContainer = tvWidget.createButton({ align: 'right' });
						const rightSymbolContainerRoot = createRoot(rightSymbolContainer);

						rightSymbolContainerRoot.render(
							<span>{tvWidget.symbolInterval().symbol}</span>
						);
					}

					// const fullScreenButton = tvWidget.createButton({
					// 	align: fullscreenButtonAlignment,
					// });
					// const fullScreenButtonRoot = createRoot(fullScreenButton);

					// fullScreenButton.setAttribute('title', t('fullscreen_mode'));
					// fullScreenButton.classList.add('tv_button', 'tv_fullscreen_button');

					// fullScreenButtonRoot.render(
					// 	<MobileFullscreenButton
					// 		clickHandler={fullscreenIconClickHandler}
					// 		isFullscreen={isFullscreen}
					// 	/>
					// );
				});

				tvWidget
					.activeChart()
					.onSymbolChanged()
					.subscribe(null, () => {
						tvWidget.save(state => {
							saveChartState(state);
						});
					});

				tvWidget
					.activeChart()
					.onIntervalChanged()
					.subscribe(null, () => {
						tvWidget.save(state => {
							saveChartState(state);
						});
						window.localStorage.setItem(
							mobileChartResolutionKey,
							tvWidget.activeChart().resolution()
						);
					});

				tvWidget
					.activeChart()
					.onChartTypeChanged()
					.subscribe(null, () => {
						tvWidget.save(state => {
							saveChartState(state);
						});
					});

				tvWidget
					.activeChart()
					.onVisibleRangeChanged()
					.subscribe(null, () => {
						tvWidget.save(state => {
							saveChartState(state);
						});
					});

				// Perform chart state update on every other user action e.g. added drawings; minimum interval is 5 seconds
				tvWidget.subscribe('onAutoSaveNeeded', () => {
					tvWidget.save(state => {
						saveChartState(state);
					});
				});

				tvWidget.activeChart().setResolution(chartResolution);
			});

			return () => {
				tvWidget.remove();
				widget.current = null;
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartActiveFilters]);

	return { entriesLength, isLoading: datafeed.isLoading };
};
