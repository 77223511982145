/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pill } from 'src/components/Pill/Pill';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { getUserId, getUserCompanyAvatarColor } from 'src/_store/selectors';
import { H400 } from 'src/components/Typography';
import { AvatarType } from 'src/shared/components/Avatar/Avatar';
import { useMediaQuery, screenSize } from 'src/_helpers/media-queries';
import { CompanyAvatar } from 'src/components/CompanyAvatar/CompanyAvatar';
import { getUserBrokerPrincipal } from './getUserBrokerPrincipal';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { ContentCell } from '../styled';
import { getUserSide } from './getUserSide';
import * as Styled from './styled';
import { PaperInstruments } from 'src/constants/contract';

export const OrderCreatorBox = ({ order, changes = {} }) => {
	const { t } = useTranslation();
	const currentUserId = useSelector(getUserId);
	const isOwnOrder = currentUserId === order.user?._key;
	const isTraded = !!order.trade_id;

	const companyColor = useSelector(getUserCompanyAvatarColor);

	const {
		user,
		userRole,
		principal,
		broker,
		hiddenIdentity,
		hiddenPrincipal,
	} = getUserBrokerPrincipal({
		companyColor,
		currentUserId,
		order,
		t,
	});

	const isLargeScreen = useMediaQuery({ 'min-width': screenSize.xl });
	const AvatarSize = isLargeScreen ? AvatarType.Large : AvatarType.Medium;

	const userToShow = principal || user;
	let userSide = getUserSide(broker || user, order);

	if (order.instrument === PaperInstruments.Spread) {
		userSide = `first_leg_${userSide}`;
	}

	const userToShowIsMe = userToShow._key === currentUserId;

	const shouldShowBadge =
		!userToShowIsMe && (!!order.is_my_approved_contact_order || !!order.is_my_approved_contact);

	return (
		<ContentCell data-test="order-creator-box">
			<header>{t(userSide)}</header>
			<section>
				<Styled.UserContainer
					$isHiddenAndOwnOrder={isOwnOrder && hiddenIdentity}
					className={clsx({ changed: changes.order_owner_principal_id })}
					$isTraded={isTraded}
				>
					<CompanyAvatar
						user={userToShow}
						className="order-creator-user-avatar"
						size={AvatarSize}
						isUserHidden={
							isOwnOrder ? hiddenPrincipal : hiddenIdentity || hiddenPrincipal
						}
						userNameLabel={!!principal || hiddenPrincipal ? t('principal') : ''}
						showUserName={!hiddenPrincipal && !!userToShow}
						usernameChildren={
							<>
								{hiddenIdentity ? (
									<Styled.HiddenContainer>
										{isOwnOrder && (
											<Styled.OwnOrderHiddenText>
												{t('hidden')}
											</Styled.OwnOrderHiddenText>
										)}
										<Tooltip>
											<TooltipTrigger>
												<Styled.InfoIcon data-test="info-icon" />
											</TooltipTrigger>
											<TooltipContent>
												{isOwnOrder
													? t('your_user_and_company_are_hidden')
													: t('user_company_name_are_hidden')}
											</TooltipContent>
										</Tooltip>
									</Styled.HiddenContainer>
								) : (
									hiddenPrincipal && (
										<Styled.HiddenContainer>
											<Tooltip>
												<TooltipTrigger>
													<Styled.InfoIcon data-test="info-icon" />
												</TooltipTrigger>
												<TooltipContent>
													{t('no_principal_provided')}
												</TooltipContent>
											</Tooltip>
										</Styled.HiddenContainer>
									)
								)}
							</>
						}
					/>
				</Styled.UserContainer>
				<Styled.FooterContainer>
					{!!broker ? (
						<UserProfileLink
							userId={broker._key}
							isInactive={isInactiveUser({ status: broker.status })}
							hasHoverEffect={false}
						>
							<Styled.BrokerUser $withBadge={shouldShowBadge}>
								<label>{t('broker')}</label>
								<OverflowText>
									<span>
										{broker.company?.name || broker.company}
										{` • `}
										{broker.name}
									</span>
								</OverflowText>
							</Styled.BrokerUser>
						</UserProfileLink>
					) : (
						<Pill>
							<H400 as="span" data-test="order-creator-principal">
								{userRole}
							</H400>
						</Pill>
					)}
					{shouldShowBadge && (
						<Tooltip>
							<TooltipTrigger>
								<Styled.MyContactBadge data-test="order-creator-my-approved-contacts">
									<Styled.BadgeIcon />
								</Styled.MyContactBadge>
							</TooltipTrigger>
							<TooltipContent>{t('approved_counterparty')}</TooltipContent>
						</Tooltip>
					)}
				</Styled.FooterContainer>
			</section>
		</ContentCell>
	);
};
