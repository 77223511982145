/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Avatar, AvatarType, BorderSize } from 'src/shared/components/Avatar/Avatar';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { OverflowText } from '../Tooltip/OverflowText';
import * as Styled from './styled';

export const CompanyAvatar = ({
	user,
	onModalActionClick = () => {},
	className,
	size = AvatarType.Large,
	isUserHidden = false,
	userNameLabel = '',
	showUserName = true,
	usernameChildren = null,
}) => {
	const userId = user.contact_user_id || user._key || user.id;

	const companyName = user.company?.name || user.company;
	const companyInitials = companyName.charAt(0);
	const borderAvatarColor = isUserHidden ? 'var(--neutral-400)' : user.company.avatar_color;

	return (
		<UserProfileLink
			userId={userId}
			onModalActionClick={onModalActionClick}
			isInactive={isInactiveUser({ status: user.status }) || isUserHidden || !userId}
			hasHoverEffect={false}
		>
			<Styled.UserDetailsContainer data-test="user-avatar">
				<Avatar
					initials={companyInitials}
					size={size}
					backgroundColor="var(--neutral-850)"
					borderColor={borderAvatarColor}
					dotBackgroundColor="var(--neutral-700)"
					data-test={`avatar-${companyInitials}-${user._key}`}
					className={className}
					borderSize={BorderSize.large}
				/>
				<Styled.UserDetails className="user-details">
					<Styled.CompanyContainer className="user-company-container">
						<div className="user-avatar-container">
							<OverflowText>
								<Styled.Name data-test={`user-avatar-company-${user._key}`}>
									{companyName}
								</Styled.Name>
								<UserStatus status={user.status} />
							</OverflowText>
						</div>
						{usernameChildren}
					</Styled.CompanyContainer>
					<Styled.UserInfo>
						{!!userNameLabel && (
							<Styled.PrincipalLabel>{userNameLabel}</Styled.PrincipalLabel>
						)}
						{showUserName && (
							<OverflowText>
								<Styled.UserNameContainer>
									{isUserHidden ? (
										'---'
									) : (
										<OverflowText>{user.name}</OverflowText>
									)}
								</Styled.UserNameContainer>
							</OverflowText>
						)}
					</Styled.UserInfo>
				</Styled.UserDetails>
			</Styled.UserDetailsContainer>
		</UserProfileLink>
	);
};
