/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';
import { formatValidityShort } from 'src/_helpers/date';
import { TimeOptions, tTimeOptions } from 'src/constants/timeRangeOptions';
import { useViewOrderDrawerContext } from 'src/components/ViewOrderDrawer/ViewOrderDrawerProvider';
import * as Styled from './styled';

export const CounterPreviewFooter = ({ onSubmit, isSubmitting }) => {
	const { t } = useTranslation();

	const {
		[DrawerContextKeys.viewDrawer]: { counterPreviewData, setCounterPreviewData },
	} = useDrawerContext();

	const {
		orderWithdrawn,
		orderEditedWithoutNegotiation,
		negotiationsWithdrawn,
	} = useViewOrderDrawerContext();

	const hasOrderUpdated =
		orderWithdrawn || orderEditedWithoutNegotiation || negotiationsWithdrawn;

	return (
		<Styled.DrawerFooter>
			<Styled.Validity>
				<label>{t('validity')}</label>
				<div>
					{counterPreviewData.validity_time
						? tTimeOptions(t, TimeOptions[counterPreviewData.validity_time])
						: formatValidityShort(counterPreviewData.validity)}
				</div>
			</Styled.Validity>
			<Styled.BackButton
				onClick={() => setCounterPreviewData(undefined)}
				disabled={hasOrderUpdated}
			>
				{t('back')}
			</Styled.BackButton>
			<Styled.SubmitButton
				onClick={onSubmit}
				loading={isSubmitting}
				disabled={hasOrderUpdated}
			>
				{t('submit_counter')}
			</Styled.SubmitButton>
		</Styled.DrawerFooter>
	);
};
