/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { listTerms, getMyContacts } from 'src/_api';
import { getUserId } from 'src/_store/selectors';
import {
	CUSTOM_CONTRACT_TERMS,
	Market,
	PerformanceBondOptions,
	Role,
} from 'src/constants/contract';
import { useCustomContract } from 'src/components/CreateOrderDrawer/physical/sections/AdditionalInfoSection/useCustomContract';
import { useWatchPhysicalCounterFields } from '../../helpers/useWatchPhysicalCounterFields';
import { sortOrder } from 'src/_helpers/sort';
import { mapCounterComments } from 'src/components/ViewOrderDrawer/helpers/mapCounterComments';

export const useAdditionalInfoCounterFields = lastCounter => {
	const userId = useSelector(getUserId);
	const { t } = useTranslation();
	const {
		setValue,
		control: { _defaultValues },
	} = useFormContext();

	const {
		productTypeValue,
		contractTermsValue,
		contractNumberValue,
		performanceBondValue,
		// principal id set in current counter
		principalIdValue,
		// principal id received from previous counter
		orderOwnerPrincipalIdValue,
	} = useWatchPhysicalCounterFields();

	/** CUSTOM CONTRACT */
	const customContract = useCustomContract();

	/** CONTRACT TERMS */
	const { data: contractTermsOptions = [], isLoading: isLoadingContractTerms } = useQuery(
		['terms', productTypeValue],
		() => listTerms(productTypeValue, Market.Physical),
		{
			select: list => {
				const options = list.map(({ _key, name }) => ({
					text: name,
					value: _key,
				}));
				return [
					{
						text: t('custom'),
						value: 'custom',
					},
					...options,
				];
			},
		}
	);

	useEffect(() => {
		setValue('contractTermsOptions', contractTermsOptions);
	}, [contractTermsOptions, setValue]);

	useEffect(() => {
		if (contractTermsValue !== CUSTOM_CONTRACT_TERMS && customContract.file) {
			customContract.onDelete(false);
		}
	}, [contractTermsValue, customContract]);

	/** CONTRACT NUMBER */
	useEffect(() => {
		if (contractTermsValue === null || contractTermsValue === CUSTOM_CONTRACT_TERMS) {
			setValue('contractNumber', '');
		}
	}, [contractTermsValue, setValue]);

	/** COMMENTS */
	const lastCounterComments = lastCounter?.comments || mapCounterComments(userId, lastCounter)[0];

	useEffect(() => {
		if (lastCounter?.comments) {
			setValue('allComments', lastCounter.comments);
		} else {
			setValue('allComments', lastCounterComments);
		}
	}, [lastCounter.comments, lastCounterComments, setValue]);

	/** BROKER */
	const shouldShowPrincipal =
		lastCounter.role === Role.Broker && lastCounter.order_user_id === userId;

	/** OTC COUNTERPARTIES */
	const { data: allContacts = [] } = useQuery(
		[
			'my-contacts',
			{
				_limit: 1000,
				is_my_region: true,
				otc_nominable: true,
				_sort: 'name',
				_order: sortOrder.asc,
			},
		],
		({ queryKey: [, params] }) => getMyContacts(params)
	);

	const counterpartyId = lastCounter.user_id;

	const contactsOptions = useMemo(() => {
		return allContacts
			.filter(contact => contact.contact_user_id !== counterpartyId)
			.map(contact => ({
				text: `${contact.name} - ${contact.company_name}`,
				key: contact.contact_user_id,
				value: contact.contact_user_id,
			}));
	}, [allContacts, counterpartyId]);

	useEffect(() => {
		if (allContacts) {
			const newPrincipalObject = allContacts.find(
				contact => contact.contact_user_id === principalIdValue
			);

			if (!orderOwnerPrincipalIdValue && principalIdValue) {
				// we're setting principal for the first time
				setValue('principalObject', newPrincipalObject);
			} else if (orderOwnerPrincipalIdValue && !principalIdValue) {
				// show existing principal
				const oldPrincipalObject = allContacts.find(
					contact => contact.contact_user_id === orderOwnerPrincipalIdValue
				);
				setValue('principalObject', oldPrincipalObject);
				setValue('principalId', orderOwnerPrincipalIdValue);
			} else if (orderOwnerPrincipalIdValue && principalIdValue) {
				// we're updating principal
				setValue('principalObject', newPrincipalObject);
			} else {
				setValue('principalObject', null);
			}
		}
	}, [allContacts, orderOwnerPrincipalIdValue, principalIdValue, setValue]);

	return {
		performanceBond: {
			options: PerformanceBondOptions,
			hasValue: !!_defaultValues.performanceBond || !!performanceBondValue,
			hasChanged: _defaultValues.performanceBond !== performanceBondValue,
		},
		contractTerms: {
			options: contractTermsOptions,
			ready: !isLoadingContractTerms,
			hasValue: !!_defaultValues.contractTerms || !!contractTermsValue,
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.contractTerms != contractTermsValue,
		},
		contractNumber: {
			enabled: contractTermsValue && contractTermsValue !== CUSTOM_CONTRACT_TERMS,
			hasValue: !!_defaultValues.contractNumber || !!contractNumberValue,
			hasChanged: _defaultValues.contractNumber !== contractNumberValue,
		},
		principal: {
			options: contactsOptions,
			ready: true,
			visible: shouldShowPrincipal,
			// eslint-disable-next-line eqeqeq
			hasChanged: _defaultValues.order_owner_principal_id != principalIdValue,
			hasValue: !!_defaultValues.order_owner_principal_id || !!principalIdValue,
		},
		customContract,
		comments: lastCounterComments,
		canHideOptional:
			!contractTermsValue ||
			(contractTermsValue !== CUSTOM_CONTRACT_TERMS && !contractNumberValue) ||
			!performanceBondValue ||
			(shouldShowPrincipal && !principalIdValue),
	};
};
