/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DrawerFooter } from '../Drawer/Drawer';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { isBeforeToday } from 'src/_helpers/date';
import { useWatchPaperFields } from './paper/useWatchPaperFields';
import * as Styled from './styled';
import { PaperInstruments, OrderType } from 'src/constants/contract';

export const CreatePaperOrderDrawerFooter = ({
	progress = 0,
	onSubmit,
	previewAlwaysAvailable = false,
	onCancel,
	isSubmitting,
}) => {
	const { t } = useTranslation();

	const {
		formState: { errors },
		setValue,
	} = useFormContext();

	const { isEditing, instrumentValue } = useWatchPaperFields();
	const ordersCount = useWatch({ name: 'contractPricing' })?.length || 1;

	const typeValue = useWatch({ name: 'contractPricing.0.type' });

	const singleOutrightLabel = useMemo(() => {
		if (typeValue === OrderType.Buy) {
			return t('preview_bid');
		}
		if (typeValue === OrderType.Sell) {
			return t('preview_offer');
		}
		return t('preview_order');
	}, [typeValue, t]);

	return (
		<DrawerFooter progress={progress}>
			<Controller
				name="validity"
				render={({ field }) => (
					<Styled.ValidityInput
						required
						label={t('validity')}
						id="validity"
						error={!!errors[field.name]}
						popupHeaderTitle={t('validity')}
						readOnly
						filterDate={date => !isBeforeToday(date)}
						renderDateToggleButton
						withEllipsis
						isTimeDefault
						{...field}
						onChange={value => {
							field.onChange(value);
							setValue('validityChanged', true);
						}}
					/>
				)}
			/>
			{isEditing ? (
				<Styled.Buttons>
					<Styled.CancelButton $isEditing onClick={onCancel}>
						{t('cancel')}
					</Styled.CancelButton>
					<Styled.PreviewButton
						onClick={onSubmit}
						disabled={!previewAlwaysAvailable}
						loading={isSubmitting}
						$isEditing
					>
						{t('save')}
					</Styled.PreviewButton>
				</Styled.Buttons>
			) : (
				<Styled.PreviewButton onClick={onSubmit} disabled={!previewAlwaysAvailable}>
					{instrumentValue === PaperInstruments.Spread ? (
						t('preview_spread')
					) : ordersCount === 1 ? (
						singleOutrightLabel
					) : (
						<>
							{t('preview_order', { count: ordersCount })}{' '}
							{ordersCount > 1 && `(${ordersCount})`}
						</>
					)}
				</Styled.PreviewButton>
			)}
		</DrawerFooter>
	);
};
