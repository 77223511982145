/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import {
	WebsocketChannelType,
	PrivateWebsocketEventType,
	PublicWebsocketEventType,
} from 'src/constants/websockets';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { CounterStatus } from 'src/constants/counterStatus';

export const useRealTimeUpdates = ({ order, negotiation, accepteeId, setAccepteeId }) => {
	const currentUserId = useSelector(getUserId);

	const [orderEdited, setOrderEdited] = useState(false);
	const [orderEditedWithoutNegotiation, setOrderEditedWithoutNegotiation] = useState(false);
	const [orderWithdrawn, setOrderWithdrawn] = useState(false);
	const [negotiationsWithdrawn, setNegotiationsWithdrawn] = useState(false);
	const [negotiationUpdated, setNegotiationUpdated] = useState(false);
	const [tradeCreated, setTradeCreated] = useState(false);

	const setOrderAndNegotiationUpToDate = () => {
		setOrderEdited(false);
		setOrderWithdrawn(false);
		setOrderEditedWithoutNegotiation(false);
		setNegotiationUpdated(false);
		setNegotiationsWithdrawn(false);
		setTradeCreated(false);
	};

	const orderEditedCallback = useCallback(
		message => {
			const orderMessage = message?.data?.data;

			if (orderMessage._key === order._key && !order.is_my_order) {
				if (!negotiation) {
					setOrderEditedWithoutNegotiation(true);
				}

				setOrderEdited(true);
			}
		},
		[order._key, order.is_my_order, setOrderEdited, negotiation]
	);

	const orderWithdrawnCallback = useCallback(
		message => {
			const orderMessage = message?.data?.data;
			const areNegotiationsWithdrawn = orderMessage?.negotiations_withdrawn;

			if (!negotiation && orderMessage.order_id === order._key && !order.is_my_order) {
				setOrderWithdrawn(true);
			}

			if (
				negotiation &&
				orderMessage.order_id === order._key &&
				negotiation.status !== CounterStatus.Confirmed &&
				!order.is_my_order &&
				areNegotiationsWithdrawn
			) {
				setNegotiationsWithdrawn(true);
			}
		},
		[negotiation, order._key, order.is_my_order]
	);

	const newCounterCallback = useCallback(
		message => {
			if (
				message.data.data.order_id === order._key &&
				message.data.data.user_id !== currentUserId &&
				negotiation &&
				negotiation.first_counter_id === message.data.data.first_counter_id
			) {
				setNegotiationUpdated(true);
			}
		},
		[order._key, currentUserId, negotiation]
	);

	const tradeCreatedCallback = useCallback(
		message => {
			if (
				negotiation &&
				negotiation.first_counter_id === message.data.data.first_counter_id &&
				accepteeId !== currentUserId
			) {
				setNegotiationUpdated(true);
				setAccepteeId(null);
			}
		},
		[accepteeId, currentUserId, negotiation, setAccepteeId]
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NewTrade,
		tradeCreatedCallback
	);

	useWebsocket(
		WebsocketChannelType.ExchangeUpdates,
		PublicWebsocketEventType.WithdrawnOrder,
		orderWithdrawnCallback
	);

	useWebsocket(
		WebsocketChannelType.ExchangeUpdates,
		PublicWebsocketEventType.EditedExchangeOrder,
		orderEditedCallback
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.EditedOTCOrder,
		orderEditedCallback
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NewCounter,
		newCounterCallback
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PublicWebsocketEventType.WithdrawnOrder,
		orderWithdrawnCallback
	);

	return {
		orderEdited,
		orderEditedWithoutNegotiation,
		orderWithdrawn,
		negotiationsWithdrawn,
		negotiationUpdated,
		tradeCreated,
		setOrderAndNegotiationUpToDate,
	};
};
