/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	tEnvironment,
	calculate2ndLegPrice,
	LegType,
	QuoteType,
	tQuoteValueType,
} from 'src/constants/contract';
import { formatDateMonthYear } from 'src/_helpers/date';
import { ReactComponent as ExternalLinkIconSVG } from 'src/assets/icons/external_link_icon.svg';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';
import { SpreadLegRow } from 'src/components/ViewOrderDrawer/components/SpreadLegRow/SpreadLegRow';
import { useCalendarSpreadLegUsers } from './useCalendarSpreadLegUsers';
import {
	SectionContainer,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
	Divider,
} from '../../../styled';
import {
	CopyTradeLink,
	CopyOrderNegotiationLink,
} from 'src/components/ViewOrderDrawer/components/CopyOrderNegotiationLink/CopyOrderNegotiationLink';
import { CreatedAtText } from '../../AdditionalInformationSection/styled';
import * as Styled from './styled';
import { formatProductAndFuturesCode } from 'src/_helpers/futuresContracts';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';

export const CalendarSpreadSection = ({ order, changes = {} }) => {
	const { t } = useTranslation();
	const { futuresContract } = useOrderDetails(order);

	const { userLabels, users } = useCalendarSpreadLegUsers(order);

	// Note: price is undefined when the field is not touched, but it's empty string when the field was filled and then cleared
	const has1stLegPrice = order.firstLegPrice !== undefined && order.firstLegPrice !== '';

	const secondLegPrice = has1stLegPrice
		? calculate2ndLegPrice(order.firstLegPrice, order.price)
		: undefined;

	const firstLegPriceSuffix = formatProductAndFuturesCode(
		futuresContract.product_code,
		order.firstLegFuturesMonth
	);

	const secondLegPriceSuffix = formatProductAndFuturesCode(
		futuresContract.product_code,
		order.secondLegFuturesMonth
	);

	const tradeIds = order.trade_ids || [];
	const isTraded = !!tradeIds.length;
	const [firstLegTradeID, secondLegTradeID] = tradeIds;

	const firstLegData = [
		{
			label: t(userLabels[0]),
			value: users[0],
		},
		{
			label: t(userLabels[1]),
			value: users[1],
		},
		{
			label: t('first_leg_month'),
			value: formatDateMonthYear(order.firstLegMonth.startDate),
		},
		has1stLegPrice
			? {
					label: t('first_leg_price'),
					value:
						formatPriceWithoutCurrency(order.firstLegPrice) + ' ' + firstLegPriceSuffix,
					changed: changes.firstLegPrice,
			  }
			: {
					label: t('first_leg_futures'),
					value: formatDateMonthYear(new Date(order.firstLegFuturesMonth).toISOString()),
			  },
		isTraded && {
			label: t('trade_id'),
			wide: true,
			value: <CopyTradeLink tradeId={firstLegTradeID} />,
		},
	].filter(Boolean);

	const secondLegData = [
		{
			label: t(userLabels[0]),
			value: users[1],
		},
		{
			label: t(userLabels[1]),
			value: users[0],
		},
		{
			label: t('second_leg_month'),
			value: formatDateMonthYear(order.secondLegMonth.startDate),
		},
		has1stLegPrice
			? {
					label: t('second_leg_price'),
					value: formatPriceWithoutCurrency(secondLegPrice) + ' ' + secondLegPriceSuffix,
					changed: changes.firstLegPrice || changes.price,
			  }
			: {
					label: t('second_leg_futures'),
					value: formatDateMonthYear(new Date(order.secondLegFuturesMonth).toISOString()),
			  },
		isTraded && {
			label: t('trade_id'),
			wide: true,
			value: <CopyTradeLink tradeId={secondLegTradeID} />,
		},
	].filter(Boolean);

	const isCounter = order._id?.startsWith('counter_orders/') || order.is_counter_preview;

	const createdDate = order.created_at
		? moment(order.created_at).format('D MMMM YYYY, h:mm A zz')
		: null;
	const updatedDate =
		!order.version || order.version === 1
			? null
			: moment(order.version_created_at).format('D MMMM YYYY, h:mm A zz');

	const quoteType = order.is_indicative ? QuoteType.Indicative : QuoteType.Firm;
	const orderTypeLabel =
		order.counterIndex > 0 || order.is_counter_preview
			? t('counter_type')
			: t('order_type_label');

	return (
		<>
			<SpreadLegRow legType={LegType.First} data={firstLegData} />
			<Divider />
			<SpreadLegRow legType={LegType.Second} data={secondLegData} />
			<Divider />
			<SectionContainer data-test="contract-and-pricing-section">
				<SectionGrid>
					{!isTraded && (
						<SectionGridContent className={clsx({ changed: changes.is_indicative })}>
							<SectionGridLabel>{orderTypeLabel}</SectionGridLabel>
							<SectionGridText data-test="pricing-section-order-type">
								{tQuoteValueType(t, quoteType)}
							</SectionGridText>
						</SectionGridContent>
					)}
					{!isTraded && (
						<SectionGridContent>
							<SectionGridLabel>{t('environment')}</SectionGridLabel>
							<SectionGridText data-test="contract-and-pricing-section-environment">
								{tEnvironment(t, order.environment)}
							</SectionGridText>
						</SectionGridContent>
					)}
					<SectionGridContent>
						<SectionGridLabel>{t('contract_terms')}</SectionGridLabel>
						<SectionGridText data-test="contract-and-pricing-section-contract">
							<Styled.ContractLink
								target="_blank"
								rel="noreferrer"
								href={order.terms.original_link}
							>
								{order.terms.name} <ExternalLinkIconSVG />
							</Styled.ContractLink>
						</SectionGridText>
					</SectionGridContent>
					{!isTraded && <CopyOrderNegotiationLink order={order} isCounter={isCounter} />}
					{createdDate && (
						<SectionGridContent>
							<SectionGridLabel>
								{isTraded ? t('trade_date') : t('created')}
							</SectionGridLabel>
							<CreatedAtText data-test="additional-information-section-created-at">
								{!!updatedDate || !isTraded ? (
									<OverflowText>{createdDate}</OverflowText>
								) : (
									createdDate
								)}
							</CreatedAtText>
						</SectionGridContent>
					)}
					{!!updatedDate && (
						<SectionGridContent>
							<SectionGridLabel>{t('edited')}</SectionGridLabel>
							<CreatedAtText data-test="additional-information-section-updated-at">
								<OverflowText>{updatedDate}</OverflowText>
							</CreatedAtText>
						</SectionGridContent>
					)}
				</SectionGrid>
			</SectionContainer>
		</>
	);
};
