/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

export const AvatarType = {
	Small: 'small',
	Medium: 'medium',
	Large: 'large',
};

const AvatarSizes = {
	small: {
		circle: '24',
		text: '10',
		offset: '0',
	},
	medium: {
		circle: '34',
		text: '14',
		offset: '1',
	},
	large: {
		circle: '42',
		text: '14',
		offset: '1',
	},
};

export const BorderSize = {
	large: '3px',
	medium: '2px',
	small: '1px',
};

export const DotType = {
	Online: 'online',
	Offline: 'offline',
	Active: 'active',
	ActiveOffline: 'activeOffline',
};

export const Avatar = ({
	size = AvatarType.Small,
	backgroundColor,
	dotBackgroundColor,
	initials,
	dotType,
	isSelected = false,
	withBorder = false,
	textColor,
	className,
	borderColor,
	borderSize,
	...params
}) => {
	return (
		<AvatarCircle
			className={clsx(className, size)}
			$size={size}
			$backgroundColor={backgroundColor}
			$selected={isSelected}
			$withBorder={withBorder}
			$textColor={textColor}
			$borderColor={borderColor}
			$borderSize={borderSize}
			{...params}
		>
			{initials?.toUpperCase()}
			{dotType && <StatusDot dotType={dotType} dotBackgroundColor={dotBackgroundColor} />}
		</AvatarCircle>
	);
};

export const StatusDot = ({ dotType, dotBackgroundColor = 'var(--neutral-1000)' }) => {
	switch (dotType) {
		case DotType.Online:
			return <OnlineDot $dotBackgroundColor={dotBackgroundColor} className="status-dot" />;

		case DotType.Offline:
			return <OfflineDot $dotBackgroundColor={dotBackgroundColor} className="status-dot" />;

		case DotType.ActiveOffline:
			return (
				<ActiveOfflineDot $dotBackgroundColor={dotBackgroundColor} className="status-dot" />
			);

		default:
			return (
				<ActiveConversationDot
					$dotBackgroundColor={dotBackgroundColor}
					className="status-dot"
				/>
			);
	}
};

const DotBase = styled.div`
	width: 7px;
	height: 7px;
	border-radius: 50%;
	border: 1px solid var(--neutral-1000);

	.medium > & {
		width: 8px;
		height: 8px;
		bottom: 2px;
		right: 2px;
		border-width: 2px;
	}

	.large > & {
		width: 10px;
		height: 10px;
		bottom: 2px;
		right: 2px;
		border-width: 2px;
	}
`;

const OnlineDot = styled(DotBase)`
	background-color: var(--green-600);
	border: 1px solid var(--green-600);
	${({ $dotBackgroundColor }) =>
		$dotBackgroundColor && `box-shadow: 0 0 0 1px ${$dotBackgroundColor};`};

	.medium > & {
		border-width: 2px;
		outline-width: 2px;
	}
	.large > & {
		border-width: 2px;
		outline-width: 2px;
	}
`;

const OfflineDot = styled(DotBase)`
	${({ $dotBackgroundColor }) => `background-color: ${$dotBackgroundColor};`}
	border: 1px solid var(--neutral-300);
	${({ $dotBackgroundColor }) =>
		$dotBackgroundColor && `box-shadow: 0 0 0 1px ${$dotBackgroundColor};`};

	.medium > & {
		border-width: 1px;
		outline-width: 2px;
	}
	.large > & {
		border-width: 1px;
		outline-width: 2px;
	}
`;

const ActiveOfflineDot = styled(OfflineDot)`
	border-color: #fff;

	.small > &,
	.medium > &,
	.large > & {
		border-width: 1px;
	}
`;

const ActiveConversationDot = styled(DotBase)`
	background-color: #fff;
	border-color: #fff;

	${({ $dotBackgroundColor }) =>
		$dotBackgroundColor && `box-shadow: 0 0 0 1px ${$dotBackgroundColor};`};

	.medium > & {
		border-width: 2px;
		outline-width: 2px;
	}
	.large > & {
		border-width: 2px;
		outline-width: 2px;
	}
`;

const AvatarCircle = styled.div`
	width: ${({ $size }) => AvatarSizes[$size].circle}px;
	height: ${({ $size }) => AvatarSizes[$size].circle}px;
	line-height: ${({ $size, $withBorder }) =>
		$withBorder ? AvatarSizes[$size].circle - 4 : AvatarSizes[$size].circle}px;
	text-align: center;
	color: ${({ $selected, $textColor }) =>
		$selected ? 'var(--neutral-900)' : $textColor ? $textColor : '#fff'};
	background-color: ${({ $backgroundColor, $selected }) =>
		$selected ? '#fff' : $backgroundColor || `var(--yellow-600)`};
	border-radius: 50%;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: ${({ $size }) => AvatarSizes[$size].text}px;
	letter-spacing: 0.2px;
	position: relative;
	flex-shrink: 0;
	user-select: none;

	.status-dot {
		position: absolute;
		bottom: ${({ $size }) => AvatarSizes[$size].offset}px;
		right: ${({ $size }) => AvatarSizes[$size].offset}px;
	}

	${({ $withBorder }) => $withBorder && `border: 2px solid var(--neutral-900);`}
	${({ $borderColor }) => $borderColor && `border-color: ${$borderColor};`}
	${({ $borderSize }) =>
		$borderSize &&
		`
		border-style: solid;
		border-width: ${$borderSize};
		align-items: center;
    display: flex;
    justify-content: center;	
	`}
`;
