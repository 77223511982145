/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useRef, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar, AvatarType } from 'src/shared/components/Avatar/Avatar';
import { UserProfileActions } from './UserProfileActions';
import { UserProfileInfo } from './UserProfileInfo';
import { UserStatistics } from './UserStatistics';
import { useUserPresenceContext } from 'src/websockets/UserPresenceProvider';
import { getUserCompanyId } from 'src/_store/selectors';
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip/Tooltip';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { UserApprovedBadge } from './UserApprovedBadge';
import * as Styled from './styled';

const CompanyNameCharactersLimit = {
	SingleLine: 40,
	DoubleLine: 110,
};

export const UserProfile = ({
	user = {},
	type,
	statistics = {},
	modal = false,
	onButtonClick,
	close,
}) => {
	const { t } = useTranslation();
	const [isCompanyNameOverflow, setIsCompanyNameOverflow] = useState(false);
	const { getUserVisibility } = useUserPresenceContext();
	const loggedUserCompanyId = useSelector(getUserCompanyId);

	const userVisibility = user.isMyContact ? getUserVisibility(user.id, user.visibility) : null;

	const content = user.showActivity ? (
		<Styled.Tab
			panes={[
				{
					menuItem: t('contact_details'),
					render: () => <UserProfileInfo user={user} type={type} />,
				},
				{
					menuItem: t('statistics_header'),
					render: () => <UserStatistics user={user} statistics={statistics} />,
				},
			]}
		/>
	) : (
		<>
			<Styled.DetailsHeader>{t('contact_details')}</Styled.DetailsHeader>
			<UserProfileInfo user={user} type={type} />
		</>
	);

	const isSameCompany = loggedUserCompanyId === user.companyId;
	const canShowOTC = !isSameCompany && user.showActivity && user.approved;

	return (
		<Styled.ProfileCard $color={user.avatarColor} className={clsx(modal && 'modal')}>
			<Styled.ProfileHeader>
				<Styled.AvatarWrapper data-test="user-avatar">
					<Avatar
						backgroundColor={user.avatarColor}
						size={AvatarType.Large}
						dotType={userVisibility}
						initials={user.initials}
					/>
				</Styled.AvatarWrapper>
				<Styled.UserName>
					<span>
						<OverflowText>{user.name}</OverflowText>
					</span>
					{user.approved && <UserApprovedBadge />}
				</Styled.UserName>
				<Styled.UserInfo
					data-test="user-info"
					isOverflowed={isCompanyNameOverflow}
					$hardBreak={
						user.companyName.length > CompanyNameCharactersLimit.SingleLine &&
						!user.companyName.includes(' ')
					}
				>
					<DoubleLineOverflowedCompanyName
						companyName={user.companyName}
						onOverflowChange={setIsCompanyNameOverflow}
						isCompanyNameOverflow={isCompanyNameOverflow}
						countryAbbreviation={user.countryAbbr}
					/>
					{isCompanyNameOverflow && (
						<span data-test="user-info-company-country">{user.countryAbbr}</span>
					)}
				</Styled.UserInfo>
			</Styled.ProfileHeader>
			<Styled.ProfileBody>{content}</Styled.ProfileBody>
			<UserProfileActions
				user={user}
				type={type}
				onButtonClick={onButtonClick}
				canShowOTC={canShowOTC}
				close={close}
			/>
		</Styled.ProfileCard>
	);
};

export const DoubleLineOverflowedCompanyName = ({
	companyName,
	onOverflowChange,
	isCompanyNameOverflow,
	countryAbbreviation,
}) => {
	const companyRef = useRef();
	const [companyNameHeights, setCompanyHeights] = useState({ scrollHeight: 0, clientHeight: 0 });

	useLayoutEffect(() => {
		setCompanyHeights({
			scrollHeight: companyRef?.current?.scrollHeight,
			clientHeight: companyRef?.current?.clientHeight,
		});

		onOverflowChange(companyNameHeights.scrollHeight > companyNameHeights.clientHeight);
	}, [companyNameHeights.clientHeight, companyNameHeights.scrollHeight, onOverflowChange]);

	const tooltipTrigger = (
		<Styled.UserCompany data-test="user-info-company-name" ref={companyRef}>
			{companyName.substring(0, CompanyNameCharactersLimit.DoubleLine)}
			{!isCompanyNameOverflow && (
				<Styled.CountryAbbreviation>{countryAbbreviation}</Styled.CountryAbbreviation>
			)}
		</Styled.UserCompany>
	);

	if (isCompanyNameOverflow) {
		return (
			<Tooltip>
				<TooltipTrigger>{tooltipTrigger}</TooltipTrigger>
				<TooltipContent>{companyName}</TooltipContent>
			</Tooltip>
		);
	} else {
		return tooltipTrigger;
	}
};
