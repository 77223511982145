/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useWatch } from 'react-hook-form';

export const useWatchPhysicalFields = () => {
	const typeValue = useWatch({ name: 'type' });
	const dateValue = useWatch({ name: 'date' });
	const incoValue = useWatch({ name: 'inco' });
	const volumeValue = useWatch({ name: 'volume' });
	const productAndTypeValue = useWatch({ name: 'productAndType' });
	const [productValue, productTypeValue] = productAndTypeValue
		? productAndTypeValue.split('|')
		: [null, null];
	const originValue = useWatch({ name: 'origin' });
	const gradeValue = useWatch({ name: 'grade' });
	const gradeObject = useWatch({ name: 'gradeObject' });
	const harvestValue = useWatch({ name: 'harvest' });
	const specialityValue = useWatch({ name: 'speciality' });
	const orderTypeValue = useWatch({ name: 'orderType' });
	const priceValue = useWatch({ name: 'price' });
	const priceTypeValue = useWatch({ name: 'priceType' });
	const futuresMonthValue = useWatch({ name: 'futuresMonth' });
	const priceIncrementTypeValue = useWatch({ name: 'priceIncrementType' });
	const paymentValue = useWatch({ name: 'payment' });
	const currencyUnitValue = useWatch({ name: 'currencyUnit' });
	const isCustomGrade = useWatch({ name: 'isCustomGrade' });
	const customSpecsValue = useWatch({ name: 'customSpecs' });
	const specsList = useWatch({ name: 'specs' }) || [];
	const cargoTypeValue = useWatch({ name: 'cargoType' });
	const portValue = useWatch({ name: 'port' });
	const portNames = useWatch({ name: 'portNames' });
	const portsArray = useWatch({ name: 'ports' }) || [];
	const inspectionValue = useWatch({ name: 'inspection' });
	const contractTermsValue = useWatch({ name: 'contractTerms' });
	const contractNumberValue = useWatch({ name: 'contractNumber' });
	const customContractFileValue = useWatch({ name: 'file_contract' });
	const performanceBondValue = useWatch({ name: 'performanceBond' });
	const environmentValue = useWatch({ name: 'environment' });
	const allDocumentsUploadedValue = useWatch({ name: 'allDocumentsUploaded' });
	const filesArrayValue = useWatch({ name: 'filesArray' }) || [];
	const counterpartiesValue = useWatch({ name: 'counterparties' }) || [];
	const isEditing = useWatch({ name: 'isEditing' }) || false;
	const isCopying = useWatch({ name: 'isCopying' }) || false;

	return {
		typeValue,
		dateValue,
		incoValue,
		volumeValue,
		productValue,
		productTypeValue,
		originValue,
		gradeValue,
		gradeObject,
		harvestValue,
		specialityValue,
		orderTypeValue,
		priceValue,
		priceTypeValue,
		futuresMonthValue,
		priceIncrementTypeValue,
		paymentValue,
		currencyUnitValue,
		isCustomGrade,
		customSpecsValue,
		specsList,
		cargoTypeValue,
		portValue,
		portNames,
		portsArray,
		inspectionValue,
		contractTermsValue,
		contractNumberValue,
		customContractFileValue,
		performanceBondValue,
		environmentValue,
		allDocumentsUploadedValue,
		filesArrayValue,
		counterpartiesValue,
		isEditing,
		isCopying,
	};
};
