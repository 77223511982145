/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useTogglable } from 'src/_helpers/useTogglable';
import { OrderType, PaperInstruments } from 'src/constants/contract';
import { SingleNegotiationRow } from './SingleNegotiationRow';
import { NoNegotiations } from './NoNegotiations';
import { useWebsocket } from 'src/websockets/useWebsocket';
import { PrivateWebsocketEventType, WebsocketChannelType } from 'src/constants/websockets';
import { useQueryClient } from 'react-query';
import * as Styled from './styled';

const TogglingAnimationDuration = 400;

export const NegotiationsSidebar = ({
	order,
	negotiations,
	activeNegotiationID,
	setActiveNegotiationID,
}) => {
	const { t } = useTranslation();
	const [isExpanded, { toggle: toggleSidebar }] = useTogglable(true);
	const [transitionCompleted, setTransitionCompleted] = useState(true);
	const queryClient = useQueryClient();

	const toggleNegotiationSidebar = useCallback(() => {
		setTransitionCompleted(false);
		toggleSidebar();
		setTimeout(() => {
			setTransitionCompleted(true);
		}, TogglingAnimationDuration);
	}, [toggleSidebar]);

	const headerLabel =
		order.instrument === PaperInstruments.Spread
			? t('your_spread')
			: order.order_type === OrderType.Buy
			? t('your_bid')
			: t('your_offer');

	const newCounterOrTradeCallback = useCallback(
		async message => {
			if (message.data.data.order_id === order._key) {
				await queryClient.invalidateQueries([
					'order',
					message.data.data.order_id,
					'negotiations',
				]);

				await queryClient.invalidateQueries(['order', order._key]);
			}
		},
		[order._key, queryClient]
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NewCounter,
		newCounterOrTradeCallback
	);

	useWebsocket(
		WebsocketChannelType.Private,
		PrivateWebsocketEventType.NewTrade,
		newCounterOrTradeCallback
	);

	return (
		<Styled.Sidebar className={clsx({ expanded: isExpanded })}>
			<Styled.SidebarHeading>
				<Styled.SidebarHeadingTitle className={clsx({ expanded: isExpanded })}>
					{headerLabel}
				</Styled.SidebarHeadingTitle>
				<Styled.ExpandCollapseButton
					onClick={toggleNegotiationSidebar}
					data-test="toggle-counters-sidebar-button"
					className={clsx({ expanded: isExpanded })}
				>
					<Styled.ExpandCollapseCountersIcon className={clsx({ expanded: isExpanded })} />
				</Styled.ExpandCollapseButton>
			</Styled.SidebarHeading>
			<Styled.OriginalOrder className={clsx({ expanded: isExpanded })}>
				<SingleNegotiationRow
					negotiation={order}
					onNegotiationSelect={setActiveNegotiationID}
					isActive={activeNegotiationID === order._key}
					originalOrder
					isExpanded={isExpanded}
					transitionCompleted={transitionCompleted}
				/>
			</Styled.OriginalOrder>
			<Styled.NegotiationsHeading
				data-before-content={t('negotiations')}
				data-after-content="•"
			/>
			<Styled.Negotiations>
				{negotiations.length === 0 ? (
					<NoNegotiations
						isExpanded={isExpanded}
						orderType={order.order_type}
						instrument={order.instrument}
					/>
				) : (
					negotiations.map((negotiation, index) => {
						return (
							<SingleNegotiationRow
								key={index}
								negotiation={negotiation}
								onNegotiationSelect={setActiveNegotiationID}
								isActive={activeNegotiationID === negotiation.first_counter_id}
								isExpanded={isExpanded}
								transitionCompleted={transitionCompleted}
							/>
						);
					})
				)}
			</Styled.Negotiations>
		</Styled.Sidebar>
	);
};
