/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useEffect } from 'react';
import moment from 'moment';
import { isNil } from 'src/_helpers';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';

const hasChanges = item => moment(item?.analytics?.created_at).isBefore(item?.updated_at);
const hasGlobalChanges = item => moment(item?.created_at).isBefore(item?.updated_at);

export const useMyRowsActivity = item => {
	const [isRowClicked, setRowClicked] = useState(false);
	const userId = useSelector(getUserId);
	const isOwnCounter = item?.counter_order_user_id === userId;
	const isOwnOrder = item?.order_user_id === userId;

	const shouldHighlight = isRowClicked
		? false
		: isNil(item?.analytics)
		? !isOwnCounter || !isOwnOrder || hasGlobalChanges(item)
		: hasChanges(item);

	const handleClick = mouseEvent => {
		if (mouseEvent.button === 2) {
			return;
		}

		setRowClicked(true);
	};

	useEffect(() => {
		if (hasChanges(item)) {
			setRowClicked(false);
		}
	}, [item, setRowClicked]);

	return { shouldHighlight, handleClick };
};
