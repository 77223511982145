/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Popup as SemanticUIPopup } from 'semantic-ui-react';
import styled from 'styled-components';
import { h300, h400, h500 } from 'src/components/Typography';
import { Button } from '../Buttons/Button';

const POPUP_WIDTH = 416;

export const Popup = styled(SemanticUIPopup)`
	border-radius: 5px;
	width: ${POPUP_WIDTH}px;
	padding-bottom: 15px;

	.ui.popup& {
		background: var(--date-picker-background) !important;
		box-shadow: 0 0 46px rgba(0, 0, 0, 0.4);
	}

	.ui.bottom.left&::before {
		background: var(--date-picker-background) !important;
	}

	.ui.top.left&::before {
		background: var(--date-picker-background) !important;
	}
`;

export const Column = styled.div`
	margin-top: 8px;
	overflow: auto;
	max-height: 320px;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: ${({ $isTimeAvailable }) => ($isTimeAvailable ? 'space-between' : 'flex-end')};
	padding: 0 24px;
`;

export const TimezoneColumn = styled(Column)`
	margin-left: 16px;
	width: 260px;
`;

export const MinuteColumn = styled(Column)`
	width: 80px;
`;

export const HourColumn = styled(Column)`
	width: 80px;
`;

export const Grid = styled.div`
	display: grid;
	grid-auto-flow: column;
	height: auto;
`;

export const Header = styled.header`
	${h500};
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--date-picker-background);
	border-top-left-radius: var(--big-border-radius);
	border-top-right-radius: var(--big-border-radius);
	width: ${POPUP_WIDTH}px;

	& > label {
		padding: 0 10px;
	}
`;

export const ChildrenWrapper = styled.div`
	position: relative;

	> .field {
		margin-top: 0 !important;
	}
`;

export const DateTimeLabel = styled.div`
	${h300};
	color: var(--neutral-300);
`;

export const DateTimeButton = styled(Button)`
	${h400};
	padding: 0;
	width: auto;
	height: auto;
	margin-top: 8px;

	&:disabled {
		background-color: transparent;
	}
`;
