/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import { OrderType } from 'src/constants/contract';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { getUserBrokerPrincipal } from '../../OrderCreatorShipmentSection/OrderCreatorBox/getUserBrokerPrincipal';

export const Labels = {
	Buyer: 'buyer',
	Seller: 'seller',
};

export const getLabels = firstLabel => {
	return firstLabel === Labels.Seller
		? [Labels.Seller, Labels.Buyer]
		: [Labels.Buyer, Labels.Seller];
};

export const useCalendarSpreadLegUsers = order => {
	const { t } = useTranslation();
	const currentUserId = useSelector(getUserId);

	const hiddenLabel = t('hidden');

	const { user, hiddenIdentity } = getUserBrokerPrincipal({
		currentUserId,
		order,
		t,
	});

	if (order.is_order_preview) {
		const myCompany = order.hidden ? t('hidden') : user.company.name;

		return {
			userLabels: getLabels(
				order.order_type === OrderType.Sell ? Labels.Seller : Labels.Buyer
			),
			users: [myCompany, '---'],
		};
	}

	if (order.is_order) {
		const orderCreatorCompany = hiddenIdentity
			? hiddenLabel
			: order.order_owner_principal_user?.company_name ||
			  user?.company?.name ||
			  order.user?.company?.name;

		return {
			userLabels: getLabels(
				order.order_type === OrderType.Sell ? Labels.Seller : Labels.Buyer
			),
			users: [orderCreatorCompany, '---'],
		};
	}

	if (order.is_counter_preview) {
		return {
			userLabels: getLabels(
				order.spreadMeta.orderCounterType === OrderType.Sell ? Labels.Buyer : Labels.Seller
			),
			users: [
				order.order_owner_principal_user?.company_name || user?.company?.name,
				order.spreadMeta.user?.company?.name || hiddenLabel,
			],
		};
	}

	const counterCreatorCompany =
		order.order_owner_principal_company?.name || user?.company?.name || '---';
	const otherUserCompany =
		counterCreatorCompany === order.counter_order_user_company.name
			? order.order_user_company?.name || hiddenLabel
			: order.counter_order_user_company.name;

	if (!!order.trade_id) {
		const isMyOrder = order.order_user_id === currentUserId;

		return {
			userLabels: getLabels(
				order.order_type === OrderType.Sell
					? isMyOrder
						? Labels.Buyer
						: Labels.Seller
					: isMyOrder
					? Labels.Seller
					: Labels.Buyer
			),
			users: [counterCreatorCompany, otherUserCompany],
		};
	}

	return {
		userLabels: getLabels(order.counterType === OrderType.Sell ? Labels.Seller : Labels.Buyer),
		users: [counterCreatorCompany, otherUserCompany],
	};
};
