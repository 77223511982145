/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { tSpecDirection } from 'src/constants/product';
import { PricePill } from './PricePrill/PricePill';
import { VolumeDisplay } from 'src/components/Volume';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { Analytics } from './OrderViews/Analytics';
import * as Styled from './styled';

export const KeyDetailsSection = ({ order, changes, isLastCounter = false }) => {
	const { t } = useTranslation();
	const { product, volume, grade } = order;
	const [isSpecsOpen, setIsSpecsOpen] = useState(false);

	const onSpecsClick = () => {
		setIsSpecsOpen(val => !val);
	};

	const isCounter = !!changes;
	const isPricePillChanged =
		changes?.price ||
		changes?.currency ||
		changes?.price_unit ||
		changes?.futures_contract_date;
	const isTraded = !!order.trade_id;

	return (
		<>
			<Styled.Container data-test="key-details-section">
				<div>
					<Analytics
						order={order}
						isCounter={isCounter}
						isLastCounter={isLastCounter}
						isTraded={isTraded}
					/>
					<Styled.DetailsTitle
						data-test="details-title"
						className={clsx({ changed: changes?.volume })}
					>
						{`${product.name} `}
						<VolumeDisplay value={volume} />
					</Styled.DetailsTitle>
					<Styled.GradeSpecsContainer data-test="grade-specs">
						<Styled.DetailsTitle>{grade.name}</Styled.DetailsTitle>
						<Styled.SpecsDropdown role="button" onClick={onSpecsClick}>
							{t('specs')}
							<Styled.SpecsChevron $isOpen={isSpecsOpen} />
						</Styled.SpecsDropdown>
					</Styled.GradeSpecsContainer>
				</div>
				<PricePill order={order} changed={isPricePillChanged} />
			</Styled.Container>
			<Styled.SpecsContainer $isOpen={isSpecsOpen} data-test="specs-section">
				<Styled.SpecsListContainer>
					{grade.gradespecs.map(({ _key, spec, value }) => {
						const testSpecName = spec.name.toLowerCase().replace(/ /g, '-');

						return (
							<Styled.SpecContentCell key={_key}>
								<Styled.SpecName>{spec.name}</Styled.SpecName>
								<Styled.SpecValueContainer
									data-test={`spec-section-${testSpecName}`}
								>
									<Styled.SpecLabel>{tSpecDirection(t, spec)}</Styled.SpecLabel>
									&nbsp;
									<Styled.SpecValue>
										<OverflowText>
											{value}
											{spec.unit}
										</OverflowText>
									</Styled.SpecValue>
								</Styled.SpecValueContainer>
							</Styled.SpecContentCell>
						);
					})}
				</Styled.SpecsListContainer>
				<Styled.SpecAdditionalInfoText>
					{t('specs_additional_info')}
				</Styled.SpecAdditionalInfoText>
			</Styled.SpecsContainer>
		</>
	);
};
