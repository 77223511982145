/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
// import * as Sentry from '@sentry/react';
import { Navigate, Route, Routes, useLocation, useMatch } from 'react-router-dom';
import { ResetPage } from 'src/containers/ResetPage/ResetPage';
import { ErrorPage } from 'src/containers/ErrorPage/ErrorPage';
import { MobileChart } from 'src/containers/MobileChart/MobileChart';
import { ROUTES } from 'src/constants/routes';
import { RegistrationCompleted } from 'src/containers/RegistrationCompleted/RegistrationCompleted';
import { loadableRetry } from './loadableRetry';
import { ErrorBoundary } from './ErrorBoundary';
import { SuspenseFallback } from './SuspenseFallback';
import { useSelector } from 'react-redux';
import { getUserHomepage } from 'src/_store/selectors';
import { userNetworkRoutes } from './routerModules/userNetworkRouter';
import { physicalExecutionRoutes } from './routerModules/physicalExecutionRouter';
import { PermissionBoundary } from 'src/containers/Permissions/PermissionBoundary';
import { Permissions } from 'src/constants/permissions';
import { SendPasswordResetEmail } from 'src/containers/SendPasswordResetEmail/SendPasswordResetEmail';
import { MobileGuide } from 'src/containers/MobileGuide/MobileGuide';
import { VideoGuide } from 'src/containers/VideoGuide/VideoGuide';
import { Flags } from 'src/featureFlags/Flags';
import { FlagNames } from 'src/constants/flags';
import { DeleteAccount } from 'src/containers/DeleteAccount/DeleteAccount';
import { DeleteAccountMobile } from 'src/containers/DeleteAccount/DeleteAccountMobile';

const DashboardGrid = loadableRetry(() => import('src/containers/Dashboard/DashboardGrid'), {
	resolveComponent: components => components.DashboardGrid,
});

const Dashboard = loadableRetry(() => import('src/containers/Dashboard/Dashboard'), {
	resolveComponent: components => components.Dashboard,
});

const ResendConfirmationEmail = loadableRetry(
	() => import('src/containers/ResendConfirmationEmail/ResendConfirmationEmail'),
	{
		resolveComponent: components => components.ResendConfirmationEmail,
	}
);

const ConfirmEmail = loadableRetry(() => import('src/containers/ConfirmEmail/ConfirmEmail'), {
	resolveComponent: components => components.ConfirmEmail,
});

const AwaitAccountApproval = loadableRetry(
	() => import('src/containers/AwaitAccountApproval/AwaitAccountApproval'),
	{
		resolveComponent: components => components.AwaitAccountApproval,
	}
);

const DeleteAccountConfirmation = loadableRetry(
	() => import('src/containers/DeleteAccount/DeleteAccountConfirmation'),
	{
		resolveComponent: components => components.DeleteAccountConfirmation,
	}
);

const RegisterPage = loadableRetry(() => import('src/containers/RegisterPage/RegisterPage'), {
	resolveComponent: components => components.RegisterPage,
});

const LoginPage = loadableRetry(() => import('src/containers/Login/LoginPage'), {
	resolveComponent: components => components.LoginPage,
});

const NewsPage = loadableRetry(() => import('src/containers/News/containers/NewsPage'), {
	resolveComponent: components => components.NewsPage,
});

const News = loadableRetry(() => import('src/containers/News/News'), {
	resolveComponent: components => components.News,
	fallback: <SuspenseFallback />,
});

const Guidebook = loadableRetry(() => import('src/containers/Guidebook/Guidebook'), {
	resolveComponent: components => components.Guidebook,
	fallback: <SuspenseFallback />,
});

const ArticleDetails = loadableRetry(
	() => import('src/containers/News/containers/ArticleDetails'),
	{
		resolveComponent: components => components.ArticleDetails,
	}
);

const CreateArticle = loadableRetry(() => import('src/containers/News/containers/CreateArticle'), {
	resolveComponent: components => components.CreateArticle,
});

const EditArticle = loadableRetry(() => import('src/containers/News/containers/EditArticle'), {
	resolveComponent: components => components.EditArticle,
});

const OldContacts = loadableRetry(
	() => import('src/containers/MyAccount/containers/Contacts/Contacts'),
	{
		resolveComponent: components => components.Contacts,
	}
);

const TrackRecord = loadableRetry(
	() => import('src/containers/KYC/containers/TrackRecord/TrackRecord'),
	{
		resolveComponent: components => components.TrackRecord,
	}
);

const ActiveConversations = loadableRetry(
	() => import('src/containers/KYC/containers/ActiveConversations'),
	{
		resolveComponent: components => components.ActiveConversations,
	}
);

const MyDocuments = loadableRetry(() => import('src/containers/KYC/containers/MyDocuments'), {
	resolveComponent: components => components.MyDocuments,
});

const Vault = loadableRetry(() => import('src/containers/KYC/containers/Vault'), {
	resolveComponent: components => components.Vault,
});

const OpenTradebook = loadableRetry(() => import('src/containers/Execution/OpenTradebookTable'), {
	resolveComponent: components => components.OpenTradebookTable,
});

const ClosedTradebook = loadableRetry(
	() => import('src/containers/Execution/ClosedTradebookTable'),
	{
		resolveComponent: components => components.ClosedTradebookTable,
	}
);

const Conversation = loadableRetry(() => import('src/containers/KYC/containers/Conversation'), {
	resolveComponent: components => components.Conversation,
});

const PendingRequests = loadableRetry(
	() => import('src/containers/KYC/containers/PendingRequests'),
	{
		resolveComponent: components => components.PendingRequests,
	}
);

const KYC = loadableRetry(() => import('src/containers/KYC/KYC'), {
	resolveComponent: components => components.KYC,
	fallback: <SuspenseFallback />,
});

const MyAccount = loadableRetry(() => import('src/containers/MyAccount/MyAccount'), {
	resolveComponent: components => components.MyAccount,
	fallback: <SuspenseFallback />,
});

const TradeDetails = loadableRetry(
	() => import('src/containers/ExecutionDetails/ExecutionDetails'),
	{
		resolveComponent: components => components.TradeDetails,
	}
);

const Execution = loadableRetry(() => import('src/containers/Execution/Execution'), {
	resolveComponent: components => components.Execution,
	fallback: <SuspenseFallback />,
});

const Overview = loadableRetry(() => import('src/containers/Overview/Overview'), {
	resolveComponent: components => components.Overview,
});

const TeamsDashboard = loadableRetry(() => import('src/containers/TeamsDashboard/TeamsDashboard'), {
	resolveComponent: components => components.TeamsDashboard,
});

const TeamDashboardEditTeam = loadableRetry(
	() => import('src/containers/TeamsDashboard/TeamDashboardEditTeam'),
	{
		resolveComponent: components => components.TeamDashboardEditTeam,
	}
);

const TeamDashboardRemoveTeam = loadableRetry(
	() => import('src/containers/TeamsDashboard/TeamDashboardRemoveTeam'),
	{
		resolveComponent: components => components.TeamDashboardRemoveTeam,
	}
);

const SignOut = loadableRetry(() => import('src/containers/SignOut/SignOut'), {
	resolveComponent: components => components.SignOut,
});

const getRoutes = ({ isLoggedIn }) => {
	const sharedRoutes = [
		{ path: ROUTES.error, element: <ErrorPage /> },
		{ path: ROUTES.reset, element: <ResetPage /> },
		{ path: ROUTES.sendPasswordResetEmail, element: <SendPasswordResetEmail /> },
		{ path: ROUTES.deleteAccountRequestSent, element: <DeleteAccountConfirmation /> },
	];

	const privateRoutes = [
		{
			path: ROUTES.root,
			element: (
				<ErrorBoundary>
					<DashboardGrid />
				</ErrorBoundary>
			),
			children: <Route index element={<Dashboard />} />,
		},
		{
			path: ROUTES.overview,
			element: (
				<ErrorBoundary>
					<Overview />
				</ErrorBoundary>
			),
		},
		{
			path: ROUTES.teamsDashboard,
			element: (
				<ErrorBoundary>
					<TeamsDashboard />
				</ErrorBoundary>
			),
			children: (
				<>
					<Route
						exact
						path={ROUTES.userNetworkTeamEdit}
						element={<TeamDashboardEditTeam />}
					/>
					<Route
						exact
						path={ROUTES.userNetworkTeamRemove}
						element={<TeamDashboardRemoveTeam />}
					/>
				</>
			),
		},
		{
			path: ROUTES.tradeDetails,
			element: (
				<ErrorBoundary>
					<TradeDetails />
				</ErrorBoundary>
			),
		},
		{
			path: ROUTES.execution,
			element: (
				<ErrorBoundary>
					<Execution />
				</ErrorBoundary>
			),
			children: (
				<>
					<Route path={ROUTES.myTradebook} element={<OpenTradebook />} />
					<Route path={ROUTES.closedTrades} element={<ClosedTradebook />} />
				</>
			),
		},
		{
			path: ROUTES.myAccount,
			element: (
				<ErrorBoundary>
					<MyAccount />
				</ErrorBoundary>
			),
			children: <Route path={ROUTES.myAccountContacts} element={<OldContacts />} />,
		},
		userNetworkRoutes,
		physicalExecutionRoutes,
		{
			path: ROUTES.kyc,
			element: (
				<ErrorBoundary>
					<PermissionBoundary permission={Permissions.KYC_VIEW}>
						<KYC />
					</PermissionBoundary>
				</ErrorBoundary>
			),
			children: (
				<>
					<Route path={ROUTES.kycPendingRequest} element={<PendingRequests />} />
					<Route
						path={ROUTES.kycActiveConversationsList}
						element={<ActiveConversations />}
					/>
					<Route path={ROUTES.kycActiveConversation} element={<Conversation />} />
					<Route path={ROUTES.kycMyDocuments} element={<MyDocuments />} />
					<Route path={ROUTES.kycMyDocumentsVault} element={<Vault />} />
					<Route
						path={ROUTES.kycTrackRecord}
						element={
							<Flags
								authorizedFlags={[FlagNames.TrackRecord]}
								renderOn={() => <TrackRecord />}
								renderOff={() => {
									const userHomepage = useSelector(getUserHomepage);
									return <Navigate to={userHomepage} />;
								}}
							/>
						}
					/>
				</>
			),
		},

		{ path: ROUTES.chart, element: <MobileChart /> },
		{ path: ROUTES.mobileGuide, element: <MobileGuide /> },
		{ path: ROUTES.videoGuide, element: <VideoGuide /> },
		{
			path: ROUTES.guidebook,
			element: (
				<ErrorBoundary>
					<Guidebook />
				</ErrorBoundary>
			),
		},
		{
			path: ROUTES.news,
			element: (
				<ErrorBoundary>
					<News />
				</ErrorBoundary>
			),
			children: (
				<>
					<Route index element={<NewsPage />} />
					<Route path={ROUTES.articleDetails} element={<ArticleDetails />} />
					<Route path={ROUTES.newArticle} element={<CreateArticle />} />
					<Route path={ROUTES.editArticle} element={<EditArticle />} />
				</>
			),
		},
		{
			path: ROUTES.deleteAccount,
			element: <DeleteAccount />,
		},
		{ path: ROUTES.signOut, element: <SignOut /> },
		...sharedRoutes,
	];

	const publicRoutes = [
		{ path: ROUTES.root, element: <LoginPage /> },
		{ path: ROUTES.login, element: <Navigate to={ROUTES.root} replace /> },
		{ path: ROUTES.register, element: <RegisterPage /> },
		{ path: ROUTES.registrationCompleted, element: <RegistrationCompleted /> },
		{
			path: ROUTES.resendConfirmationEmail,
			element: <ResendConfirmationEmail />,
		},
		{ path: ROUTES.confirmEmail, element: <ConfirmEmail /> },
		{ path: ROUTES.awaitAccountApproval, element: <AwaitAccountApproval /> },
		{
			path: ROUTES.deleteAccount,
			element: <DeleteAccountMobile />,
		},
		...sharedRoutes,
	];

	if (isLoggedIn) {
		return privateRoutes;
	}

	return publicRoutes;
};

const NavigateToLocation = () => {
	const { pathname } = useLocation();

	return <Navigate to={pathname.replace(/\/+$/, '')} />;
};

const NavigateToMatch = () => {
	const match = useMatch();

	return <Navigate to={`/${match.params.url.replace(/\/\/+/, '/')}`} />;
};

const NavigateToHomepage = ({ isLoggedIn, userHomepage }) => {
	return <Navigate from="*" to={isLoggedIn ? userHomepage : '/'} />;
};

export const Router = ({ isLoggedIn }) => {
	const userHomepage = useSelector(getUserHomepage);

	// TODO: WE NEED TO UNDERSTAND WHY THIS IS BREAKING TABS IN PAPER EXECUTION
	// const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

	return (
		<Routes>
			<Route path="/:url*(/+)" strict element={<NavigateToLocation />} />
			<Route path="/:url(.*//+.*)" strict element={<NavigateToMatch />} />

			{getRoutes({ isLoggedIn }).map(route => (
				<Route key={route.path} {...route} />
			))}

			<Route
				path="*"
				element={<NavigateToHomepage isLoggedIn={isLoggedIn} userHomepage={userHomepage} />}
			/>
		</Routes>
	);
};
