/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { sortOrder } from 'src/_helpers/sort';

export const pageSize = 20;

export const groupLimit = 12;
export const teamLimit = 12;

export const contactsDefaultSort = {
	_sort: 'name',
	_order: sortOrder.asc,
};

export const commonSortOptions = [
	{
		text: 'sort_by_name_az',
		value: 'name.ASC',
	},
	{
		text: 'sort_by_name_za',
		value: 'name.DESC',
	},
];

export const contactSortOptions = [
	...commonSortOptions,
	{
		text: 'sort_by_newest',
		value: 'contact_created_at.DESC',
	},
	{
		text: 'sort_by_oldest',
		value: 'contact_created_at.ASC',
	},
];

export const allUsersSortOptions = [
	...commonSortOptions,
	{
		text: 'sort_by_newest',
		value: 'user_created_at.DESC',
	},
	{
		text: 'sort_by_oldest',
		value: 'user_created_at.ASC',
	},
];

export const UserType = {
	Contacts: 'my_contacts',
	AllUsers: 'all',
};

export const fakeContactDetails = [
	{
		label: 'phone_number',
		value: '+XX XXX XXX XXX',
		hidden: true,
	},
	{
		label: 'email',
		value: 'xxxxx@xxxxxx.xxx',
		hidden: true,
	},
];
