/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Currency,
	DrawerPriceType,
	Market,
	Payment,
	PriceIncrementType,
	PriceType,
	PriceUnit,
	QuoteType,
	defaultCurrencyUnit,
	tDrawerPriceType,
	tPriceIncrement,
	tQuoteValueType,
	Environment,
} from 'src/constants/contract';
import { useWatchPhysicalFields } from '../../useWatchPhysicalFields';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getProducts } from 'src/_api';
import moment, { duration } from 'moment-timezone';
import { genFutureContractMonths } from 'src/containers/Order/PhysicalOrder/Groups/Pricing/FuturesContractDate';
import { toUTCUnix } from 'src/_helpers/date';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { FlagNames } from 'src/constants/flags';

export const usePricingSectionOptions = () => {
	const { t } = useTranslation();
	const {
		priceTypeValue,
		futuresMonthValue,
		priceValue,
		productValue,
		currencyUnitValue,
		orderTypeValue,
		priceIncrementTypeValue,
		paymentValue,
		environmentValue,
		isCopying,
		isEditing,
	} = useWatchPhysicalFields();

	const { isFlagEnabled } = useFeatureFlags();

	const { setValue } = useFormContext();
	const isNoPrice = priceTypeValue === DrawerPriceType.NoPrice;
	const isFlatOrNoPrice = [DrawerPriceType.NoPrice, DrawerPriceType.Flat].includes(
		priceTypeValue
	);

	const { data: products = [] } = useQuery(['products', Market.Physical], getProducts, {
		staleTime: duration.hour,
	});

	/** Order type */
	const orderTypeOptions = useMemo(
		() =>
			Object.values(QuoteType).map(value => ({
				text: tQuoteValueType(t, value),
				key: value,
				value,
			})),
		[t]
	);

	const areExchangeFirmOrdersEnabled = isFlagEnabled(FlagNames.ExchangeFirmOrders);
	const isOTC = environmentValue === Environment.OTC;

	useEffect(() => {
		if (!areExchangeFirmOrdersEnabled && !isOTC && orderTypeValue === QuoteType.Firm) {
			setValue('orderType', QuoteType.Indicative);
		}
	}, [setValue, orderTypeValue, areExchangeFirmOrdersEnabled, isOTC]);

	/* Price type */
	const selectedProductFutureContracts = useMemo(() => {
		return products.find(product => product._key === productValue)?.futures_contracts || [];
	}, [products, productValue]);

	const [flat, noPrice] = Object.values(DrawerPriceType);

	const priceTypeOptions = useMemo(() => {
		const options = [flat, ...selectedProductFutureContracts.map(fc => fc.futures_contract_id)];

		if (orderTypeValue === QuoteType.Indicative) {
			options.push(noPrice);
		}

		return options.map(value => ({
			text: tDrawerPriceType(t, value),
			key: value,
			value,
		}));
	}, [flat, selectedProductFutureContracts, orderTypeValue, noPrice, t]);

	useEffect(() => {
		if (priceTypeValue && products.length) {
			const currentValueIsValid = priceTypeOptions.find(
				({ value }) => value === priceTypeValue
			);
			if (!currentValueIsValid) {
				setValue('priceType', PriceType.Flat);
			}
		}
	}, [priceTypeOptions, priceTypeValue, products.length, setValue]);

	useEffect(() => {
		if (priceTypeValue === DrawerPriceType.NoPrice && orderTypeValue === QuoteType.Firm) {
			setValue('priceType', null);
		}
	}, [orderTypeValue, priceTypeValue, setValue]);

	/* Currency & Unit */
	const currencyUnitOptions = useMemo(() => {
		if (isFlatOrNoPrice) {
			return [
				{
					key: defaultCurrencyUnit,
					text: `${Currency.USD}/${t(`price_unit_short_${PriceUnit.MetricTon}`, {
						lng: 'en',
					})}`,
					value: defaultCurrencyUnit,
				},
				{
					key: `${Currency.EUR}/${PriceUnit.MetricTon}`,
					text: `${Currency.EUR}/${t(`price_unit_short_${PriceUnit.MetricTon}`, {
						lng: 'en',
					})}`,
					value: `${Currency.EUR}/${PriceUnit.MetricTon}`,
				},
			];
		}

		const selectedFuturesContract = selectedProductFutureContracts.find(
			fc => fc.futures_contract_id === priceTypeValue
		);

		if (selectedFuturesContract) {
			const { currency, unit } = selectedFuturesContract;

			return [
				{
					key: `${currency}/${unit}`,
					text: `${currency}/${t(`price_unit_short_${unit}`, { lng: 'en' })}`,
					value: `${currency}/${unit}`,
				},
			];
		}

		return [];
	}, [isFlatOrNoPrice, priceTypeValue, selectedProductFutureContracts, t]);

	const currencyUnitInitialValue = useMemo(() => {
		if (!isFlatOrNoPrice) {
			const selectedFuturesContract = selectedProductFutureContracts.find(
				fc => fc.futures_contract_id === priceTypeValue
			);

			if (selectedFuturesContract) {
				const { currency, unit } = selectedFuturesContract;
				return `${currency}/${unit}`;
			}
		}

		return defaultCurrencyUnit;
	}, [isFlatOrNoPrice, priceTypeValue, selectedProductFutureContracts]);

	const currencyUnitChangeHandler = value => {
		setValue('currencyUnit', value);
	};

	/* Payment */
	const paymentOptions = useMemo(
		() =>
			Object.values(Payment).map(value => ({
				text: value,
				key: value,
				value,
			})),
		[]
	);

	/* Futures Month */
	const selectedFuturesContractMonths = useMemo(() => {
		return (
			selectedProductFutureContracts.find(fc => fc.futures_contract_id === priceTypeValue)
				?.future_months || []
		);
	}, [priceTypeValue, selectedProductFutureContracts]);

	const futuresMonthOptions = useMemo(() => {
		return genFutureContractMonths(selectedFuturesContractMonths).map(value => ({
			key: value.valueOf(),
			text: moment(value).format('MMM YYYY'),
			value: toUTCUnix(value),
		}));
	}, [selectedFuturesContractMonths]);

	useEffect(() => {
		if (
			futuresMonthValue &&
			!futuresMonthOptions.some(({ value }) => value === futuresMonthValue)
		) {
			setValue('futuresMonth', null);
		}
	}, [futuresMonthOptions, futuresMonthValue, setValue]);

	/* Price increment */
	const priceIncrementOptions = useMemo(
		() =>
			Object.values(PriceIncrementType).map(value => ({
				text: tPriceIncrement(t, value),
				key: value,
				value,
			})),
		[t]
	);

	useEffect(() => {
		if (priceIncrementTypeValue === PriceIncrementType.None) {
			setValue('price_increment', undefined);
			setValue('priceIncrementBasisShipment', undefined);
		}
	}, [priceIncrementTypeValue, setValue]);

	/* Meta */
	const [currency, unit] = useMemo(() => {
		return currencyUnitValue
			? currencyUnitValue.split('/')
			: [Currency.USD, PriceUnit.MetricTon];
	}, [currencyUnitValue]);

	/* Side effects */
	useEffect(() => {
		if (isFlatOrNoPrice) {
			setValue('futuresMonth', null);
		}
	}, [isFlatOrNoPrice, setValue]);

	useEffect(() => {
		if (isFlatOrNoPrice && priceValue < 0) {
			setValue('price', null);
		}
	}, [isFlatOrNoPrice, priceValue, setValue]);

	useEffect(() => {
		if (!isCopying && !isEditing) {
			setValue('currencyUnit', currencyUnitInitialValue);
		}
	}, [currencyUnitInitialValue, setValue, isCopying, isEditing]);

	useEffect(() => {
		if (isNoPrice || priceValue === '') {
			setValue('price', null);
		}
	}, [isNoPrice, priceValue, setValue]);

	useEffect(() => {
		if (!currencyUnitOptions.map(cu => cu.value).includes(currencyUnitValue)) {
			setValue('currencyUnit', currencyUnitInitialValue);
		}
	}, [currencyUnitInitialValue, currencyUnitOptions, currencyUnitValue, setValue]);

	return {
		orderType: { options: orderTypeOptions, ready: isOTC || areExchangeFirmOrdersEnabled },
		priceType: { options: priceTypeOptions, ready: !!productValue },
		futuresMonth: { options: futuresMonthOptions, ready: true },
		payment: { options: paymentOptions, ready: true, hasValue: paymentValue },
		priceIncrementType: {
			options: priceIncrementOptions,
			ready: true,
			hasValue: priceIncrementTypeValue,
		},
		priceCurrencyUnit: {
			options: currencyUnitOptions,
			ready: isFlatOrNoPrice,
			onOptionChange: currencyUnitChangeHandler,
		},
		priceIncrementBasisShipment: { initialValue: new Date() },
		currency,
		unit,
		canHideOptional: !priceIncrementTypeValue || !paymentValue,
	};
};
