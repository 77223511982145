/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as RightArrowIcon } from 'src/chat/assets/chevron_no_margin.svg';
import { h600, h200, h400, h500 } from 'src/components/Typography';
import { Avatar } from 'src/chat/components/GroupsList/styled';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';

const animationDuration = `0.4s`;

export const Sidebar = styled.div`
	background: var(--neutral-950);
	grid-row: 1 / span 3;
	width: 88px;
	transition: width ${animationDuration} ease-in-out;

	&.expanded {
		width: 332px;
	}
`;

export const SidebarHeading = styled.div`
	color: var(--white);
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	${h600}
	font-weight: 500;
	white-space: nowrap;
	margin: 16px;
	margin-bottom: 0;
`;

export const SidebarHeadingTitle = styled.div`
	opacity: 0;
	transition: opacity ${animationDuration} ease-in-out;
	width: 0;

	&.expanded {
		opacity: 1;
	}
`;

export const ExpandCollapseButton = styled.button`
	border: 0;
	cursor: pointer;
	width: 56px;
	height: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	transition: width ${animationDuration} ease-in-out;

	&.expanded {
		width: 40px;
	}
`;

export const ExpandCollapseCountersIcon = styled(RightArrowIcon)`
	color: var(--purple-200);
	transition: transform ${animationDuration} ease-in-out;

	&.expanded {
		transform: rotate(-180deg);
	}
`;

export const OriginalOrder = styled.div`
	transition: padding ${animationDuration} ease-in-out;
	margin-top: 8px;
	padding: 0 12px 0 12px;

	&.expanded {
		padding: 0 16px 0 16px;
	}
`;

export const Negotiations = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: calc(100% - 172px);
	margin-top: 8px;
	transition: padding ${animationDuration} ease-in-out;
	padding: 0 12px 24px;

	.expanded & {
		padding: 0 16px 24px;
	}
`;

export const SingleCounterRow = styled.div`
	width: 100%;
	height: 48px;
	border-radius: 8px;
	display: grid;
	grid-template-columns: 24px minmax(0, auto) minmax(24px, auto) minmax(44px, max-content);
	align-items: center;
	max-width: 300px;
	padding-left: 8px;
	padding-right: 12px;
	grid-column-gap: 8px;
	flex-shrink: 0;
	transition: background 0.2s ease-in-out;

	&.collapsed {
		min-width: 64px;
		grid-template-columns: 1fr;
		padding-left: 0;
		padding-right: 0;
		background: var(--neutral-800);

		&:hover {
			background: var(--neutral-700);
		}

		& span {
			text-align: center;
		}
	}

	&.active {
		background: var(--primary-400);

		&:hover {
			background: var(--primary-200);
		}
	}

	&:hover {
		cursor: pointer;
		background: var(--neutral-800);
	}

	&.new-activities {
		background: linear-gradient(270deg, transparent 0%, #24288b 100%);

		&.collapsed {
			background: linear-gradient(270deg, var(--neutral-800) 0%, #24288b 100%);
		}

		> div:first-of-type {
			border-color: var(--white);
		}

		div,
		span {
			color: var(--white);
		}

		&.active {
			background: var(--primary-400);
		}

		&:hover {
			background: linear-gradient(90deg, #24288b 10%, transparent 100%);
			color: var(--white);
		}
	}
`;

export const NegotiationsAvatar = styled(Avatar)`
	max-height: 48px;
`;

export const NegotiationsHeading = styled.div`
	${h600}
	line-height: 48px;
	margin: 8px 16px 0 16px;
	position: relative;
	padding-top: 48px;

	&:before,
	&:after {
		transition: opacity ${animationDuration} ease-in-out;
		width: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&:before {
		content: attr(data-before-content);
		opacity: 0;
	}

	&:after {
		content: attr(data-after-content);
		opacity: 1;
		text-align: center;
	}

	.expanded & {
		&:before {
			opacity: 1;
		}

		&:after {
			opacity: 0;
		}
	}
`;

export const NoNegotiations = styled.div`
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	${h200}
	color: var(--neutral-400);
	gap: 10px;
`;

export const Price = styled.div`
	height: 24px;
	line-height: 24px;
	padding: 0 8px;
	border-radius: 8px;
	background: var(--neutral-800);
	color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	${h400}
	font-size:14px;

	.new-activities & {
		background: var(--neutral-700);
	}

	.active & {
		background: var(--primary-100);
	}
`;

export const PriceCollapsed = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 64px;
	padding: 0 4px;
	justify-self: end;
	${h400}
	font-size: 14px;
`;

export const Type = styled.span`
	${h200}
	color: var(--neutral-400);
	justify-self: end;

	.active & {
		color: var(--white);
	}
`;

export const NameValidity = styled.div`
	display: flex;
	gap: 2px;
	flex-direction: column;
	padding-left: 4px;
	max-height: 48px;
`;

export const Name = styled.span`
	${h500}
	color: var(--neutral-200);

	.active & {
		color: var(--white);
	}
`;

export const Validity = styled.span`
	${h200}
	color: var(--neutral-400);

	.active & {
		color: var(--white);
	}

	.new-activities & {
		color: var(--neutral-100) !important;
	}
`;

export const InfoIcon = styled(Info)`
	width: 14px;
	height: 14px;
	margin-top: 1px;
	min-width: 14px;
	min-height: 14px;
`;

export const NoNegotiationsInfo = styled.span`
	overflow: hidden;
	text-overflow: clip;
	white-space: nowrap;
	opacity: 0;
	transition: opacity ${animationDuration} ease-in-out;

	.expanded & {
		opacity: 1;
	}
`;
