/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { toggle, toggleByKey } from 'src/_helpers/utils';
import { loadState, storageKey } from 'src/_helpers/searchState';
import { Market } from 'src/constants/contract';
import {
	SEARCH_SELECT_PRODUCT,
	SEARCH_SELECT_ORDER_TYPE,
	SEARCH_SELECT_INSTRUMENT,
	SEARCH_SELECT_ORIGIN,
	SEARCH_SET_PRODUCT_VISIBLE,
	SEARCH_SELECT_HARVEST_YEAR,
	SEARCH_SELECT_SPECIALITY,
	SEARCH_SELECT_GRADE,
	SEARCH_SET_GRADE_VISIBLE,
	SEARCH_SET_SPEC_VALUE,
	SEARCH_SELECT_INCO,
	SEARCH_SELECT_PRESET,
	SEARCH_SELECT_PRICE_TYPE,
	SEARCH_SELECT_SHIPMENT_TYPE,
	SEARCH_SELECT_PAYMENT,
	SEARCH_SELECT_ROLE,
	SEARCH_SELECT_VOLUME_RANGE,
	SEARCH_SELECT_LOCATION_PORT,
	SEARCH_SELECT_SHIPMENT,
	SEARCH_SELECT_LOCATION,
	SEARCH_RESET_LOCATION_FILTERS,
	SEARCH_RESET_CONTRACT_FILTERS,
	SEARCH_RESET_PRODUCT_FILTERS,
	SEARCH_SET_ENVIRONMENT,
	SEARCH_SET_MARKET,
	SEARCH_SET_MY_APPROVED_CONTACTS,
	SEARCH_CLEAR_FILTERS,
	SEARCH_SET_CLOSED,
	searchActionTypes,
} from '../actions';
import { FilterType } from 'src/containers/TeamsDashboard/constants';

const initialPhysicalMarketSearchState = {
	products: [],
	orderType: null,
	inco: [],
	priceType: [],
	payment: [],
	role: [],
	volumeMin: null,
	volumeMax: null,
	shipmentType: [],
	locationPorts: [],
	location: {
		countries: [],
		regions: [],
	},
	shipment: null,
	myApprovedContacts: false,
};

const initialPaperMarketSearchState = {
	...initialPhysicalMarketSearchState,
	preset: [],
	instrument: FilterType.All,
	shipment: {
		format: 'months',
	},
};

export const defaultEmptyState = {
	visibleProductId: null,
	visibleProductName: '',
	visibleGradeId: null,
	visibleGrade: null,
	closed: false,
	market: Market.Physical,
	environment: null,
	[Market.Physical]: initialPhysicalMarketSearchState,
	[Market.Paper]: {
		...initialPaperMarketSearchState,
	},
};

const loadedState = loadState(storageKey.search);

// TODO: write a generic solution that will go through existing filters and overwrite/add new keys whenever we introduce breaking change
// https://vosbor.atlassian.net/browse/TD-646
// In case user has old state without `role`, we need to set its value to what was previously under `principal` or default empty array
if (loadedState) {
	if (!loadedState[Market.Physical].role) {
		loadedState[Market.Physical].role = loadedState[Market.Physical]?.principal || [];
	}

	if (!loadedState[Market.Paper].role) {
		loadedState[Market.Paper].role = loadedState[Market.Paper]?.principal || [];
	}
}

const initialState = loadedState || defaultEmptyState;

export function searchReducers(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case SEARCH_SET_PRODUCT_VISIBLE:
			return {
				...state,
				visibleGradeId: null,
				visibleGrade: null,
				visibleProductId: payload._key,
				visibleProductName: payload.name,
			};
		case SEARCH_SET_GRADE_VISIBLE:
			return {
				...state,
				visibleGradeId: payload._key,
				visibleGrade: payload,
			};
		case SEARCH_SET_MARKET: {
			return {
				...state,
				market: payload,
			};
		}
		case SEARCH_SET_ENVIRONMENT: {
			return {
				...state,
				environment: payload,
			};
		}
		case SEARCH_SET_CLOSED: {
			return {
				...state,
				closed: payload,
			};
		}
		case SEARCH_CLEAR_FILTERS: {
			return {
				...state,
				visibleProductId: null,
				visibleProductName: '',
				visibleGradeId: null,
				visibleGrade: null,
				[state.market]:
					state.market === Market.Physical
						? initialPhysicalMarketSearchState
						: initialPaperMarketSearchState,
			};
		}
		/**
		 * NOTE: technically it should be in the marketSearchReducer
		 * but we want to make sure that it always modifies the paper state, even if the physical market is selected
		 * It's because we call this action on the app start when user don't have the specified localStorage flag and set Paranagua to default filter
		 */
		case SEARCH_SELECT_PRESET:
			return {
				...state,
				paper: {
					...state.paper,
					preset: state.paper.preset ? toggle(state.paper.preset, payload) : [payload],
				},
			};
		default: {
			if (searchActionTypes.includes(type)) {
				const marketSearchState = state[state.market];
				return {
					...state,
					[state.market]: marketSearchReducer(marketSearchState, action, state),
				};
			}

			return state;
		}
	}
}

export function marketSearchReducer(state, action, visibleFilters) {
	const { type, payload } = action;

	switch (type) {
		case SEARCH_RESET_PRODUCT_FILTERS:
			return {
				...state,
				products: [],
			};
		case SEARCH_SELECT_PRODUCT:
			const products = toggleByKey(
				state.products,
				createProductFilter({
					typeId: payload.type_id,
					productId: payload._key,
				})
			);

			return {
				...state,
				products,
			};

		case SEARCH_SET_MY_APPROVED_CONTACTS: {
			return {
				...state,
				myApprovedContacts: payload,
			};
		}

		case SEARCH_SET_SPEC_VALUE:
		case SEARCH_SELECT_GRADE:
		case SEARCH_SELECT_HARVEST_YEAR:
		case SEARCH_SELECT_SPECIALITY:
		case SEARCH_SELECT_ORIGIN: {
			const products = getOrCreateVisibleProduct(state.products, visibleFilters);

			return {
				...state,
				products: products.map(product => {
					if (product._key !== visibleFilters.visibleProductId) {
						return product;
					}
					return productReducer(product, { type, payload });
				}),
			};
		}

		case SEARCH_SELECT_INCO:
			return {
				...state,
				inco: toggle(state.inco, payload),
			};

		case SEARCH_SELECT_PRICE_TYPE:
			return {
				...state,
				priceType: toggle(state.priceType, payload),
			};

		case SEARCH_SELECT_SHIPMENT_TYPE:
			return {
				...state,
				shipmentType: toggle(
					state.shipmentType || initialPhysicalMarketSearchState.shipmentType,
					payload
				),
			};

		case SEARCH_SELECT_PAYMENT:
			return {
				...state,
				payment: toggle(state.payment, payload),
			};

		case SEARCH_SELECT_ROLE:
			return {
				...state,
				role: toggle(state.role, payload),
			};

		case SEARCH_SELECT_VOLUME_RANGE:
			return {
				...state,
				volumeMax: isNaN(parseInt(payload.volumeMax, 10))
					? null
					: parseInt(payload.volumeMax, 10),
				volumeMin: isNaN(parseInt(payload.volumeMin, 10))
					? null
					: parseInt(payload.volumeMin, 10),
			};

		case SEARCH_SELECT_LOCATION_PORT:
			return {
				...state,
				locationPorts: toggleByKey(state.locationPorts, payload),
			};

		case SEARCH_SELECT_LOCATION:
			return {
				...state,
				location: payload,
			};

		case SEARCH_RESET_LOCATION_FILTERS:
			return {
				...state,
				locationPorts: [],
				location: {
					countries: [],
					regions: [],
				},
			};

		case SEARCH_RESET_CONTRACT_FILTERS:
			return {
				...state,
				inco: [],
				preset: [],
				priceType: [],
				payment: [],
				role: [],
				volumeMin: null,
				volumeMax: null,
				shipmentType: [],
			};

		case SEARCH_SELECT_SHIPMENT:
			return {
				...state,
				shipment: payload,
			};

		case SEARCH_SELECT_ORDER_TYPE:
			return {
				...state,
				orderType: payload,
			};

		case SEARCH_SELECT_INSTRUMENT:
			return {
				...state,
				instrument: payload,
			};

		default:
			return state;
	}
}

function productReducer(state, { type, payload }) {
	switch (type) {
		case SEARCH_SELECT_GRADE: {
			return {
				...state,
				grades: toggleByKey(state.grades, createGradeFilter({ gradeId: payload._key })),
			};
		}
		case SEARCH_SELECT_HARVEST_YEAR: {
			return { ...state, harvestYears: toggle(state.harvestYears, payload._key) };
		}
		case SEARCH_SELECT_SPECIALITY: {
			return { ...state, specialities: toggle(state.specialities, payload._key) };
		}
		case SEARCH_SELECT_ORIGIN: {
			return { ...state, origins: toggle(state.origins, payload._key) };
		}
		case SEARCH_SET_SPEC_VALUE: {
			if (payload.value === undefined) {
				return {
					...state,
					grades: state.grades.map(grade => {
						return grade._key !== payload.gradeId
							? grade
							: {
									...grade,
									specs: grade.specs.filter(spec => spec._key !== payload._key),
							  };
					}),
				};
			}

			const grades = state.grades.find(g => g._key === payload.gradeId)
				? state.grades
				: state.grades.concat({
						_key: payload.gradeId,
						specs: [],
				  });

			return {
				...state,
				grades: grades.map(grade => {
					if (grade._key !== payload.gradeId) {
						return grade;
					}
					const gradeSpecs = grade.specs.find(g => g._key === payload._key)
						? grade.specs
						: grade.specs.concat({
								_key: payload._key,
						  });

					return {
						...grade,
						specs: gradeSpecs.map(gradeSpec => {
							if (gradeSpec._key !== payload._key) {
								return gradeSpec;
							}
							return {
								...gradeSpec,
								[payload.direction]: payload.value,
							};
						}),
					};
				}),
			};
		}

		default:
			return state;
	}
}

function getOrCreateVisibleProduct(products, state) {
	return products.find(p => p._key === state.visibleProductId)
		? products
		: [
				...products,
				createProductFilter({
					productId: state.visibleProductId,
				}),
		  ];
}

const createProductFilter = ({ typeId, productId }) => ({
	type_id: typeId,
	_key: productId,
	origins: [],
	specialities: [],
	harvestYears: [],
	grades: [],
});

const createGradeFilter = ({ gradeId }) => ({
	_key: gradeId,
	specs: [],
});
