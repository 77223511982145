/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { H900, H600 } from 'src/components/Typography';
import { tCurrency, QuoteType, tQuoteValueType } from 'src/constants/contract';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import * as Styled from './styled';

export const SpreadPricePill = ({ order, changed }) => {
	const { t } = useTranslation();
	const { isExpired, isTerminatedOrNotAvailable } = useOrderDetails(order);
	const { price, currency, price_unit, counterType, is_indicative, trade_id } = order;

	const isCounter = !!counterType;

	const quoteType = is_indicative ? QuoteType.Indicative : QuoteType.Firm;

	const formattedPrice = formatPriceWithoutCurrency(price);

	const isTraded = !!trade_id;

	return (
		<Styled.SpreadPill
			$isOrderClosed={!isCounter && (isExpired || isTerminatedOrNotAvailable)}
			$isChanged={changed}
			data-test="price-pill"
		>
			<Styled.OrderType>
				<H600 as="span">{isTraded ? t('traded') : tQuoteValueType(t, quoteType)}</H600>
			</Styled.OrderType>
			<Styled.PriceDetails>
				<H600 as="span">{`${tCurrency(t, currency)}/${t(`price_unit_short_${price_unit}`, {
					lng: 'en',
				})}`}</H600>
				<H900 as="span">{formattedPrice > 0 ? `+${formattedPrice}` : formattedPrice}</H900>
			</Styled.PriceDetails>
		</Styled.SpreadPill>
	);
};
