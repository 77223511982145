/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useState, useEffect } from 'react';
import { css } from 'styled-components';

export const screenSize = {
	xl: 1920,
	ll: 1440, // we need additional step between l and xl - I'd be happy to use better name than "ll", but can't think of anything better
	l: 1280,
	m: 1024,
	s: 768,
};

export const screenHeightSize = {
	xl: 1080,
	l: 720,
	m: 660,
	s: 600,
};

export const media = Object.keys(screenSize).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (min-width: ${screenSize[label]}px) {
			${css(...args)};
		}
	`;
	return acc;
}, {});

export const mediaHeight = Object.keys(screenHeightSize).reduce((acc, label) => {
	acc[label] = (...args) => css`
		@media (min-height: ${screenHeightSize[label]}px) {
			${css(...args)};
		}
	`;
	return acc;
}, {});

export const useMediaQuery = query => {
	const queryString = Object.entries(query)
		.map(([key, value]) => `(${key}: ${value}px)`)
		.join(' and ');
	const mediaQuery = window.matchMedia(queryString);

	const [match, setMatch] = useState(!!mediaQuery.matches);

	useEffect(() => {
		const handler = () => setMatch(!!mediaQuery.matches);
		mediaQuery.addEventListener('change', handler);

		return () => mediaQuery.removeEventListener('change', handler);
	}, [mediaQuery]);

	return match;
};
