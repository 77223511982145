/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import * as Styled from './styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { FieldContainer, SectionWrapper } from '../FoldableSection/styled';
import { H400 } from 'src/components/Typography';
import { defaultContractPricingRowValue } from 'src/components/CreateOrderDrawer/paper/model';
import { getDefaultValuesForPreset } from '../../paper/helpers/getDefaultValuesForPreset';
import { useWatchPaperFields } from '../../paper/useWatchPaperFields';
import { DrawerContextKeys, useDrawerContext } from 'src/components/Drawer/DrawerContext';

export const AddAnotherOrderButton = ({ onAppend }) => {
	const { t } = useTranslation();

	const {
		[DrawerContextKeys.createDrawer]: { copyParams },
	} = useDrawerContext();

	const { selectedProductPresetValue } = useWatchPaperFields();

	return (
		<SectionWrapper>
			<FieldContainer>
				<Styled.AddButton
					onClick={() =>
						onAppend({
							...defaultContractPricingRowValue,
							...getDefaultValuesForPreset(selectedProductPresetValue),
							...(!!copyParams?.order_type && {
								type: copyParams.order_type,
							}),
						})
					}
					data-test="add-another-order-button"
				>
					<PlusIcon />
					<H400>{t('add_another_order')}</H400>
				</Styled.AddButton>
			</FieldContainer>
		</SectionWrapper>
	);
};
