/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Children, cloneElement } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { Table } from 'semantic-ui-react';
import styled, { css } from 'styled-components';
import { IncoType } from 'src/constants/contract';
import { useToast } from 'src/components/Toast';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import { useMyRowsActivity } from './useMyRowsActivity';
import { CounterStatus, validityStatusCheck } from 'src/constants/counterStatus';
import { useUpdateAtTime } from 'src/_helpers/useUpdateAtTime';
import { useUnseenActivitiesContext } from 'src/containers/Dashboard/Footer/MyTabsContext';
import { DrawerContextKeys, useDrawerContext } from '../Drawer/DrawerContext';

const mapChildrenWithLinkTo = (children, linkTo) => {
	return Children.map(children, child =>
		cloneElement(child, {
			item: {
				...child.props.item,
				linkTo,
			},
		})
	);
};

export const MyNegotiationsRow = ({ item: order, children, ...props }) => {
	const { shouldHighlight, handleClick } = useMyRowsActivity(order);
	const { decreaseNegotiationsCount } = useUnseenActivitiesContext();
	const hasJustExpired = useUpdateAtTime(order.validity);

	const childrenList = mapChildrenWithLinkTo(
		children,
		`/?orderEnvironment=${order.environment}&orderId=${order.order_id}&negotiationId=${order.first_counter_id}`
	);

	const onMouseDown = mouseEvent => {
		if (shouldHighlight) {
			decreaseNegotiationsCount();
		}
		handleClick(mouseEvent);
	};

	return (
		<StyledRow
			$highlight={shouldHighlight && !hasJustExpired}
			onMouseDown={onMouseDown}
			{...props}
		>
			{childrenList}
		</StyledRow>
	);
};

export const NegotiationHistoryRow = ({ item: order, children, ...props }) => {
	const childrenList = mapChildrenWithLinkTo(
		children,
		`/?orderEnvironment=${order.environment}&orderId=${order.order_id}&negotiationId=${order.first_counter_id}`
	);

	return <ClickableRow {...props}>{childrenList}</ClickableRow>;
};

export const MyOrderCounterRow = ({ item: counter, children, ...props }) => {
	const { shouldHighlight, handleClick } = useMyRowsActivity(counter);

	const hasJustExpired = useUpdateAtTime(counter.validity);

	const isExpiredCounter =
		counter.status === CounterStatus.Expired ||
		validityStatusCheck(counter.validity, counter.status);

	const childrenList = mapChildrenWithLinkTo(
		children,
		`/?orderEnvironment=${counter.environment}&orderId=${counter.order_id}&negotiationId=${counter.first_counter_id}`
	);

	return (
		<StyledRow
			$highlight={shouldHighlight}
			$expired={isExpiredCounter || hasJustExpired}
			onMouseDown={handleClick}
			{...props}
		>
			{childrenList}
		</StyledRow>
	);
};

export const OrderRow = ({ item: order, children, ...props }) => {
	const contextData = useDrawerContext();

	const {
		setCurrentOrderInfo,
		visibility: [, { open: openDrawer }],
		currentOrderInfo,
	} = contextData[DrawerContextKeys.viewDrawer];
	const isOrderOpen = currentOrderInfo ? currentOrderInfo?.orderId === order?._key : false;

	const handleRowClick = () => {
		setCurrentOrderInfo({
			orderId: order._key,
			environment: order.environment,
		});
		openDrawer();
	};

	return (
		<Table.Row
			{...props}
			onClick={handleRowClick}
			className={clsx(props.className, isOrderOpen && 'highlight-row', 'clickable-row')}
		>
			{children}
		</Table.Row>
	);
};

export const MyOrderRow = ({ item: order, children, ...props }) => {
	const contextData = useDrawerContext();

	const {
		setCurrentOrderInfo,
		visibility: [, { open: openDrawer }],
		currentOrderInfo,
	} = contextData[DrawerContextKeys.viewDrawer];
	const isOrderOpen = currentOrderInfo ? currentOrderInfo?.orderId === order?._key : false;

	const handleRowClick = () => {
		setCurrentOrderInfo({
			orderId: order._key,
			environment: order.environment,
			market: order.market,
		});

		openDrawer();
	};

	return (
		<ClickableRow
			{...props}
			onMouseDown={handleRowClick}
			className={clsx(isOrderOpen && 'highlight-row', 'clickable-row')}
		>
			{children}
		</ClickableRow>
	);
};

export const TradeRow = ({ item: trade, children, ...props }) => {
	const userId = useSelector(getUserId);

	const { addToast } = useToast();
	const { t } = useTranslation();
	const isPKPGTrade = !!trade?.runs;
	const hasNewActivities = !!trade?.has_new_activities;

	const linkTo = `${`${ROUTES.execution}/trade`}/${trade._key}`;

	const canUserSeeTradeDetails =
		trade.seller_user_ids.includes(userId) || trade.buyer_user_ids.includes(userId);

	const isUnavailableTrade =
		isPKPGTrade || trade.inco_id === IncoType.RENDU || !canUserSeeTradeDetails;
	const childrenList = mapChildrenWithLinkTo(children, isUnavailableTrade ? undefined : linkTo);

	return (
		<Table.Row
			{...props}
			className={clsx(hasNewActivities && !isUnavailableTrade && 'highlighted')}
			onClick={() => {
				// TODO: Remove when RENDU inco post-trade process will be done
				if (trade.inco_id === IncoType.RENDU) {
					addToast({
						message: t('rendu_no_post_trade_available'),
						kind: 'warning',
					});

					return;
				}

				// TODO: Remove when PKPG post-trade process will be done
				if (isPKPGTrade) {
					addToast({
						message: t('pkpb_no_post_trade_available'),
						kind: 'warning',
					});

					return;
				}
			}}
		>
			{childrenList}
		</Table.Row>
	);
};

export const MyTradeRow = ({ item: trade, children, ...props }) => {
	const { shouldHighlight, handleClick } = useMyRowsActivity(trade);
	const { decreaseTradesCount } = useUnseenActivitiesContext();

	const path = `/?orderEnvironment=${trade.environment}&orderId=${trade.order_id}&negotiationId=${trade.first_counter_id}`;

	const childrenList = mapChildrenWithLinkTo(children, {
		pathname: path,
		state: { myTabs: true },
	});

	const onMouseDown = mouseEvent => {
		if (shouldHighlight) {
			decreaseTradesCount();
		}
		handleClick(mouseEvent);
	};

	return (
		<StyledRow $highlight={shouldHighlight} onMouseDown={onMouseDown} {...props}>
			{childrenList}
		</StyledRow>
	);
};

export const KYCActiveConversationRow = ({ item, children, ...props }) => {
	const childrenList = mapChildrenWithLinkTo(
		children,
		`${ROUTES.kyc}/${ROUTES.kycActiveConversationsList}/${item.conversationId}`
	);

	return <StyledRow {...props}>{childrenList}</StyledRow>;
};

const ClickableRow = styled(Table.Row)`
	& > td {
		cursor: pointer;
	}
`;

const StyledRow = styled(ClickableRow)`
	${({ $highlight, $expired }) =>
		$highlight &&
		!$expired &&
		css`
			background: var(--table_row_gradient);
		`};

	${({ $expired }) =>
		$expired &&
		css`
			td {
				color: inherit !important;
			}
		`};
`;
