/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toPath } from 'src/_helpers';
import { getShipmentCutOffDate, isNextMonthOrAfter } from 'src/_helpers/date';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { MaterialSelectInput } from 'src/designSystem/Form/MaterialInput/MaterialSelectInput';
import { MaterialDateInput } from 'src/designSystem/Form/MaterialInput/MaterialDateInput';
import { tCurrency, QuoteType } from 'src/constants/contract';
import { useCalendarSpreadSection } from './useCalendarSpreadSection';
import { useWatchPaperFields } from '../../useWatchPaperFields';
import * as Styled from './styled';

export const CalendarSpreadRow = ({ path }) => {
	const { t } = useTranslation();

	const { setValue } = useFormContext();

	const { firstLegMonthValue, orderTypeValue } = useWatchPaperFields(path);
	const {
		firstLegOrderType,
		secondLegQuote,
		firstLegMonth,
		firstLegFuturesMonth,
		secondLegFuturesMonth,
		currency,
		unit,
	} = useCalendarSpreadSection({ path });

	const formattedCurrencyUnit = (
		<>
			<Styled.CurrencyUnitSeparator />
			<span>{`${tCurrency(t, currency)}/${t(`price_unit_short_${unit}`, {
				lng: 'en',
			})}`}</span>
		</>
	);

	return (
		<>
			<Controller
				mandatory
				name={toPath(path, 'type')}
				render={({ field, fieldState: { error } }) => (
					<MaterialSelectInput
						required
						error={!!error}
						data-test={field.name}
						label={t('first_leg_quote')}
						options={firstLegOrderType.options}
						disabled={!firstLegOrderType.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name={toPath(path, 'firstLegMonth')}
				defaultValue={null}
				render={({ field, fieldState: { error } }) => (
					<Styled.CalendarWrapper>
						<MaterialDateInput
							{...field}
							required
							id={field.name}
							data-test={field.name}
							label={t('first_leg_month')}
							error={!!error}
							cutOffDate={getShipmentCutOffDate()}
							renderDateToggleButton={false}
							allowMultipleMonths={false}
							onChange={value => {
								firstLegMonth.onChange(value);
								field.onChange(value);
							}}
						/>
					</Styled.CalendarWrapper>
				)}
			/>
			<Controller
				name={toPath(path, 'firstLegFuturesMonth')}
				mandatory
				render={({ field, fieldState: { error } }) => (
					<MaterialSelectInput
						required
						error={!!error}
						data-test={field.name}
						label={t('first_leg_futures')}
						options={firstLegFuturesMonth.options}
						{...field}
						onChange={value => {
							field.onChange(value);
							setValue(toPath(path, 'firstLegFuturesMonthChanged'), true);
						}}
					/>
				)}
			/>
			<Controller
				mandatory
				name={toPath(path, 'secondLegQuote')}
				render={({ field, fieldState: { error } }) => (
					<MaterialSelectInput
						required
						error={!!error}
						data-test={field.name}
						label={t('second_leg_quote')}
						options={secondLegQuote.options}
						disabled={!secondLegQuote.ready}
						{...field}
					/>
				)}
			/>
			<Controller
				mandatory
				name={toPath(path, 'secondLegMonth')}
				defaultValue={null}
				render={({ field, fieldState: { error } }) => (
					<Styled.CalendarWrapper>
						<MaterialDateInput
							{...field}
							required
							id={field.name}
							data-test={field.name}
							label={t('second_leg_month')}
							error={!!error}
							cutOffDate={getShipmentCutOffDate()}
							renderDateToggleButton={false}
							allowMultipleMonths={false}
							isMonthDisabled={month =>
								!isNextMonthOrAfter(month, firstLegMonthValue.endDate)
							}
						/>
					</Styled.CalendarWrapper>
				)}
			/>
			<Controller
				name={toPath(path, 'secondLegFuturesMonth')}
				mandatory
				render={({ field, fieldState: { error } }) => (
					<MaterialSelectInput
						required
						error={!!error}
						data-test={field.name}
						label={t('second_leg_futures')}
						options={secondLegFuturesMonth.options}
						{...field}
						onChange={value => {
							field.onChange(value);
							setValue(toPath(path, 'secondLegFuturesMonthChanged'), true);
						}}
					/>
				)}
			/>
			<Controller
				name={toPath(path, 'price')}
				mandatory
				render={({ field, fieldState: { error } }) => (
					<Styled.SpreadPriceContainer>
						<MaterialNumberInput
							error={!!error}
							data-test="priceCurrencyUnit"
							label={t('spread_price')}
							required
							allowNegative
							parameters={formattedCurrencyUnit}
							{...field}
						/>
					</Styled.SpreadPriceContainer>
				)}
			/>
			<Controller
				mandatory
				name={toPath(path, 'volume')}
				render={({ field, fieldState: { error } }) => (
					<MaterialNumberInput
						required
						error={!!error}
						data-test={field.name}
						id={field.name}
						label={t('quantity')}
						fixedDecimalScale={false}
						parameters={t('price_unit_short_metric_ton')}
						{...field}
					/>
				)}
			/>
			<Controller
				name={toPath(path, 'firstLegPrice')}
				mandatory={orderTypeValue === QuoteType.Firm}
				render={({ field, fieldState: { error } }) => (
					<Styled.FirstLegContainer>
						<MaterialNumberInput
							error={!!error}
							data-test="firstLegPriceCurrencyUnit"
							id={field.name}
							label={t('first_leg_price')}
							required={orderTypeValue === QuoteType.Firm}
							allowNegative
							parameters={formattedCurrencyUnit}
							{...field}
						/>
					</Styled.FirstLegContainer>
				)}
			/>
		</>
	);
};
