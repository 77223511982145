/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ThemeNames, ThemeColors } from 'src/constants/settings';
import { fetchFromExchangeApi, map404ToEmptyObject, fetchFromServerSide } from './model';
import { Market } from 'src/constants/contract';

const mapBuyColorToTheme = {
	[ThemeColors.green]: ThemeNames.standard,
	[ThemeColors.red]: ThemeNames.alternative,
	[ThemeColors.blue]: ThemeNames.standardColorblindness,
	[ThemeColors.orange]: ThemeNames.alternativeColorblindness,
};

export const mapThemeToColors = {
	[ThemeNames.standard]: {
		buy_color: ThemeColors.green,
		sell_color: ThemeColors.red,
	},
	[ThemeNames.alternative]: {
		buy_color: ThemeColors.red,
		sell_color: ThemeColors.green,
	},
	[ThemeNames.standardColorblindness]: {
		buy_color: ThemeColors.blue,
		sell_color: ThemeColors.orange,
	},
	[ThemeNames.alternativeColorblindness]: {
		buy_color: ThemeColors.orange,
		sell_color: ThemeColors.blue,
	},
};

export async function getSettings() {
	const result = await fetchFromExchangeApi('settings/read', {});

	return {
		theme: mapBuyColorToTheme[result.buy_color] || ThemeNames.standard,
		isOnline: !result.hide_visibility,
		avatarColor: result.avatar_color || 'var(--neutral-400)',
		isChatInfoVisible: !result.chat_hide_info_message,
		supportConversationId: result.support_conversation_id,
		defaultMarket: result.default_market || Market.Paper,
		lastTourVersionSeen: result.last_tour_version_seen || 0,
	};
}

export const updateSettings = async ({ body }) => {
	return await fetchFromExchangeApi('settings/patch', {
		method: 'PATCH',
		body,
	});
};

export const getTermsAndConditions = async () => {
	const result = await fetchFromExchangeApi('terms_and_conditions/newest_version', {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});

	return result;
};

export const getPublicTermsAndConditions = async ({ countryCode }) => {
	const result = await fetchFromExchangeApi('terms_and_conditions', {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
		query: {
			country_code: countryCode,
		},
	});

	return result;
};

export const acceptTermsAndConditions = async ({ version }) => {
	return await fetchFromExchangeApi(`terms_and_conditions/${version}/accept`, {
		method: 'POST',
	});
};

export const getFeaturesFlagList = async () => {
	const result = await fetchFromExchangeApi('features_list', {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});

	return result;
};

export const getGitVersion = async () => {
	return await fetchFromServerSide('version', {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});
};
