/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import {
	SectionContainer,
	SectionTitle,
	SectionGrid,
	SectionGridContent,
	SectionGridLabel,
	SectionGridText,
} from 'src/components/ViewOrderDrawer/styled';

export const SpreadLegRow = ({ legType, data }) => {
	const { t } = useTranslation();

	return (
		<SectionContainer data-test={`${legType}_section`}>
			<SectionTitle>{t(legType)}</SectionTitle>
			<SectionGrid>
				{data.map(({ label, value, wide, changed }) => (
					<SectionGridContent key={label} className={clsx({ changed, wide })}>
						<SectionGridLabel>{label}</SectionGridLabel>
						<SectionGridText
							data-test={`${legType}_${label.toLowerCase().replaceAll(' ', '-')}`}
						>
							{wide ? value : <OverflowText>{value}</OverflowText>}
						</SectionGridText>
					</SectionGridContent>
				))}
			</SectionGrid>
		</SectionContainer>
	);
};
