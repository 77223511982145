/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { Role } from 'src/constants/contract';

export const getHiddenUserObject = t => ({
	_id: '',
	_key: '',
	first_name: '',
	last_name: '',
	name: '---',
	company_id: '',
	company: {
		name: t('hidden'),
	},
	avatar_color: 'var(--neutral-300)',
	status: '',
});

// order_user_id -> returns owner always
// counter_order_user_id -> returns counterparty always
// user_id -> who countered given counter (or order owner if it's order)
// counter_order_type -> sell|buy

export const getUserBrokerPrincipal = ({ currentUserId, order, companyColor, t }) => {
	const isOrderPreview = !!order.is_order_preview;
	const isMyTeamOrder = !order.is_my_order && order.is_my_team_order;
	const isHiddenOrder = !!order.hidden && !order.is_identity_revealed && !isMyTeamOrder;
	const isOrder = order.is_order === true;

	const isTraded = !!order.trade_id;
	const isArtificial = order.artificial;

	const isCounterPreview = !!order.is_counter_preview;

	const isViewingMyOwnOrderOrCounter = currentUserId === order.user?._key;

	const isMyOrder = isOrder && isViewingMyOwnOrderOrCounter;
	const iAmOrderCreator = order.order_user_id === currentUserId;
	const shouldHideUser = !isCounterPreview && !isOrderPreview && !isMyOrder && isHiddenOrder;

	const isMyCounterToMyOrder =
		(!isOrder || isCounterPreview) && iAmOrderCreator && isViewingMyOwnOrderOrCounter;

	const isBrokerOrder = order.role === Role.Broker;
	const isPrincipalDefined = typeof order.order_owner_principal_id !== 'undefined';

	const getUser = () => {
		if (isTraded) {
			const userData = iAmOrderCreator ? order.counter_order_user : order.order_user;
			const userCompanyData = iAmOrderCreator
				? order.counter_order_user_company
				: order.order_user_company;

			return {
				...userData,
				company: {
					...userCompanyData,
					avatar_color: userCompanyData.avatar_color || companyColor,
				},
			};
		}

		return {
			...order.user,
			company: {
				...order.user?.company,
				avatar_color: order.user?.company.avatar_color || companyColor,
			},
			...(shouldHideUser && getHiddenUserObject(t)),
		};
	};
	const user = getUser();

	const isBrokerUser =
		(isBrokerOrder && user._key === order.order_user_id) ||
		(isBrokerOrder && isOrder && order.user_id === user._key);

	const getUserRole = () => {
		if (isOrderPreview) {
			return t(order.role);
		}

		if (isCounterPreview && isBrokerUser) {
			return t('broker');
		}

		if (isBrokerUser && isTraded && iAmOrderCreator) {
			return t('principal');
		}

		if (isBrokerUser || (isBrokerOrder && isHiddenOrder && !isViewingMyOwnOrderOrCounter)) {
			return t('broker');
		}

		return t('principal');
	};

	const userRole = getUserRole();

	const getHiddenIdentity = () => {
		if (isCounterPreview) {
			return false;
		}

		return isHiddenOrder;
	};

	const getHiddenPrincipal = () => {
		if (!isBrokerOrder) {
			return false;
		}

		if (!isTraded && isMyCounterToMyOrder && !isPrincipalDefined) {
			return true;
		}

		if (isOrderPreview || userRole === t('principal')) {
			return false;
		}

		if (isTraded && !iAmOrderCreator && !isPrincipalDefined) {
			return true;
		}

		if (isTraded && isArtificial && !iAmOrderCreator) {
			return true;
		}

		// order creator is viewing other counterparty's counter or counterparty viewing order creator counter
		if (iAmOrderCreator !== isViewingMyOwnOrderOrCounter) {
			return false;
		}

		// one flag at the time - and hidden identity has higher priority
		if (isHiddenOrder) {
			return false;
		}

		if (!isPrincipalDefined) {
			return true;
		}

		return false;
	};

	const hiddenPrincipal = getHiddenPrincipal();
	const hiddenIdentity = getHiddenIdentity();

	const getBroker = () => {
		if (!isBrokerOrder) {
			return null;
		}

		// order creator is viewing other counterparty's counter
		if (iAmOrderCreator && !isViewingMyOwnOrderOrCounter) {
			return null;
		}

		if (!isTraded && iAmOrderCreator) {
			return user;
		}

		if (hiddenPrincipal) {
			return user;
		}

		if (!isOrder && !hiddenPrincipal && !iAmOrderCreator && !isViewingMyOwnOrderOrCounter) {
			return user;
		}

		if (isTraded && !iAmOrderCreator && isPrincipalDefined) {
			return user;
		}

		return null;
	};

	const getPrincipalUserObject = order => {
		const principalUser = order.order_owner_principal_user;

		return {
			...principalUser,
			company: {
				_key: principalUser.company_id,
				avatar_color: principalUser.avatar_color,
				country_code: principalUser.company_country_code,
				name: principalUser.company_name,
				...(order.order_owner_principal_company || {}),
			},
		};
	};

	const getPrincipal = () => {
		if (hiddenPrincipal) {
			return getHiddenUserObject(t);
		}

		if (iAmOrderCreator && !isPrincipalDefined) {
			return null;
		}

		if (!isTraded && isPrincipalDefined && iAmOrderCreator === isViewingMyOwnOrderOrCounter) {
			return getPrincipalUserObject(order);
		}

		if (isBrokerOrder && isTraded && !iAmOrderCreator && !isPrincipalDefined) {
			return getHiddenUserObject(t);
		}

		if (isBrokerOrder && isTraded && !iAmOrderCreator && isPrincipalDefined) {
			return getPrincipalUserObject(order);
		}

		return null;
	};

	const broker = getBroker();
	const principal = getPrincipal();

	return {
		user: !broker && !principal ? user : null,
		userRole,
		broker,
		principal,
		hiddenIdentity,
		hiddenPrincipal,
	};
};
