/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useMemo } from 'react';
import { ChartContainerID, Orientation } from 'src/constants/chart';
import { useQueryParams } from 'src/_helpers/router';

const allowedColorSchemas = [
	'default',
	'alternative',
	'defaultColorblindness',
	'alternativeColorblindness',
];

const green400 = '#00ffe0';
const green600 = '#0CEBD0';
const red400 = '#ed5252';
const blue200 = '#4449e4';
const blue200CTA = '#2A8CFF';
const yellow200 = '#ffab4a';

const LINESTYLE_SOLID = 0;

export const theme = {
	neutral400: '#3e4879',
	neutral800: '#171d3c',
	neutral900: '#0f1328',
	neutral950: '#080d22',
	upColor: {
		default: green400,
		alternative: red400,
		defaultColorblindness: blue200,
		alternativeColorblindness: yellow200,
	},
	downColor: {
		default: red400,
		alternative: green400,
		defaultColorblindness: yellow200,
		alternativeColorblindness: blue200,
	},
};

const mobileChartFeatures = colorSchema => ({
	[Orientation.Portrait]: {
		disabled_features: [
			'left_toolbar',
			'header_indicators',
			'header_settings',
			'header_chart_type',
			'header_undo_redo',
			'timeframes_toolbar',
			'legend_widget',
			'header_resolutions',
		],
		enabled_features: ['header_interval_dialog_button'],
		overrides: {
			'paneProperties.crossHairProperties.color': green600,
		},
		studies_overrides: {
			'volume.volume.color.0': theme.neutral400,
			'volume.volume.color.1': theme.neutral400,
		},
	},
	[Orientation.Landscape]: {
		disabled_features: [],
		enabled_features: [],
		overrides: {
			'paneProperties.crossHairProperties.color': theme.neutral400,
		},
		studies_overrides: {
			'volume.volume.color.0': theme.downColor[colorSchema],
			'volume.volume.color.1': theme.upColor[colorSchema],
		},
	},
});

export const useCreateWidgetOptions = orientation => {
	const { colorSchema, lang } = useQueryParams();
	const language = ['zh', 'en'].includes(lang) ? lang : 'en';
	const defaultColorSchema =
		colorSchema && allowedColorSchemas.includes(colorSchema)
			? colorSchema
			: allowedColorSchemas[0];

	const featuresExtension = mobileChartFeatures(defaultColorSchema)[orientation];

	return useMemo(() => {
		return {
			interval: 'D',
			timeframe: '60M',
			container: ChartContainerID.Mobile,
			library_path: '/charting_library/',
			fullscreen: true,
			autosize: true,
			studies_overrides: { ...featuresExtension.studies_overrides },
			locale: language,
			theme: 'dark',
			disabled_features: [
				'use_localstorage_for_settings',
				'header_fullscreen_button',
				'volume_force_overlay',
				'header_saveload',
				'header_screenshot',
				'header_symbol_search',
				'header_compare',
				...featuresExtension.disabled_features,
			],
			enabled_features: [
				'header_in_fullscreen_mode',
				'hide_left_toolbar_by_default',
				...featuresExtension.enabled_features,
			],
			debug: false,
			custom_css_url: '../charting_library_overrides_mobile.css',
			overrides: {
				'paneProperties.backgroundType': 'solid',
				'paneProperties.background': theme.neutral950,
				'paneProperties.vertGridProperties.color': theme.neutral800,
				'paneProperties.horzGridProperties.color': theme.neutral800,
				'mainSeriesProperties.candleStyle.upColor': theme.upColor[defaultColorSchema],
				'mainSeriesProperties.candleStyle.borderUpColor': theme.upColor[defaultColorSchema],
				'mainSeriesProperties.candleStyle.wickUpColor': theme.upColor[defaultColorSchema],
				'mainSeriesProperties.candleStyle.downColor': theme.downColor[defaultColorSchema],
				'mainSeriesProperties.candleStyle.borderDownColor':
					theme.downColor[defaultColorSchema],
				'mainSeriesProperties.candleStyle.wickDownColor':
					theme.downColor[defaultColorSchema],
				'mainSeriesProperties.areaStyle.color1': 'rgba(42, 140, 255, 0.91)',
				'mainSeriesProperties.areaStyle.color2': 'transparent',
				'mainSeriesProperties.areaStyle.linecolor': blue200CTA,
				'mainSeriesProperties.areaStyle.linestyle': LINESTYLE_SOLID,
				'mainSeriesProperties.areaStyle.linewidth': 2,
				'mainSeriesProperties.areaStyle.priceSource': 'close',
				...featuresExtension.overrides,
			},
			time_frames: [
				{ text: '5y', resolution: '1D', description: '5 years' },
				{ text: '1y', resolution: '1D', description: '1 years' },
				{ text: '6m', resolution: '1D', description: '6 months' },
				{ text: '3m', resolution: '1D', description: '3 months' },
				{ text: '1m', resolution: '1D', description: '1 month' },
				{ text: '5d', resolution: '1D', description: '5 days' },
				{ text: '1d', resolution: '5', description: '1 day' },
			],
			drawings_access: {
				type: 'black', // `black` to blacklist (disable) the following sidebar drawing element(s)
				tools: [
					{
						name: 'Font Icons',
						grayed: false, // Disable but keep in DOM (grey out)?
					},
				],
			},
			load_last_chart: true,
		};
	}, [defaultColorSchema, language, featuresExtension]);
};
