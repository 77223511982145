/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback } from 'react';
import { ReactComponent as CheckCircleOutline } from 'src/assets/icons/check-circle-outline.svg';
import { ReactComponent as ErrorIcon } from 'src/assets/icons/error.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { ThemeProvider } from 'styled-components';
import { ButtonSize } from '../Buttons/Button';
import * as Styled from './styled';

const themes = {
	info: {
		icon: InfoIcon,
		background: 'var(--primary-color)',
		closeIconColor: 'var(--primary-900)',
		textButtonColor: '#ffffff',
		button: Styled.StyledPrimaryButton,
	},
	error: {
		icon: ErrorIcon,
		background: 'var(--red)',
		closeIconColor: 'var(--red-900)',
		button: Styled.StyledNegativeButton,
	},
	warning: {
		icon: WarningIcon,
		background: 'var(--yellow-400)',
		closeIconColor: 'var(--yellow-900)',
		button: Styled.StyledWarningButton,
	},
	success: {
		icon: CheckCircleOutline,
		color: 'var(--neutral-1000)',
		background: 'var(--green)',
		closeIconColor: 'var(--green-900)',
		button: Styled.StyledPositiveButton,
	},
};

export function ToastNotification({
	message,
	kind = 'success',
	onClose,
	topPage = false,
	embeded,
	modal = false,
}) {
	return (
		<Styled.ToastContainer $kind={kind} $topPage={topPage} $modal={modal}>
			<Toast message={message} kind={kind} embeded={embeded} onClose={onClose} />
		</Styled.ToastContainer>
	);
}

export function Toast({
	message,
	kind = 'success',
	button,
	link,
	embeded,
	onClose,
	children,
	...props
}) {
	const theme = themes[kind];
	const Icon = theme.icon;
	const ToastButton = theme.button;

	const canBeClosed = !!onClose;

	const closeToast = useCallback(() => {
		if (canBeClosed) {
			onClose();
		}
	}, [canBeClosed, onClose]);

	const handleButtonClick = useCallback(
		event => {
			event.preventDefault();
			if (button?.onClick) {
				button.onClick();
			}
			if (link?.onClick) {
				link.onClick();
			}
			event.stopPropagation();
		},
		[button, link]
	);

	return (
		<ThemeProvider theme={theme}>
			<Styled.ToastContent $embeded={embeded} {...props} onClick={closeToast}>
				<Icon />
				<Styled.ToastMessage>{message || children}</Styled.ToastMessage>
				{link && (
					<ToastButton
						to={link.to}
						size={ButtonSize.Small}
						data-test={link.name}
						onClick={handleButtonClick}
					>
						{link.text}
					</ToastButton>
				)}
				{button && (
					<ToastButton
						size={ButtonSize.Small}
						onClick={handleButtonClick}
						data-test={button.name}
					>
						{button.text}
					</ToastButton>
				)}
				{canBeClosed && (
					<Styled.CloseButton basic onClick={closeToast}>
						<Styled.StyledCloseIcon />
					</Styled.CloseButton>
				)}
			</Styled.ToastContent>
		</ThemeProvider>
	);
}
