/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserCompanyName } from 'src/_store/selectors';
import { LegType, OrderType } from 'src/constants/contract';
import { formatDateMonthYear } from 'src/_helpers/date';
import { SpreadLegRow } from 'src/components/ViewOrderDrawer/components/SpreadLegRow/SpreadLegRow';
import { Divider } from 'src/components/ViewOrderDrawer/styled';
import { useWatchPaperCounterFields } from '../../../helpers/paper/useWatchPaperCounterFields';

export const CalendarSpreadInfo = ({ path }) => {
	const { t } = useTranslation();
	const myCompanyName = useSelector(getUserCompanyName);

	const {
		firstLegMonthValue,
		firstLegFuturesMonthValue,
		secondLegMonthValue,
		secondLegFuturesMonthValue,
		spreadMetaValue,
	} = useWatchPaperCounterFields(path);

	const userLabels =
		spreadMetaValue.orderCounterType === OrderType.Sell
			? ['buyer', 'seller']
			: ['seller', 'buyer'];

	const counterpartyCompanyName = spreadMetaValue.shouldShowUser
		? spreadMetaValue.user?.company?.name
		: t('hidden');

	const firstLegData = [
		{
			label: t(userLabels[0]),
			value: myCompanyName,
		},
		{
			label: t(userLabels[1]),
			value: counterpartyCompanyName,
		},
		{
			label: t('first_leg_month'),
			value: formatDateMonthYear(firstLegMonthValue.startDate),
		},
		{
			label: t('first_leg_futures'),
			value: formatDateMonthYear(new Date(firstLegFuturesMonthValue).toISOString()),
		},
	];

	const secondLegData = [
		{
			label: t(userLabels[0]),
			value: counterpartyCompanyName,
		},
		{
			label: t(userLabels[1]),
			value: myCompanyName,
		},
		{
			label: t('second_leg_month'),
			value: formatDateMonthYear(secondLegMonthValue.startDate),
		},
		{
			label: t('second_leg_futures'),
			value: formatDateMonthYear(new Date(secondLegFuturesMonthValue).toISOString()),
		},
	];

	return (
		<>
			<SpreadLegRow legType={LegType.First} data={firstLegData} />
			<Divider />
			<SpreadLegRow legType={LegType.Second} data={secondLegData} />
			<Divider $withSpacing />
		</>
	);
};
