/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { OrderType, Market } from 'src/constants/contract';

const getOppositeSide = side => {
	return side === 'buyer' ? 'seller' : 'buyer';
};

export const getUserSide = (user, order) => {
	const orderCreatorSide = order.order_type === OrderType.Buy ? 'buyer' : 'seller';

	if (order.is_order || order.is_order_preview || order.order_user_id === user._key) {
		return orderCreatorSide;
	} else if (order.market === Market.Paper && order.is_counter_preview) {
		return orderCreatorSide;
	} else {
		return getOppositeSide(orderCreatorSide);
	}
};
