/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ReactComponent as Badge } from 'src/assets/icons/badge_icon.svg';
import { h600, h400, m200 } from 'src/components/Typography';

export const UserContainer = styled.div`
	display: flex;
	align-items: start;
	gap: 4px;
	width: 100%;

	& > span {
		width: inherit;
	}

	&.changed .user-details {
		position: relative;
		z-index: 1;

		&:before {
			content: '';
			background-color: var(--counter-highlight-color);
			inset: -6.5px -6px;
			border-radius: 8px;
			position: absolute;
			z-index: -1;

			${media.xl`
				border-radius: 16px;
			`}
		}
	}

	.user-avatar-company-name {
		max-width: 124px;

		${media.ll`
			max-width: 150px;
		`}

		${media.xl`
			max-width: 168px;
		`}
	}

	.user-avatar-details {
		margin-left: 10px;

		${media.xl`
			margin-left: 12px;
		`}
	}

	.user-avatar-username-container,
	.user-avatar-container {
		${props => props.$isHiddenAndOwnOrder && 'max-width: 68px'};

		${media.ll`
			${props => props.$isHiddenAndOwnOrder && 'max-width: 94px'};
		`};

		${media.xl`
			${props => props.$isHiddenAndOwnOrder && 'max-width: 110px'};
		`};
	}

	.user-avatar-username {
		${h600}
		padding-top: 0;
	}

	.order-creator-user-avatar {
		place-self: start;
	}

	${({ $isTraded }) =>
		$isTraded &&
		`
		.user-company-container {
			justify-content: space-between;
		}

		.user-details {
			width: 100%;
		}
  `}
`;

export const InfoIcon = styled(Info)`
	width: 20px;
	height: 20px;
	color: var(--neutral-300);
`;

export const FooterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.hasPrincipal {
		display: flex;
		flex-direction: column;
	}
`;

export const MyContactBadge = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: var(--neutral-800);
`;

export const BadgeIcon = styled(Badge)`
	path {
		fill: var(--neutral-100);
	}
`;

export const HiddenContainer = styled.div`
	display: flex;
	align-items: start;
	gap: 4px;
	margin-left: 4px;

	${media.xl`
		align-items: center;
	`}
`;

export const OwnOrderHiddenText = styled.span`
	${h600}
	color: #fff;

	&::before {
		content: '•';
		margin-right: 4px;
	}
`;

export const BrokerUser = styled.div`
	${h400}
	--badgeSize: 0px;

	${props => props.$withBadge && '--badgeSize: 40px'};

	width: calc(190px - var(--badgeSize));

	${media.ll`
		width: calc(216px - var(--badgeSize));
	`}

	${media.xl`
		width: calc(253px - var(--badgeSize));
	`}

	label {
		display: block;
		color: var(--text-color-secondary);
		margin-bottom: 4px;
	}
`;

export const RolePill = styled.div`
	border-radius: var(--large-border-radius);
	border: 1px solid var(--neutral-200);
	padding: 5px 6px;
	color: var(--neutral-200);
	${m200}
	font-size: 9px;
	line-height: 1;
	position: relative;
	top: -1px;

	${media.xl`
		font-size: 10px;
	`}
`;
