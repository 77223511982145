/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { h600, m100 } from 'src/components/Typography';
import { TimeOptions, tTimeOptions } from 'src/constants/timeRangeOptions';

export const TimeRange = ({ time, onTimeChange }) => {
	const { t } = useTranslation();

	const supportedTime = useMemo(
		() => Object.keys(TimeOptions).map(c => tTimeOptions(t, TimeOptions[c])),
		[t]
	);

	const getClassNames = useCallback(period => (time === period ? `selected selected__0` : ''), [
		time,
	]);

	return (
		<Section data-test="select-time-range">
			<PeriodContainer data-test="quick-date-options">
				<PeriodTitle>{t('quick_options')}</PeriodTitle>
				{Object.keys(TimeOptions).map((time, index) => (
					<PeriodButton
						type="button"
						aria-label={`Choose ${supportedTime[index]}`}
						key={index}
						onClick={() => onTimeChange(time)}
						className={getClassNames(time)}
					>
						{supportedTime[index]}
					</PeriodButton>
				))}
			</PeriodContainer>
		</Section>
	);
};

const Section = styled.section`
	width: 100%;
	background-color: var(--date-picker-background);
	padding: 16px;
	min-height: 327px;
	border-bottom-left-radius: var(--big-border-radius);
	border-bottom-right-radius: var(--big-border-radius);
	${m100}
`;

const PeriodContainer = styled.div`
	display: grid;
	grid-template-rows: 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	row-gap: 12px;
`;

const PeriodTitle = styled.div`
	grid-column-start: 1;
	grid-column-end: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	${h600};
	color: var(--text-color-default);
`;

const PeriodButton = styled.button`
	color: var(--neutral-300);
	text-transform: capitalize;
	white-space: nowrap;
	padding: 12px 11px 12px 10px;
	min-height: 37px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: var(--simple-border-radius);

	&:hover {
		background-color: var(--neutral-300);
		color: var(--text-color-default);
	}

	&.selected {
		background: var(--date-picker-active-background);
		color: var(--text-color-default);
	}

	&.selected__0 {
		background-color: var(--date-picker-active-background);
		border-top-right-radius: var(--simple-border-radius);
		border-bottom-right-radius: var(--simple-border-radius);
	}
`;
