/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { OrderType } from 'src/constants/contract';
import { useUserDetailsQuery } from 'src/_api';
import { useOrderDetails } from './useOrderDetails';
import { useSelector } from 'react-redux';
import { getUserCompanyName } from 'src/_store/selectors';
import { useTranslation } from 'react-i18next';

export const getCounterpartyLabel = (t, order) => {
	const isMyOrder = order.is_my_order;
	const orderType = order.order_type;

	if (!isMyOrder) {
		return orderType === OrderType.Sell ? t('seller') : t('buyer');
	}

	return orderType !== OrderType.Sell ? t('seller') : t('buyer');
};

export const getTraderDetails = (t, order, lastCounter) => {
	const shouldRevealIdentity = !order.hidden || order.is_identity_revealed;
	const isMyOrder = order.is_my_order;

	return isMyOrder
		? {
				name: lastCounter.user.name,
				company: lastCounter.company.name,
		  }
		: shouldRevealIdentity
		? {
				name: order.user.name,
				company: order.company.name,
		  }
		: {
				name: t('hidden'),
				company: t('hidden'),
		  };
};

export const useSellerBrokerBuyer = (order, counters = [], counterFormData = {}) => {
	const { t } = useTranslation();
	const { isMyOrder, isBrokerOrder } = useOrderDetails(order);
	const currentUserCompanyName = useSelector(getUserCompanyName);

	const selectedPrincipalId = counterFormData.order?.order_owner_principal_id;
	const isSellOrder = order.order_type === OrderType.Sell;

	const orderWithCounters = [order, ...counters];
	const lastCounter = orderWithCounters.at(-1);

	const { data: userData = {} } = useUserDetailsQuery(selectedPrincipalId, {
		enabled: !!selectedPrincipalId,
	});

	const principalCompany = userData?.company_name || '---';

	const shouldRevealIdentity = order.hidden ? lastCounter?.is_identity_revealed : true;

	const getSellerCompany = () => {
		if (isSellOrder && isMyOrder && isBrokerOrder) {
			return principalCompany;
		}

		if (isSellOrder && !isMyOrder && isBrokerOrder) {
			return lastCounter.order_owner_principal_company?.name || '---';
		}

		if (isSellOrder && !isMyOrder && !isBrokerOrder) {
			return shouldRevealIdentity ? order.company?.name : t('hidden');
		}

		if (isSellOrder && isMyOrder && !isBrokerOrder) {
			return currentUserCompanyName;
		}

		if (!isSellOrder && isMyOrder && isBrokerOrder) {
			return lastCounter.counter_order_user_company?.name;
		}

		if (!isSellOrder && !isMyOrder && isBrokerOrder) {
			return currentUserCompanyName;
		}

		if (!isSellOrder && !isMyOrder && !isBrokerOrder) {
			return currentUserCompanyName;
		}

		if (!isSellOrder && isMyOrder && !isBrokerOrder) {
			return lastCounter.counter_order_user_company?.name;
		}

		return '---';
	};

	const getBuyerCompany = () => {
		if (!isSellOrder && isMyOrder && isBrokerOrder) {
			return principalCompany;
		}

		if (!isSellOrder && !isMyOrder && isBrokerOrder) {
			return lastCounter.order_owner_principal_company?.name || '---';
		}

		if (!isSellOrder && !isMyOrder && !isBrokerOrder) {
			return shouldRevealIdentity ? order.company?.name : t('hidden');
		}

		if (!isSellOrder && isMyOrder && !isBrokerOrder) {
			return currentUserCompanyName;
		}

		if (isSellOrder && isMyOrder && isBrokerOrder) {
			return lastCounter.counter_order_user_company?.name;
		}

		if (isSellOrder && !isMyOrder && isBrokerOrder) {
			return currentUserCompanyName;
		}

		if (isSellOrder && !isMyOrder && !isBrokerOrder) {
			return currentUserCompanyName;
		}

		if (isSellOrder && isMyOrder && !isBrokerOrder) {
			return lastCounter.counter_order_user_company?.name;
		}

		return '---';
	};

	const getBrokerCompany = () => {
		if (!isBrokerOrder) {
			return null;
		}

		if (isMyOrder) {
			return currentUserCompanyName;
		}

		return shouldRevealIdentity
			? order.company?.name || lastCounter.order_user_company?.name
			: t('hidden');
	};

	return {
		sellerCompany: getSellerCompany(),
		buyerCompany: getBuyerCompany(),
		brokerCompany: getBrokerCompany(),
	};
};

export const getOrderNegotiationTradeLink = (t, order = {}, isCounter) => {
	const locationOrigin = window.location.origin;

	const {
		_key: orderId,
		order_id: counterOrderId,
		environment: orderEnvironment,
		first_counter_id: negotiationId,
		trade_id: tradeId,
	} = order;

	if (isCounter) {
		if (tradeId) {
			return {
				label: t('trade_id'),
				id: tradeId,
				link: `${locationOrigin}/execution/trade/${tradeId}`,
			};
		}

		return {
			label: t('negotiation_id'),
			id: negotiationId,
			link: `${locationOrigin}/?orderEnvironment=${orderEnvironment}&orderId=${counterOrderId}&negotiationId=${negotiationId}`,
		};
	}

	return {
		label: t('order_id'),
		id: orderId,
		link: `${locationOrigin}/?orderEnvironment=${orderEnvironment}&orderId=${orderId}`,
	};
};
